/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// material ui
import { Grid, Paper, Typography, withStyles } from '@material-ui/core';

// Utils
import { compose } from '@Utils';
import { addCommasInNumber } from '@Utils/formatting';

// styles
import styles from './styles';

const Balance = ({ classes, solveBalance, minBalanceThreshold, t }) => {
  let solveBalanceClass = '';

  if (minBalanceThreshold) {
    solveBalanceClass =
      solveBalance > minBalanceThreshold ? classes.green : classes.red;
  }

  return (
    <Paper elevation={5} className={classes.balanceInfo}>
      <Grid item className={classes.balanceItem}>
        <Typography
          variant="subtitle1"
          gutterBottom
          className={classes.balanceSubtitle}
        >
          {t('can.common.balance')}
        </Typography>
        <Typography
          variant="subtitle2"
          gutterBottom
          className={classnames(classes.ballanceValue, solveBalanceClass)}
        >
          {addCommasInNumber(solveBalance)}
        </Typography>
      </Grid>
      {minBalanceThreshold ? (
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            gutterBottom
            className={classes.balanceSubtitle}
          >
            {t('can.common.minBalance')}
          </Typography>
          <Typography
            variant="subtitle2"
            gutterBottom
            className={classes.ballanceValue}
          >
            {addCommasInNumber(minBalanceThreshold)}
          </Typography>
        </Grid>
      ) : null}
    </Paper>
  );
};

Balance.defaultProps = {
  minBalanceThreshold: 0,
  solveBalance: 0
};

Balance.propTypes = {
  classes: PropTypes.object.isRequired,
  solveBalance: PropTypes.number,
  minBalanceThreshold: PropTypes.number,
  t: PropTypes.func.isRequired
};

export default compose(
  withStyles(styles),
  withTranslation()
)(Balance);
