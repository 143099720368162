/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

// Material UI imports
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

// Styles
import styles from './styles';

const ModalDialog = ({
  isOpen,
  title,
  handleConfirmAction,
  handleCancelAction,
  confirmBtn,
  cancelBtn,
  classes
}) => (
  <Dialog
    id="dialog-window"
    className={classes.margin}
    open={isOpen}
    onClose={handleCancelAction}
    aria-labelledby="form-dialog-title"
  >
    <DialogTitle id="form-dialog-title">{title}</DialogTitle>
    <DialogActions>
      <Button
        id="cancel-btn"
        className={classes.modalButton}
        onClick={handleCancelAction}
      >
        {cancelBtn}
      </Button>
      <Button
        id="confirm-btn"
        className={classes.modalButton}
        onClick={handleConfirmAction}
      >
        {confirmBtn}
      </Button>
    </DialogActions>
  </Dialog>
);

ModalDialog.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  handleConfirmAction: PropTypes.func,
  handleCancelAction: PropTypes.func,
  confirmBtn: PropTypes.string,
  cancelBtn: PropTypes.string,
  classes: PropTypes.object.isRequired
};

ModalDialog.defaultProps = {
  confirmBtn: i18n.t('common.yes'),
  cancelBtn: i18n.t('common.cancel')
};

export default withStyles(styles)(ModalDialog);
