/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

// Material-UI imports
import { Grid, withStyles } from '@material-ui/core';

// Components
import { TableSearch } from '@CommonScene/MainContentWrapper/components/ContainerHeader/components';
import { PaymentsSummary, FromToDateFilter, Balance } from '@CommonScene';

// Utils
import { compose } from '@Utils';

// Styles
import styles from './styles';

export class Details extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    WithdrawalStore: PropTypes.object.isRequired
  };

  componentDidMount() {
    const {
      WithdrawalStore: { getBalance, getWithdrawalTickets }
    } = this.props;

    getBalance();
    // TODO: temporary - get only 'paid' transactions until deposit state machine is implemented
    getWithdrawalTickets();
  }

  setQuery = query => {
    const {
      WithdrawalStore: { setQuery }
    } = this.props;
    setQuery(query);
  };

  setDefaultQuery = () => {
    const {
      WithdrawalStore: { getWithdrawalTickets, clearQuery }
    } = this.props;

    clearQuery();
    getWithdrawalTickets();
  };

  render() {
    const {
      classes,
      WithdrawalStore: { balance, transactionsSummary, query, dateRange }
    } = this.props;

    return (
      <Fragment>
        <Balance solveBalance={balance} />
        <Grid container className={classes.filterWrapper}>
          <FromToDateFilter
            title="Date Ordered"
            handleChangeDate={this.handleChangeDateOrdered}
            dateRange={{ from: dateRange.from_date, to: dateRange.to_date }}
          />
          <TableSearch
            setQuery={this.setQuery}
            value={query}
            placeholder="Search by sender"
            setDefaultStatuses={this.setDefaultQuery}
          />
        </Grid>
        <PaymentsSummary transactionsSummary={transactionsSummary} />
      </Fragment>
    );
  }
}

export default compose(
  withStyles(styles),
  inject('DepositsStore', 'WithdrawalStore'),
  observer
)(Details);
