/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// translations
import i18n from 'i18next';

const COLORS = {
  red: 'red',
  green: 'green',
  orange: 'orange'
};

export const statusMapping = {
  CODE_NOT_SENT: {
    title: i18n.t('mwAdmin.invitations.codeNotSent'),
    color: COLORS.red
  },
  CODE_SENT: {
    title: i18n.t('mwAdmin.invitations.codeSent'),
    color: COLORS.orange
  },
  INVITATION_ACCEPTED: {
    title: i18n.t('mwAdmin.invitations.invitationAccepted'),
    color: COLORS.green
  },
  INVITATION_REVOKED: {
    title: i18n.t('mwAdmin.invitations.invitationRevoked'),
    color: COLORS.red
  },
  CODE_EXPIRED: {
    title: i18n.t('mwAdmin.invitations.codeExpired'),
    color: COLORS.red
  },
  BLOCKED: {
    title: i18n.t('common.blocked'),
    color: COLORS.red
  },
  REMOVED: {
    title: i18n.t('common.removed'),
    color: COLORS.red
  },
  ACTIVE: {
    title: i18n.t('common.active'),
    color: COLORS.green
  },
  INVITED: {
    title: i18n.t('common.invited'),
    color: COLORS.orange
  }
};
