/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

import PropTypes from 'prop-types';
import React from 'react';
import { inject, observer } from 'mobx-react';

// Material-UI imports
import { Paper, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';

// Components
import ResetPasswordPhone from '@AuthScene/scenes/ResetPassword/components/ResetPasswordPhoneForm';
import ResetPasswordCode from '@AuthScene/scenes/ResetPassword/components/ResetPasswordCodeForm';
import ResetPassword from '@AuthScene/scenes/ResetPassword/components/ResetPasswordForm';

// Styles
import styles from './styles';

// Utils
import { compose } from '@Utils';

export class ResetPasswordLayout extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    AuthStore: PropTypes.shape({
      resetPasswordStep: PropTypes.number,
      clearInputFields: PropTypes.func.isRequired
    })
  };

  static defaultProps = {
    resetPasswordStep: null
  };

  componentWillUnmount() {
    this.props.AuthStore.clearInputFields('resetPasswordPhone', ['phone']);
  }

  activeForm = () => {
    switch (this.props.AuthStore.resetPasswordStep) {
      case 1:
        return <ResetPasswordPhone />;
      case 2:
        return <ResetPasswordCode />;
      case 3:
        return <ResetPassword />;
      default:
        return <ResetPasswordPhone />;
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid
          container
          className={classes.offset}
          direction="column"
          justify="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={6} className={classes.container}>
            <Paper className={classes.paper}>{this.activeForm()}</Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  inject('AuthStore'),
  observer
)(ResetPasswordLayout);
