/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

// Material UI
import { Button, withStyles } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';

// Components
import { Items } from './Items';

// Utils
import { compose } from '@Utils';

// Styles
import styles from './styles';

class HeadingFilter extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    applyHandler: PropTypes.func.isRequired,
    onChangeHandler: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
    selectedItems: PropTypes.array.isRequired
  };

  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  applyFilter = () => {
    this.props.applyHandler();
    this.handleClose();
  };

  render() {
    const {
      classes,
      items,
      selectedItems,
      label,
      onChangeHandler
    } = this.props;
    const { anchorEl } = this.state;

    return (
      <div>
        <Button
          id="type-button"
          className={classes.filterWrapper}
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <span className={classes.tableHeader}>{label}</span>
          <MoreVert />
        </Button>
        {anchorEl ? (
          <Items
            items={items}
            selectedItems={selectedItems}
            anchorEl={anchorEl}
            handleClose={this.handleClose}
            applyFilter={this.applyFilter}
            onChangeHandler={onChangeHandler}
          />
        ) : null}
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  inject('InvitationStore'),
  observer
)(HeadingFilter);
