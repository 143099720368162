/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Divider, Typography } from '@material-ui/core';

// Components
import { TextField } from '@CommonScene';
import SnackBar from '@AuthScene/scenes/common/SnackBar';
import ResetPasswordButtons from '@AuthScene/scenes/ResetPassword/components/ResetPasswordButtons';

// Utils
import { displayPhone } from '@Utils/display';
import { validationKeys } from '@Utils/constans';
import { compose } from '@Utils';

// Styles
import styles from './styles';

export class ResetPasswordCodeForm extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    AuthStore: PropTypes.object,
    t: PropTypes.func.isRequired
  };

  componentDidMount() {
    const {
      AuthStore: { clearInputFields }
    } = this.props;

    clearInputFields('resetPasswordCode', ['code']);
  }

  handleResendCode = e => {
    e.preventDefault();

    const {
      AuthStore: { clearErrorMessage, sendPhoneForResetPassword }
    } = this.props;

    clearErrorMessage();
    sendPhoneForResetPassword();
  };

  handleSubmitForm = e => {
    e.preventDefault();

    const {
      AuthStore: { clearErrorMessage, sendConfirmationCode }
    } = this.props;

    clearErrorMessage();
    sendConfirmationCode();
  };

  render() {
    const {
      classes,
      AuthStore: {
        forms,
        onFieldChange,
        clearErrorMessage,
        errors,
        handleChangeForm
      },
      t
    } = this.props;
    const userPhone = forms.resetPasswordPhone.fields.phone.value;

    return (
      <form onSubmit={this.handleSubmitForm}>
        <Typography
          align="left"
          gutterBottom
          variant="h5"
          component="h2"
          className={classes.headerTitle}
        >
          {t('auth.resetPassword.stage2.heading')}
        </Typography>

        <Divider className={classes.headerBottomBorder} />

        <Grid container spacing={8} alignItems="flex-end">
          <Grid item xs={12}>
            <Typography
              align="left"
              className={classNames(classes.phoneText, classes.marginBottom)}
            >
              {`${t('auth.resetPassword.stage2.sendCodeText')} ${displayPhone(
                userPhone
              )}`}
            </Typography>

            <TextField
              name="code"
              label={t('auth.resetPassword.stage2.verificationCode')}
              value={forms.resetPasswordCode.fields.code.value}
              error={t(
                validationKeys[forms.resetPasswordCode.fields.code.error]
              )}
              onChange={e =>
                onFieldChange(
                  e.target.name,
                  e.target.value,
                  'resetPasswordCode'
                )
              }
              placeholder={t('auth.resetPassword.stage2.codePlaceholder')}
            />
          </Grid>
          <Grid item xs={12} align="left">
            <Button
              onClick={this.handleResendCode}
              className={classes.resendBtn}
              color="primary"
            >
              {t('auth.resetPassword.stage2.resendCode')}
            </Button>
          </Grid>
        </Grid>

        {errors ? (
          <SnackBar
            onClose={clearErrorMessage}
            variant={'error'}
            message={errors}
          />
        ) : null}

        <ResetPasswordButtons
          isValid={forms.resetPasswordCode.meta.isValid}
          clearErrorMessage={clearErrorMessage}
          handleChangeForm={handleChangeForm}
          step={1}
        />
      </form>
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation(),
  inject('AuthStore'),
  observer
)(ResetPasswordCodeForm);
