/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// Material UI
import { Button, Menu, MenuItem, Divider, withStyles } from '@material-ui/core';

// Components
import { Checkbox } from '@CommonScene';

// Utils
import { compose } from '@Utils';
import { translatedStatuses } from '@Assets/config/configForStatuses';

// Styles
import styles from './styles';

export class StatusMenu extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    anchorEl: PropTypes.object.isRequired,
    closeMenu: PropTypes.func.isRequired,
    NetworkStore: PropTypes.shape({
      userStatus: PropTypes.object.isRequired,
      handleStatusSelect: PropTypes.func,
      handleStatusAllSelect: PropTypes.func,
      getGroupUsersAccounts: PropTypes.func,
      setDefaultStatuses: PropTypes.func,
      handleChangePage: PropTypes.func,
      resetPage: PropTypes.func,
      allStatusesChecked: PropTypes.bool,
      setActualUserStatus: PropTypes.func.isRequired
    }),
    match: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.props.NetworkStore.setActualUserStatus();
  }

  componentWillUnmount() {
    const {
      NetworkStore: { resetPage }
    } = this.props;

    resetPage();
  }

  handleClose = () => {
    const {
      closeMenu,
      NetworkStore: { setDefaultStatuses }
    } = this.props;

    setDefaultStatuses();
    closeMenu();
  };

  handleStatusSelect = (event, type) => {
    const {
      target: { checked }
    } = event;

    this.props.NetworkStore.handleStatusSelect({ type, checked });
  };

  handleStatusAllSelect = event => {
    const {
      target: { checked }
    } = event;

    this.props.NetworkStore.handleStatusAllSelect({ checked });
  };

  isApplyBtnDisabled = () => {
    const {
      NetworkStore: { userStatus }
    } = this.props;
    return Object.values(userStatus).every(item => !item);
  };

  getGroupId() {
    return this.props.match.params.group;
  }

  handleSubmit = () => {
    const {
      closeMenu,
      NetworkStore: { getGroupUsersAccounts, handleChangePage }
    } = this.props;

    if (this.getGroupId()) {
      getGroupUsersAccounts(this.getGroupId());
    } else {
      handleChangePage(0);
    }

    closeMenu();
  };

  render() {
    const {
      t,
      classes,
      anchorEl,
      NetworkStore: { userStatus, allStatusesChecked }
    } = this.props;

    return (
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={this.handleClose}
      >
        <MenuItem>
          <Checkbox
            id="all-checkbox"
            className={classes.checkboxLabel}
            checked={allStatusesChecked}
            onChange={this.handleStatusAllSelect}
            label={t('common.all')}
          />
        </MenuItem>
        <Divider />
        {Object.keys(userStatus).map(type => (
          <MenuItem key={type} className={classes.menuItem}>
            <Checkbox
              checked={userStatus[type]}
              onChange={e => this.handleStatusSelect(e, type)}
              id="checkbox"
              className={classes.checkboxLabel}
              label={t(translatedStatuses[type])}
            />
          </MenuItem>
        ))}

        <div className={classes.menuItemFilter}>
          <Button id="cancel" onClick={this.handleClose}>
            {t('common.cancel')}
          </Button>
          <Button
            id="apply"
            onClick={this.handleSubmit}
            disabled={this.isApplyBtnDisabled()}
          >
            {t('common.apply')}
          </Button>
        </div>
      </Menu>
    );
  }
}

export default compose(
  withTranslation(),
  withStyles(styles),
  withRouter,
  inject('NetworkStore'),
  observer
)(StatusMenu);
