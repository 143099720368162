/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

// Material UI
import { Grid, withStyles } from '@material-ui/core';

// Components
import { TableSearch } from '@CommonScene/MainContentWrapper/components/ContainerHeader/components';
import { PaymentsSummary, FromToDateFilter, Balance } from '@CommonScene';

// Utils
import { compose } from '@Utils';

// Styles
import styles from './styles';

export class Details extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    DepositsStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
  };

  state = {
    dateRanges: { ...this.props.DepositsStore.dateRange }
  };

  componentDidMount() {
    const {
      DepositsStore: { getBalance, getTransactionsSummary }
    } = this.props;

    getBalance();
    // TODO: temporary - get only 'paid' transactions until deposit state machine is implemented
    getTransactionsSummary({ statuses: ['Paid'] });
  }

  setDate = (key, date) => {
    const {
      DepositsStore: {
        getTransactionsSummary,
        getDepositsTransactions,
        setDateRange
      }
    } = this.props;
    this.setState(
      {
        dateRanges: {
          ...this.state.dateRanges,
          [key]: date
        }
      },
      () => {
        const { dateRanges } = this.state;

        setDateRange(dateRanges);
        getTransactionsSummary(dateRanges);
        getDepositsTransactions(dateRanges);
      }
    );
  };

  getFilteredTransactions = (date, calendarKey, fromKey, toKey) => {
    if (calendarKey === 'from') {
      this.setDate(fromKey, date);
    } else {
      this.setDate(toKey, date);
    }
  };

  handleChangeDateOrdered = (date, calendarKey) => {
    this.getFilteredTransactions(
      date,
      calendarKey,
      'from_order_date',
      'to_order_date'
    );
  };

  handleChangeDateLoaded = (date, calendarKey) => {
    this.getFilteredTransactions(date, calendarKey, 'from_date', 'to_date');
  };

  setQuery = query => {
    const {
      DepositsStore: { setQuery }
    } = this.props;
    setQuery(query);
  };

  setDefaultQuery = () => {
    const {
      DepositsStore: { getDepositsTransactions, clearQuery }
    } = this.props;

    clearQuery();
    getDepositsTransactions();
  };

  render() {
    const { dateRanges } = this.state;
    const {
      classes,
      DepositsStore: {
        balance,
        minBalanceThreshold,
        transactionsSummary,
        query
      },
      t
    } = this.props;

    return (
      <Fragment>
        <Balance
          solveBalance={balance}
          minBalanceThreshold={minBalanceThreshold}
        />
        <Grid container className={classes.filterWrapper}>
          <FromToDateFilter
            title={t('can.deposits.dateOrdered')}
            handleChangeDate={this.handleChangeDateOrdered}
            dateRange={{ from: dateRanges.from_date, to: dateRanges.to_date }}
          />
          <FromToDateFilter
            title={t('can.deposits.dateReceived')}
            handleChangeDate={this.handleChangeDateLoaded}
            dateRange={{
              from: dateRanges.from_order_date,
              to: dateRanges.to_order_date
            }}
          />
          <TableSearch
            setQuery={this.setQuery}
            value={query}
            placeholder={t('can.deposits.searchPlaceholder')}
            setDefaultStatuses={this.setDefaultQuery}
          />
        </Grid>
        <PaymentsSummary transactionsSummary={transactionsSummary} />
      </Fragment>
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation(),
  inject('DepositsStore'),
  observer
)(Details);
