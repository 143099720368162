/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import i18n from 'i18next';

// Alias Routes
import NoAdminRights from '@CommonScene/noAdminRights';
import NotFoundPage from '@CommonScene/notFoundPage';

// Networks
import NetworksList from '@NetworkScene/scenes/NetworksList/container';
import NetworkView from '@NetworkScene/scenes/ViewNetwork/container';
import NetworkEdit from '@NetworkScene/scenes/EditNetwork/container';
import NetworkRoleEdit from '@NetworkScene/scenes/EditRole/container';

// Roles
import RolesList from '@RolesScene/scenes/RolesList/container';
import AddRole from '@RolesScene/scenes/AddRole/container';
import ViewRole from '@RolesScene/scenes/ViewRole/container';
import EditRole from '@RolesScene/scenes/EditRole/container';

// Invitations
import InvitationsList from '@InvitationsScene/scenes/InvitationsList/container';
import AddInvitation from '@InvitationsScene/scenes/AddInvitation/container';

// Groups
import GroupsList from '@GroupsScene/scenes/GroupsList/container';
import ViewGroup from '@GroupsScene/scenes/ViewGroup/container';

// Deposits
import DepositsList from '@DepositsScene/scenes/DepositsList/container';

// Payments
import ServiceView from '@PaymentsScene/scenes/ServiceView/container';
import ServicePayments from '@PaymentsScene/scenes/ServicePayments/container';
import MembersView from '@PaymentsScene/scenes/MembersView/container';
import MemberPayments from '@PaymentsScene/scenes/MemberPayments/container';

// Wallets
import Wallets from '@ServiceWalletScene/scenes/Wallets/container';
import PaymentsAdminService from '@ServiceWalletScene/scenes/Payments/container';
import NewWithdrawal from '@ServiceWalletScene/scenes/Withdrawals/scenes/NewWithdrawal/container';
import WithdrawalsList from '@ServiceWalletScene/scenes/Withdrawals/scenes/WithdrawalsList/container';

// Statistics
import CanTopologyStatistics from '@StatisticsScene/scenes/CanTopologyStatistics/container';

// Utils
import { getMenuItems } from '@Utils/formatting';

// Used for Breadcrumbs and Main menu
const rolesSection = 'Roles';
const networkSection = 'Network Participants';
const groupSection = 'Wallet Groups';
const invitationSection = 'Invitations';
const depositsSection = 'Deposits';
const paymentsSection = 'Payments';
// TODO: do not setting updated value
// const serviceWalletSection = `${serviceWallets.LYFT} Wallet`;
const serviceWalletSection = 'Service Wallets';
const statisticsSection = 'CAN Statistics';

const WM_ADMIN = 'SYSTEM_DECK';
export const PAYMENT_ADMIN = 'PAYMENT_ADMIN_DECK';
export const CAN_SPONSOR = 'CAN_SPONSOR_DECK';
export const CAN_STATISTICS = 'REPORTING_ADMIN';

const RoleRoutes = [
  {
    path: '/roles',
    name: 'roles',
    title: i18n.t('mwAdmin.sidebar.roles'),
    section: rolesSection,
    exact: true,
    icon: '',
    menu: true,
    component: RolesList,
    roles: [WM_ADMIN]
  },
  {
    path: '/roles/create',
    name: 'role_create',
    parent: 'roles',
    title: i18n.t('mwAdmin.roles.addRole'),
    section: rolesSection,
    exact: true,
    icon: '',
    component: AddRole,
    roles: [WM_ADMIN]
  },
  {
    path: '/roles/view/:role',
    name: 'role_view',
    parent: 'roles',
    title: 'Role profile',
    section: rolesSection,
    exact: true,
    icon: '',
    component: ViewRole,
    roles: [WM_ADMIN]
  },
  {
    path: '/roles/edit/:role',
    name: 'role_edit',
    parent: 'roles',
    title: 'Role profile (Edit)',
    section: rolesSection,
    exact: true,
    icon: '',
    component: EditRole,
    roles: [WM_ADMIN]
  }
];

const NetworkRoutes = [
  {
    path: '/networks/view/:id',
    name: 'network_view',
    parent: 'networks',
    title: i18n.t('mwAdmin.networkParticipants.participantProfile'),
    section: networkSection,
    exact: true,
    icon: '',
    component: NetworkView,
    roles: [WM_ADMIN]
  },
  {
    path: '/networks/add-role/:userId',
    name: 'network_add_roles',
    parent: 'networks',
    title: i18n.t('mwAdmin.networkParticipants.participantProfileAddingRole'),
    section: networkSection,
    exact: true,
    icon: '',
    component: AddInvitation,
    roles: [WM_ADMIN]
  },
  {
    path: '/networks/edit/:id',
    name: 'network_edit',
    parent: 'networks',
    title: i18n.t('mwAdmin.networkParticipants.participantProfileEditing'),
    section: networkSection,
    exact: true,
    icon: '',
    component: NetworkEdit,
    roles: [WM_ADMIN]
  },
  {
    path: '/networks/:userId/edit-role/:roleId',
    name: 'network_role_edit',
    parent: 'networks',
    title: i18n.t('mwAdmin.networkParticipants.participantProfileRoleEditing'),
    section: networkSection,
    exact: true,
    icon: '',
    component: NetworkRoleEdit,
    roles: [WM_ADMIN]
  },
  {
    path: '/networks',
    name: 'networks',
    title: i18n.t('mwAdmin.sidebar.networkParticipants'),
    section: networkSection,
    exact: true,
    icon: '',
    menu: true,
    component: NetworksList,
    roles: [WM_ADMIN]
  }
];

const GroupRoutes = [
  {
    path: '/groups',
    name: 'groups',
    title: i18n.t('mwAdmin.groups.walletGroups'),
    section: groupSection,
    exact: true,
    icon: '',
    menu: true,
    component: GroupsList,
    roles: [WM_ADMIN]
  },
  {
    path: '/groups/view/:group',
    parent: 'groups',
    name: 'group_view',
    title: 'Wallet Group Details',
    section: groupSection,
    exact: true,
    icon: '',
    component: ViewGroup,
    roles: [WM_ADMIN]
  }
];

const InvitationRoutes = [
  {
    path: '/invitations',
    name: 'invitations',
    title: i18n.t('mwAdmin.sidebar.invitations'),
    section: invitationSection,
    exact: true,
    icon: '',
    menu: true,
    component: InvitationsList,
    roles: [WM_ADMIN]
  },
  {
    path: '/invitations/create',
    name: 'invitation_create',
    title: i18n.t('mwAdmin.invitations.inviteToRole'),
    parent: 'invitations',
    section: invitationSection,
    exact: true,
    icon: '',
    component: AddInvitation,
    roles: [WM_ADMIN]
  }
];

const DepositsRoutes = [
  {
    name: 'deposits',
    title: i18n.t('can.sidebar.solveManagement'),
    section: depositsSection,
    roles: [CAN_SPONSOR]
  },
  {
    path: '/deposits',
    name: 'deposits_details',
    title: i18n.t('can.sidebar.deposits'),
    parent: 'deposits',
    section: depositsSection,
    exact: true,
    menu: true,
    component: DepositsList,
    roles: [CAN_SPONSOR]
  }
];

const PaymentsRoutes = [
  {
    name: 'payments',
    title: i18n.t('can.sidebar.payments'),
    section: paymentsSection,
    roles: [CAN_SPONSOR]
  },
  {
    path: '/payments/service',
    name: 'payments_service_view',
    title: i18n.t('can.sidebar.services'),
    parent: 'payments',
    section: paymentsSection,
    exact: true,
    menu: true,
    component: ServiceView,
    roles: [CAN_SPONSOR]
  },
  {
    path: '/payments/service/:service',
    name: 'payments_service',
    title: i18n.t('can.services.transactions.title'),
    parent: 'payments_service_view',
    section: paymentsSection,
    exact: true,
    component: ServicePayments,
    roles: [CAN_SPONSOR]
  },
  {
    path: '/payments/members',
    name: 'payments_members_view',
    title: i18n.t('can.sidebar.members'),
    parent: 'payments',
    section: paymentsSection,
    exact: true,
    menu: true,
    component: MembersView,
    roles: [CAN_SPONSOR]
  },
  {
    path: '/payments/members/:member',
    name: 'payments_members',
    title: i18n.t('can.members.transactions.title'),
    parent: 'payments_members_view',
    section: paymentsSection,
    exact: true,
    component: MemberPayments,
    roles: [CAN_SPONSOR]
  }
];

const ServiceWallets = [
  {
    path: '/wallets',
    name: 'wallets',
    section: serviceWalletSection,
    title: 'Service Wallets',
    exact: true,
    menu: true,
    component: Wallets,
    roles: [PAYMENT_ADMIN]
  },
  {
    path: '/wallets/:walletId/withdrawals',
    name: 'wallet_withdrawals',
    title: 'Withdrawals',
    section: serviceWalletSection,
    exact: true,
    component: WithdrawalsList,
    roles: [PAYMENT_ADMIN]
  },
  {
    path: '/wallets/:walletId/withdrawals/new',
    name: 'wallet_withdrawal',
    title: 'New Withdrawal',
    section: serviceWalletSection,
    exact: true,
    parent: 'wallets',
    component: NewWithdrawal,
    roles: [PAYMENT_ADMIN]
  },
  {
    path: '/wallets/:walletId/payments',
    name: 'wallet_payments',
    title: i18n.t('can.common.payments'),
    section: serviceWalletSection,
    parent: 'wallets',
    exact: true,
    component: PaymentsAdminService,
    roles: [PAYMENT_ADMIN]
  }
];

const StatisticsRoutes = [
  {
    path: '/statistics',
    name: 'topology_statistics',
    title: i18n.t('can.sidebar.canTopologyStatistics'),
    section: statisticsSection,
    exact: true,
    menu: true,
    component: CanTopologyStatistics,
    roles: [CAN_STATISTICS, WM_ADMIN, CAN_SPONSOR]
  }
];

const AliasRoutes = [
  {
    path: '/',
    exact: true,
    redirectURL: '/networks'
  },
  {
    path: '/not-found',
    name: 'not_found',
    exact: true,
    title: 'Page not found',
    component: NotFoundPage
  },
  {
    path: '/no-admin-rights',
    exact: true,
    title: 'No Admin Rights',
    component: NoAdminRights
  }
];

export default [
  ...RoleRoutes,
  ...InvitationRoutes,
  ...NetworkRoutes,
  ...GroupRoutes,
  ...AliasRoutes,
  ...DepositsRoutes,
  ...PaymentsRoutes,
  ...ServiceWallets,
  ...StatisticsRoutes
];

// routes for can
export const menuLinksConfig = {
  [WM_ADMIN]: [
    {
      title: i18n.t('mwAdmin.sidebar.participantsManagement'),
      routes: [...InvitationRoutes, ...NetworkRoutes].filter(getMenuItems)
    },
    {
      title: i18n.t('mwAdmin.sidebar.networkConfiguration'),
      routes: [...RoleRoutes].filter(getMenuItems)
    },
    {
      title: i18n.t('can.sidebar.canStatistics'),
      routes: [...StatisticsRoutes].filter(getMenuItems)
    }
  ],
  [PAYMENT_ADMIN]: [
    {
      routes: [...ServiceWallets].filter(getMenuItems)
    }
  ],
  [CAN_SPONSOR]: [
    {
      title: i18n.t('can.sidebar.solveManagement'),
      routes: [...DepositsRoutes].filter(getMenuItems)
    },
    {
      title: i18n.t('can.sidebar.payments'),
      routes: [...PaymentsRoutes].filter(getMenuItems)
    },
    {
      title: i18n.t('can.sidebar.canStatistics'),
      routes: [...StatisticsRoutes].filter(getMenuItems)
    }
  ],
  [CAN_STATISTICS]: [
    {
      routes: [...StatisticsRoutes].filter(getMenuItems)
    }
  ]
};

// routes for ACN
export const MwAdminConfigForAcn = [
  {
    title: i18n.t('mwAdmin.sidebar.participantAndRoleManagement'),
    routes: [
      ...RoleRoutes,
      ...InvitationRoutes,
      ...NetworkRoutes,
      ...GroupRoutes
    ].filter(getMenuItems)
  }
];
