/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react/index';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel
} from '@material-ui/core';

// Utils
import { compose } from '@Utils';

// Styles
import styles from './styles';

class TextField extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    classes: PropTypes.object,
    onChange: PropTypes.func,
    error: PropTypes.string,
    placeholder: PropTypes.string,
    maxLength: PropTypes.number
  };

  static defaultProps = {
    name: undefined,
    value: '',
    onChange: () => {
      // Stub function
    },
    error: null,
    placeholder: '',
    maxLength: undefined
  };

  render() {
    const {
      label,
      error,
      classes,
      maxLength,
      value,
      onChange,
      ...other
    } = this.props;
    return (
      <FormControl error={!!error} fullWidth aria-describedby="input-component">
        <InputLabel id="input-label" htmlFor="input-component">
          {label}
        </InputLabel>
        <Input
          id="input-wrapper"
          type="text"
          inputProps={{ maxLength }}
          className={classes.margin}
          onChange={(name, inputValue, formName) =>
            onChange(name, inputValue, formName)
          }
          value={value}
          {...other}
        />
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    );
  }
}

export default compose(
  withStyles(styles),
  observer
)(TextField);
