/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

// Components
import RolePanel from '@NetworkScene/scenes/ViewNetwork/components/RolePanel';
import Details from '@NetworkScene/scenes/ViewNetwork/components/Details';

// Utils
import { compose } from '@Utils';

export class NetworkProfilePanel extends React.Component {
  static propTypes = {
    NetworkStore: PropTypes.shape({
      getUserRoles: PropTypes.func,
      getUser: PropTypes.func,
      resetCurrentUser: PropTypes.func,
      getActiveRoles: PropTypes.func,
      currentUser: PropTypes.object,
      currentUserRoles: PropTypes.object
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired
      })
    })
  };

  componentDidMount() {
    const {
      NetworkStore: { getUserRoles, getActiveRoles, getUser }
    } = this.props;

    getUserRoles(this.getUserId());
    getActiveRoles();
    getUser(this.getUserId());
  }

  componentWillUnmount() {
    this.props.NetworkStore.resetCurrentUser();
  }

  getUserId() {
    return this.props.match.params.id;
  }

  render() {
    const {
      NetworkStore: { currentUser, currentUserRoles }
    } = this.props;

    return (
      <Fragment>
        <Details userId={this.getUserId()} />

        {currentUser.status !== 'REMOVED' ? (
          <RolePanel
            userId={this.getUserId()}
            currentUser={currentUser}
            currentUserRoles={currentUserRoles}
          />
        ) : null}
      </Fragment>
    );
  }
}

export default compose(
  withRouter,
  inject('NetworkStore'),
  observer
)(NetworkProfilePanel);
