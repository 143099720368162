/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

// Material-UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';

// Component
import DeckPermissionForm from '@RolesScene/scenes/AddRole/components/DeckPermissionForm';
import RoleCustomFieldsForm from '@RolesScene/scenes/AddRole/components/RoleCustomFieldsForm';

// Styles
import styles from './styles';

@withTranslation()
@inject('RoleStore', 'DeckStore')
@withRouter
@observer
class RolePermissionFieldSet extends React.Component {
  constructor(props) {
    super(props);
    // TODO: code below need refactor
    props.DeckStore.getDecks().then(() => {
      const roleId = props.match.params.role;
      if (roleId) {
        const role = props.RoleStore.getRole(roleId);
        if (role) {
          Object.keys(role.permissions).map(id => {
            props.DeckStore.setCheckedDecks({ id, checked: true });
            return null;
          });
          props.RoleStore.setFormField('name', role.name);
        }
      }
    });
  }

  static propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    RoleStore: PropTypes.object,
    history: PropTypes.object.isRequired,
    DeckStore: PropTypes.object,
    match: PropTypes.object.isRequired,
    UpdateState: PropTypes.func
  };

  render() {
    const { classes, DeckStore, t } = this.props;

    return (
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <div className={classes.alignMiddle}>
            <Typography variant="subtitle1" color="textPrimary">
              <span className={classes.rowLable}>
                {t('mwAdmin.roles.cards')}
              </span>
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.alignMiddle}>
            <Typography variant="subtitle1" color="textPrimary">
              <span className={classes.rowLable}>
                {t('mwAdmin.roles.permissions')}
              </span>
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          {DeckStore.decks
            .filter(
              item =>
                !!item.name && (Number(item.id) !== 1 && Number(item.id) !== 6)
            )
            .map((item, index) =>
              item ? (
                <div className={classes.deckForm} key={`deck-${index}`}>
                  <DeckPermissionForm index={index} deck={item} />
                </div>
              ) : null
            )}
        </Grid>
      </Grid>
    );
  }
}

@withTranslation()
@withRouter
@observer
class RolePermissionForm extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    handleBack: PropTypes.func,
    handleNext: PropTypes.func,
    UpdateState: PropTypes.func
  };

  render() {
    const { classes, t } = this.props;

    return (
      <form>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <div
                  className={classNames(
                    classes.alignMiddle,
                    classes.blockTitle
                  )}
                >
                  <Typography
                    variant="subtitle1"
                    component="h3"
                    className={classes.permissionTitle}
                  >
                    {t('mwAdmin.roles.selectCardForRole')}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={6}>
                <RolePermissionFieldSet classes={classes} />
              </Grid>
              <Grid item xs={6}>
                <RoleCustomFieldsForm />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default withStyles(styles)(RolePermissionForm);
export { RolePermissionFieldSet };
