/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { compose } from '@Utils';
import { withTranslation } from 'react-i18next';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

// Components
import { DataTable } from '@CommonScene';

// Configs
import { tableConfig } from '@Assets/config/tables/groupParticipants';

// Styles
import styles from './styles';

export class ParticipantsTable extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    NetworkStore: PropTypes.object,
    groupId: PropTypes.string.isRequired
  };

  componentDidMount() {
    const {
      groupId,
      NetworkStore: { clearGroupUsers, getGroupUsersAccounts, setCurrentGroup }
    } = this.props;

    clearGroupUsers();
    setCurrentGroup(groupId);
    getGroupUsersAccounts(groupId);
  }

  componentWillUnmount() {
    this.props.NetworkStore.reset();
  }

  fetchTableData = () => {
    const {
      NetworkStore: { handleChangePage, page, lastUsersPage }
    } = this.props;

    if (!lastUsersPage) {
      handleChangePage(page + 1);
    }
  };

  render() {
    const {
      t,
      classes,
      NetworkStore: { groupUsers, lastUsersPage }
    } = this.props;

    return (
      <Grid container spacing={3} className={classes.padding}>
        <Grid item sm={12} md={12} lg={12}>
          <Typography
            component="h6"
            variant="h5"
            gutterBottom
            className={classes.title}
          >
            {t('mwAdmin.groups.participants')}
          </Typography>
          <DataTable
            tableConfig={tableConfig}
            tableData={toJS(groupUsers)}
            fetchTableData={this.fetchTableData}
            hasMoreData={!lastUsersPage}
          />
        </Grid>
      </Grid>
    );
  }
}

export default compose(
  withTranslation(),
  withStyles(styles),
  inject('NetworkStore'),
  observer
)(ParticipantsTable);
