/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

// Material-UI
import { Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// components
import RemoveBtn from './RemoveBtn';

// Utils
import { compose } from '@Utils';

// Import styles
import styles from './styles';

const ProfileBtns = ({
  t,
  classes,
  currentUser: { status },
  handleBlockBtn,
  handleUserDelete,
  handleUnblockBtn
}) => {
  switch (status) {
    case 'REMOVED':
      return null;
    case 'BLOCKED':
      return (
        <Grid
          item
          xs={12}
          className={classNames(classes.bottomSpace, classes.solidBorderBottom)}
        >
          <RemoveBtn classes={classes} handleUserDelete={handleUserDelete} />
          <Button
            id="unblock"
            variant="outlined"
            color="default"
            onClick={handleUnblockBtn}
            className={classes.button}
          >
            {t('common.unblock')}
          </Button>
        </Grid>
      );
    case 'INVITED':
      return (
        <Grid
          item
          xs={12}
          className={classNames(classes.bottomSpace, classes.solidBorderBottom)}
        >
          <RemoveBtn classes={classes} handleUserDelete={handleUserDelete} />
        </Grid>
      );
    default:
      return (
        <Grid
          item
          xs={12}
          className={classNames(classes.bottomSpace, classes.solidBorderBottom)}
        >
          <RemoveBtn classes={classes} handleUserDelete={handleUserDelete} />
          <Button
            id="Block"
            variant="outlined"
            color="default"
            onClick={handleBlockBtn}
            className={classes.button}
          >
            {t('common.block')}
          </Button>
        </Grid>
      );
  }
};

ProfileBtns.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  handleBlockBtn: PropTypes.func,
  handleUnblockBtn: PropTypes.func,
  handleUserDelete: PropTypes.func
};

export default compose(
  withTranslation(),
  withStyles(styles)
)(ProfileBtns);
