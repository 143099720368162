/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

const styles = theme => ({
  margin: {
    margin: theme.spacing(1)
  },
  padding: {
    padding: theme.spacing(3)
  },
  alignRight: {
    textAlign: 'right'
  },
  fullHeightStretch: {
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    display: 'flex',
    height: '100%',
    flex: 1,
    flexDirection: 'column',
    position: 'relative'
  },
  formControl: {
    paddingBottom: theme.spacing(3),
    width: '100%'
  },
  inviteContainer: {
    width: '80%'
  },
  actionsContainer: {
    textAlign: 'right',
    margin: theme.spacing(3)
  },
  bottomSpace: {
    paddingBottom: theme.spacing(3)
  },
  row: {
    padding: 8,
    width: '100%'
  },
  rowInner: {
    flexDirection: 'row',
    display: 'flex',
    background: '#f5f5f5',
    borderRadius: '7px',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 17
  },
  paddings: {
    padding: 8
  },
  cardsTable: {
    marginRight: theme.spacing(3)
  },
  tableTitle: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.9rem',
    fontWeight: '500'
  },
  cardName: {
    fontWeight: 500
  },
  permissionItems: {
    padding: 8,
    color: 'rgba(0, 0, 0, 0.38)',
    fontWeight: 500
  },
  transparentBtn: {
    background: 'transparent',
    boxShadow: 'none',
    fontSize: theme.typography.fontSize,
    margin: `0 ${theme.spacing(1.25)}px`,
    padding: theme.spacing(1)
  },
  editIcon: {
    marginRight: theme.spacing(1),
    fontSize: '20px'
  },
  paddingsCustoms: {
    padding: 8,
    paddingTop: 30,
    paddingBottom: 30
  },
  horizontalLine: {
    widh: '100%',
    height: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    marginTop: 30,
    marginBottom: 30
  },
  button: {
    padding: `0 ${theme.spacing(5)}px`,
    marginLeft: `${theme.spacing(2)}px`
  },
  identifier: {
    fontFamily: theme.typography.fontFamily,
    borderBottom: '1px dotted rgba(0, 0, 0, 0.42)',
    display: 'block',
    padding: `${theme.spacing(1)}px 0`,
    maxWidth: '300px'
  },
  removeBtn: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  nameField: {
    color: '#000'
  },
  editBtn: {
    marginTop: -theme.spacing(8)
  },
  modalButton: {
    color: '#2196f3'
  }
});
export default styles;
