/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

// Material UI
import { Grid } from '@material-ui/core';

// Utils
import { compose } from '@Utils';

// Components
const TextField = React.lazy(() =>
  import('@CommonScene/materialUIComponents/textField')
);

const fields = {
  text: <TextField />,
  number: <TextField type="number" />
};

const renderField = fieldData => {
  if (fieldData.wrapper) {
    const Wrapper = fieldData.wrapper.component;
    return (
      <Wrapper {...fieldData.wrapper.props}>
        {React.cloneElement(fields[fieldData.type], fieldData)}
      </Wrapper>
    );
  }
  return React.cloneElement(fields[fieldData.type], fieldData);
};

const FormFieldsGenerator = ({ config }) => {
  return (
    <Grid container>
      <Suspense fallback="">
        {Object.keys(config.fields).map(key => renderField(config.fields[key]))}
      </Suspense>
    </Grid>
  );
};

FormFieldsGenerator.propTypes = {
  config: PropTypes.object.isRequired
};

export default compose(observer)(FormFieldsGenerator);
