/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

// Components
import {
  MainContentWrapper,
  PaymentsSummary,
  FromToDateFilter,
  DataTable
} from '@CommonScene';

// Configs
import { tableConfig } from '@Assets/config/tables/serviceView';

// Utils
import { compose } from '@Utils';
import { getDateFilterKey } from '@Utils/formatting';

export class ServiceView extends Component {
  static propTypes = {
    ServicePaymentsStore: PropTypes.shape({
      aggregatedServiceSummary: PropTypes.object,
      aggregatedServiceOptions: PropTypes.object,
      aggregatedServiceTransactions: PropTypes.array,
      getAggregatedServiceSummary: PropTypes.func,
      getAggregatedServiceTransactions: PropTypes.func,
      dateRange: PropTypes.object,
      setDateRange: PropTypes.func,
      setPage: PropTypes.func,
      setOrder: PropTypes.func,
      clearAggregatedServiceSummary: PropTypes.func,
      clearAggregatedServiceTransactions: PropTypes.func,
      updateAggregatedSearchCriteria: PropTypes.func,
      clearAggregatedSearchCriteria: PropTypes.func,
      clearPaging: PropTypes.func
    }).isRequired
  };

  componentDidMount() {
    const {
      ServicePaymentsStore: {
        setOrder,
        dateRange,
        getAggregatedServiceSummary,
        getAggregatedServiceTransactions,
        clearAggregatedServiceTransactions,
        updateAggregatedSearchCriteria
      }
    } = this.props;

    setOrder({ orderBy: tableConfig.defaultOrderBy });
    clearAggregatedServiceTransactions();
    updateAggregatedSearchCriteria(dateRange);
    getAggregatedServiceSummary();
    getAggregatedServiceTransactions();
  }

  componentWillUnmount() {
    const {
      ServicePaymentsStore: {
        clearPaging,
        clearAggregatedSearchCriteria,
        clearAggregatedServiceSummary
      }
    } = this.props;

    clearPaging();
    clearAggregatedSearchCriteria();
    clearAggregatedServiceSummary();
  }

  fetchData = () => {
    const {
      ServicePaymentsStore: { setPage, getAggregatedServiceTransactions }
    } = this.props;

    setPage();
    getAggregatedServiceTransactions();
  };

  updateTableData = searchCriteria => {
    const {
      ServicePaymentsStore: {
        updateAggregatedSearchCriteria,
        clearPaging,
        clearAggregatedServiceSummary,
        clearAggregatedServiceTransactions,
        getAggregatedServiceSummary,
        getAggregatedServiceTransactions
      }
    } = this.props;

    updateAggregatedSearchCriteria(searchCriteria);
    clearPaging();
    clearAggregatedServiceSummary();
    clearAggregatedServiceTransactions();
    getAggregatedServiceSummary();
    getAggregatedServiceTransactions();
  };

  handleChangeDate = (date, key) => {
    const {
      ServicePaymentsStore: { dateRange, setDateRange }
    } = this.props;

    setDateRange({
      ...dateRange,
      [getDateFilterKey(key)]: date
    });
    this.updateTableData({ [getDateFilterKey(key)]: date });
  };

  getHeaderOptions = () => ({
    title: i18n.t('can.services.title')
  });

  render() {
    const {
      ServicePaymentsStore: {
        aggregatedServiceTransactions,
        aggregatedServiceSummary,
        aggregatedServiceOptions,
        dateRange
      }
    } = this.props;

    return (
      <MainContentWrapper headerOptions={this.getHeaderOptions()}>
        <FromToDateFilter
          handleChangeDate={this.handleChangeDate}
          dateRange={{ from: dateRange.from_date, to: dateRange.to_date }}
        />
        <PaymentsSummary transactionsSummary={aggregatedServiceSummary} />
        <DataTable
          tableConfig={tableConfig}
          tableData={aggregatedServiceTransactions}
          fetchTableData={this.fetchData}
          hasMoreData={!aggregatedServiceOptions.last}
        />
      </MainContentWrapper>
    );
  }
}

export default compose(
  inject('ServicePaymentsStore'),
  observer
)(ServiceView);
