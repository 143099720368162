/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { toJS } from 'mobx';
import { withTranslation } from 'react-i18next';

// Material-UI
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Grid,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  StepConnector
} from '@material-ui/core';

// Components
import InviteOptionsForm from '@RolesScene/scenes/common/InviteOptionsForm';
import RolePermissionForm from '@RolesScene/scenes/AddRole/components/RolePermissionForm';

// Utils
import { compose } from '@Utils';

// Styles
import styles from './styles';

class CreateForm extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    CommonStore: PropTypes.object,
    RoleStore: PropTypes.object,
    DeckStore: PropTypes.object
  };

  state = {
    activeStep: 0
  };

  componentDidMount() {
    this.props.DeckStore.resetCheckedDecks();
  }

  componentWillUnmount() {
    this.props.RoleStore.clearCreateRoleForm();
  }

  handleNext = () => {
    if (this.state.activeStep === 1) {
      this.submitForm();
    }

    this.setState(state => ({
      activeStep: state.activeStep + 1
    }));
  };

  handleCancel = () => {
    this.props.history.push('/roles');
  };

  handleBack = () => {
    this.props.DeckStore.resetCheckedDecks();
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
  };

  submitForm = () => {
    this.props.RoleStore.createRole().then(role => {
      if (role.id) {
        this.props.history.replace(`/roles/view/${role.id}`);
      } else {
        this.props.history.replace('/roles');
      }
    });
  };

  isAddRoleDisabled = () => {
    const {
      DeckStore: { checkedDecks }
    } = this.props;

    return !Object.values(checkedDecks).some(item => item);
  };

  render() {
    const { activeStep } = this.state;
    const { classes, RoleStore, t } = this.props;

    const getStepContent = step => {
      switch (step) {
        case 0:
          return (
            <InviteOptionsForm
              onFieldChange={RoleStore.onFieldChange}
              formData={toJS(RoleStore.forms.createRole)}
              formName="createRole"
            />
          );
        case 1:
          return <RolePermissionForm />;
        default:
          return 'Unknown step';
      }
    };

    const renderBtns = (step, handleBack, handleNext, handleCancel) => {
      const formError = !RoleStore.forms.createRole.meta.isValid;

      switch (step) {
        case 0:
          return (
            <div className={classes.alignRight}>
              <Button
                onClick={handleCancel}
                className={classNames(classes.buttonBack, classes.marginRight)}
              >
                {t('common.cancel')}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleNext}
                disabled={formError}
                className={classes.button}
              >
                {t('common.next')}
              </Button>
            </div>
          );
        case 1:
          return (
            <div className={classes.alignRight}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classNames(classes.buttonBack, classes.marginRight)}
              >
                {t('common.back')}
              </Button>
              <Button
                disabled={this.isAddRoleDisabled()}
                variant="contained"
                color="secondary"
                onClick={handleNext}
                className={classes.button}
              >
                {t('mwAdmin.roles.addRole')}
              </Button>
            </div>
          );
        default:
          return 'Unknown step';
      }
    };

    const steps = [
      t('mwAdmin.roles.invitationOptions'),
      t('mwAdmin.roles.cardsAccess')
    ];

    return (
      <div>
        <Stepper
          connector={activeStep === 0 ? null : <StepConnector />}
          activeStep={activeStep}
          orientation="vertical"
          classes={{
            root: classes.stepperRoot
          }}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel
                StepIconProps={{
                  classes: {
                    root: classes.step,
                    completed: classes.stepCompleted,
                    active: classes.stepActive,
                    disabled: classes.stepDisabled
                  }
                }}
                classes={{
                  active: classes.textActive,
                  completed: classes.textCompleted,
                  label: classes.label
                }}
              >
                {label}
              </StepLabel>
              <StepContent>
                <div className={classes.form}>{getStepContent(index)}</div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        <Grid item xs={12}>
          <div className={classes.actionsContainer}>
            {renderBtns(
              activeStep,
              this.handleBack,
              this.handleNext,
              this.handleCancel
            )}
          </div>
        </Grid>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  withStyles(styles),
  withRouter,
  inject('RoleStore', 'DeckStore'),
  observer
)(CreateForm);
