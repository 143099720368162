/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

// Material UI
import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
  withStyles
} from '@material-ui/core';

// Components
import RoleRow from '@NetworkScene/scenes/ViewNetwork/components/RoleRow';

// Utils
import { compose } from '@Utils';

// Styles
import styles from './styles';

export const NetworkRolePanel = ({
  t,
  classes,
  currentUser,
  currentUserRoles
}) => {
  return (
    <Fragment>
      <Grid container className={classes.padding}>
        <Grid item xs={12}>
          <Typography variant="h5" id="roles-component-title">
            {t('mwAdmin.sidebar.roles')}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.root}>
        <Table>
          <TableHead>
            <TableRow id="roles-table-header">
              <TableCell>
                {t('mwAdmin.networkParticipants.assignedRoles')}
              </TableCell>
              <TableCell align="left">{t('common.customFields')}</TableCell>
              <TableCell align="right">{t('common.actions')}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody id="roles-body">
            {currentUserRoles
              ? Object.keys(currentUserRoles).map((role, roleIndex) => (
                  <RoleRow
                    key={`Role-${roleIndex}`}
                    classes={classes}
                    currentUser={currentUser}
                    currentUserRoles={currentUserRoles}
                    role={role}
                  />
                ))
              : null}
          </TableBody>
        </Table>
      </Grid>

      <Grid container className={classes.padding}>
        <Grid item xs={12} align="right">
          <Button
            variant="outlined"
            color="default"
            className={classes.button}
            component={Link}
            to={`/networks/add-role/${currentUser.id}`}
          >
            {t('mwAdmin.roles.addRole')}
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
};

NetworkRolePanel.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  currentUserRoles: PropTypes.object
};

export default compose(
  withTranslation(),
  withStyles(styles),
  observer
)(NetworkRolePanel);
