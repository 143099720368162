/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import shortid from 'shortid';
import i18n from 'i18next';

// Components
import RolesFilter from '@InvitationsScene/scenes/InvitationsList/components/RolesFilter';

// Utils
import {
  formatDate,
  formatPhoneNumber,
  formatUserStatus
} from '@Utils/formatting';

import { translation } from '@Utils/translation';

// Material UI
import { Refresh, Person } from '@material-ui/icons';

// Stores
import InvitationStore from '@Stores/InvitationStore';
import CommonStore from '@Stores/CommonStore';

const renderRolesNames = key => translation(key, 'rolesNames');

const renderRolesNamesForACN = roles =>
  roles.map((key, i) => {
    const divider = i === roles.length - 1 ? '' : '; ';
    return (
      <React.Fragment key={shortid.generate()}>
        {`${translation(key, 'rolesNames')}${divider}`}
        <br />
      </React.Fragment>
    );
  });

const chooseConfigForRolesNames = () => {
  if (CommonStore.isACN) {
    return { key: 'role_name_list', cb: renderRolesNamesForACN };
  }
  return { key: 'role_name', cb: renderRolesNames };
};

export const tableConfig = {
  defaultOrder: 'desc',
  defaultOrderBy: 'creationDate',
  hasActions: true,
  rowIdKey: 'id',
  extraIdKey: 'owner_id',
  headings: [
    {
      id: 'creationDate',
      title: i18n.t('common.created'),
      sortable: true,
      cb: InvitationStore.setOrder
    },
    i18n.t('common.name'),
    i18n.t('common.cellPhone'),
    <RolesFilter
      items={InvitationStore.filteredRolesTemplates}
      key={shortid.generate()}
    />,
    i18n.t('common.status')
  ],
  rowCells: [
    { key: 'creation_date', cb: formatDate },
    ['first_name', 'last_name'],
    { key: 'phone_number', cb: formatPhoneNumber },
    chooseConfigForRolesNames(),
    { key: 'status', cb: formatUserStatus }
  ],
  actionsConfig: {
    filterMenuItems: exlude => {
      if (exlude === 'INVITATION_ACCEPTED') {
        // TODO: Fix it. Use correct 'this' context (this.actionsConfig.menuItems)
        return tableConfig.actionsConfig.menuItems.filter(
          item => item.status !== exlude
        );
      }
      return tableConfig.actionsConfig.menuItems;
    },
    filterKey: 'status',
    menuItems: [
      {
        name: i18n.t('common.resendCode'),
        icon: <Refresh />,
        status: 'INVITATION_ACCEPTED',
        handleClick: ({ rowId }) => {
          InvitationStore.resendInvitation(rowId);
        }
      },
      {
        name: i18n.t('common.viewInviteeProfile'),
        icon: <Person />,
        isRedirect: true,
        handleClick: ({ rowId, redirectTo }) => {
          redirectTo(`/networks/view/${rowId}`);
        }
      }
    ]
  }
};
