/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import Login from '@AuthScene/scenes/Login/container';
import ResetPassword from '@AuthScene/scenes/ResetPassword/container';
import { inject, observer } from 'mobx-react/index';

const PublicRoutes = [
  {
    path: '/login',
    title: 'Login',
    section: 'Login Dashboard',
    icon: null,
    component: Login
  },
  {
    path: '/reset-password',
    title: 'Reset password',
    section: 'Reset password form',
    icon: null,
    component: ResetPassword
  }
];

@inject('CommonStore', 'AuthStore')
@observer
class PublicRoute extends React.Component {
  static propTypes = {
    AuthStore: PropTypes.object.isRequired,
    CommonStore: PropTypes.object.isRequired
  };

  render() {
    const { ...restProps } = this.props;
    this.props.CommonStore.setCurrentPath(restProps.name);
    this.props.CommonStore.setCurrentTitle(restProps.title);
    return <Route {...restProps} />;
  }
}

export { PublicRoute, PublicRoutes };
