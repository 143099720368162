/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

import { computed, observable, action, set } from 'mobx';
import { debounce, isEmpty } from 'lodash';

// Providers
import { RoleProvider } from '@Providers';

// stores
import CommonStore from '@Stores/CommonStore';
import ValidationStore from '@Stores/ValidationStore';
import DeckStore from '@Stores/DeckStore';

// utils
import { DELAY_REQUEST } from '@Utils/constans';

//  config
import { RolesForms as forms } from '@Assets/config/configForRolesForms';

class RoleStore extends ValidationStore {
  @observable errors = null;

  @observable query = '';

  @observable lastPage = false;

  @observable rowsPerPage = 25;

  @observable page = 0;

  @observable order = 'asc';

  @observable orderBy = 'name';

  @observable role = {};

  @observable forms = forms;

  @observable error = null;

  @observable deleteRequestConfirmationIsOpen = false;

  roles = observable.array([], { deep: false });

  filter = observable.array([], { deep: false });

  deleteRoleId = null;

  @computed get filteredRoles() {
    return this.filter.length
      ? this.roles.filter(role => this.filter.includes(role.id))
      : this.roles;
  }

  // Not usable for now. Just for Sonar validation
  set filteredRoles(value) {
    this.roles = value;
  }

  @action('roleStore => _handleRequestError') _handleRequestError = err => {
    this.errors =
      (err.response && err.response.body && err.response.body.errors) || err;
    throw err;
  };

  // TODO Remove it after finishing all roles forms validation implementation
  @action setFormField(key, val) {
    this.role[key] = val;
  }

  @action('roleStore => setEditFormFields') setEditFormFields = () => {
    Object.keys(this.forms.editRole.fields).forEach(field => {
      this.forms.editRole.fields[field].value = this.role[field];
    });
  };

  @action('roleStore => getRoles') getRoles = (
    page = this.page,
    rows = this.rowsPerPage
  ) => {
    CommonStore.setPending();
    let queryS = {
      page,
      rows
    };

    queryS = { ...queryS, sort: `${this.orderBy},${this.order}` };

    if (this.query) {
      queryS = { ...queryS, searchStr: this.query };
    }
    return RoleProvider.getRoles(queryS)
      .then(
        action('roleStore => handle response in getRoles', response => {
          this.setPagesParams(response);
          this.setRoles(response);
        })
      )
      .catch(this._handleRequestError)
      .finally(CommonStore.clearPending);
  };

  @action('roleStore => getRole') getRole = id => {
    CommonStore.setPending();
    return RoleProvider.getRole(id)
      .then(
        action('roleStore => handle response in getRole', response => {
          set(this.role, response);
        })
      )
      .catch(this._handleRequestError)
      .finally(CommonStore.clearPending);
  };

  @action('roleStore => createRole') createRole() {
    const data = {
      name: this.forms.createRole.fields.name.value,
      permissions: DeckStore.deckPermissions
    };

    CommonStore.setPending();
    return RoleProvider.createRole(data)
      .then(
        action('roleStore => handle response in createRole', role => {
          DeckStore.resetCheckedDecks();
          return role;
        })
      )
      .catch(this._handleRequestError)
      .finally(CommonStore.clearPending);
  }

  @action('roleStore => updateRole') updateRole() {
    const role = { ...this.role, name: this.forms.editRole.fields.name.value };
    const decksPermissions = DeckStore.deckPermissions;

    CommonStore.setPending();
    if (!isEmpty(decksPermissions)) {
      role.permissions = decksPermissions;
    }
    return RoleProvider.updateRole(role)
      .then(
        action('roleStore => handle response in updateRole', updatedRole => {
          this.clearEditRoleForm();
          return updatedRole;
        })
      )
      .catch(this._handleRequestError)
      .finally(CommonStore.clearPending);
  }

  @action('roleStore => removeRole') removeRole = (id = this.deleteRoleId) => {
    CommonStore.setPending();
    return RoleProvider.deleteRole(id)
      .then(
        action('roleStore => handle response in removeRole', () => {
          this._dropExistingRole(id);
          this.clearTemporaryId();
        })
      )
      .catch(
        action('roleStore => handle error in removeRole', err => {
          const error = err.response.body.error;
          this.error = error;

          CommonStore.setModalOptions({
            modalName: 'ModalErrorRemovingRole',
            modalProps: {
              haveErrors: error,
              clearErrorMessage: () => {
                this.clearErrorMessage();
                this.clearTemporaryId();
                CommonStore.delModalOptions();
              }
            }
          });

          throw err;
        })
      )
      .finally(CommonStore.clearPending);
  };

  @action('roleStore => handleRoleDeleteClick') handleRoleDeleteClick = (
    id,
    isOpen = true
  ) => {
    this.deleteRoleId = id;
    this.deleteRequestConfirmationIsOpen = isOpen;
  };

  @action('roleStore => closeActionDialog') closeActionDialog = () => {
    this.deleteRoleId = null;
    this.deleteRequestConfirmationIsOpen = false;
  };

  @action('roleStore => setDeleteRoleId') setDeleteRoleId = id => {
    this.deleteRoleId = id;
  };

  @action('roleStore => clearTemporaryId') clearTemporaryId() {
    this.deleteRoleId = null;
  }

  @action('roleStore => clearCreateRoleForm') clearCreateRoleForm = () => {
    this.forms.createRole.fields.name.value = '';
    this.forms.createRole.meta.isValid = false;
  };

  @action('roleStore => clearEditRoleForm') clearEditRoleForm() {
    this.forms.editRole.fields.name.value = '';
    this.forms.editRole.meta.isValid = true;
  }

  @action('roleStore => setPagesParams') setPagesParams({ last }) {
    this.lastPage = last;
  }

  @action('roleStore => setRoles') setRoles = ({ content }) => {
    this.roles.replace(content);
  };

  @action('roleStore => setQuery') setQuery = query => {
    this.query = query;
    this._searchWithDebounce();
  };

  @action setOrder = () => {
    this.page = 0;
    this.roles.replace([]);
    if (this.order === 'asc') {
      this.order = 'desc';
    } else {
      this.order = 'asc';
    }
    this.getRoles(this.page);
  };

  @action('roleStore => _searchQuery') _searchQuery = () => {
    this._resetRoles();
    this.resetPagination();
    this.getRoles();
  };

  _searchWithDebounce = debounce(this._searchQuery, DELAY_REQUEST);

  @action('roleStore => clearQuery') clearQuery = () => {
    this.query = '';
  };

  @action('roleStore => dropExistingRole') _dropExistingRole(id) {
    const newRoles = this.roles.filter(role => role.id !== id);

    this.roles.replace(newRoles);
  }

  @action('roleStore => handleChangePage') handleChangePage = page => {
    this.page = page;
    this.getRoles();
  };

  @action('roleStore => clearErrorMessage') clearErrorMessage() {
    this.error = null;
  }

  @action('roleStore => resetPagination') resetPagination() {
    this.page = 0;
  }

  @action('roleStore => _resetOrder') _resetOrder() {
    this.order = 'asc';
  }

  @action('roleStore => _resetRoles') _resetRoles = () => {
    this.roles.replace([]);
  };

  @action('roleStore => reset store') reset = () => {
    this.resetPagination();
    this.clearQuery();
    this._resetRoles();
    this._resetOrder();
  };
}

export default new RoleStore();
