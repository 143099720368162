/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { toJS } from 'mobx';
import { withTranslation } from 'react-i18next';

// Material UI
import { Button, Paper, Grid, withStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

// Components
import ProfileBtns from '@NetworkScene/scenes/ViewNetwork/components/ProfileBtns';
import { TextField } from '@CommonScene';

// Utils
import classNames from 'classnames';
import { formatDate, formatPhoneNumber } from '@Utils/formatting';
import { compose } from '@Utils';
import { translatedStatuses } from '@Assets/config/configForStatuses';

// Styles
import styles from './styles';

export class NetworkProfilePanel extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    NetworkStore: PropTypes.shape({
      getUser: PropTypes.func.isRequired,
      resetCurrentUser: PropTypes.func.isRequired,
      setBlockUser: PropTypes.func.isRequired,
      setUserId: PropTypes.func.isRequired,
      setUnblockUser: PropTypes.func.isRequired,
      resetForms: PropTypes.func.isRequired,
      deleteUser: PropTypes.func.isRequired,
      currentUserRoles: PropTypes.object,
      currentUser: PropTypes.object
    }),
    CommonStore: PropTypes.shape({
      setModalOptions: PropTypes.func.isRequired
    }),
    classes: PropTypes.object.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    })
  };

  handleBlockBtn = () => {
    const {
      userId,
      CommonStore: { setModalOptions },
      NetworkStore: { setBlockUser, setUserId }
    } = this.props;

    setUserId(userId);
    setModalOptions({
      modalName: 'ConfirmationAction',
      modalProps: { handleConfirmAction: setBlockUser }
    });
  };

  handleUnblockBtn = () => {
    const {
      userId,
      CommonStore: { setModalOptions },
      NetworkStore: { setUnblockUser, setUserId }
    } = this.props;

    setUserId(userId);
    setModalOptions({
      modalName: 'ConfirmationAction',
      modalProps: {
        handleConfirmAction: setUnblockUser
      }
    });
  };

  handleUserDelete = () => {
    const {
      userId,
      NetworkStore: { setUserId, resetForms },
      CommonStore: { setModalOptions }
    } = this.props;

    setUserId(userId);
    setModalOptions({
      modalName: 'ModalDeleteConfirmation',
      modalProps: {
        handleConfirmAction: this.handleConfirmDelete,
        handleCancelAction: resetForms
      }
    });
  };

  handleConfirmDelete = () => {
    const {
      NetworkStore: { deleteUser },
      history
    } = this.props;

    deleteUser().then(() => {
      history.push('/networks');
    });
  };

  isEditButtonShown = () => {
    const {
      NetworkStore: {
        currentUser: { status },
        currentUserRoles
      }
    } = this.props;
    const roles = toJS(currentUserRoles);

    if (status === 'REMOVED') {
      return false;
    }

    if (roles) {
      return !Object.keys(roles).some(item => item === 'SUPPORT_MW');
    }
    return false;
  };

  getPlainValue(value, defaultValue = '') {
    return value || defaultValue;
  }

  render() {
    const {
      t,
      userId,
      classes,
      NetworkStore: { currentUser }
    } = this.props;

    return (
      <React.Fragment>
        <Grid container className={classes.padding}>
          <Grid item xs={4}>
            <Paper className={classes.profileField} elevation={0}>
              <TextField
                label={t('common.name')}
                value={this.getPlainValue(currentUser.first_name)}
                name="first_name"
                disabled
              />
              <TextField
                label={t('common.surname')}
                value={this.getPlainValue(currentUser.last_name)}
                name="last_name"
                disabled
              />
              <TextField
                label={t('common.cellPhone')}
                value={formatPhoneNumber(currentUser.phone_number)}
                name="phone_number"
                disabled
              />
              {currentUser.care_wallet_id ? (
                <TextField
                  label={t('common.careWalletId')}
                  value={this.getPlainValue(currentUser.care_wallet_id)}
                  name="care_wallet_id"
                  disabled
                />
              ) : null}
            </Paper>
          </Grid>

          <Grid item xs={4}>
            <Paper className={classes.profileField} elevation={0}>
              <TextField
                className={classNames(classes[currentUser.status])}
                label={t('common.status')}
                value={t(translatedStatuses[currentUser.status])}
                name="status"
                disabled
              />
              {currentUser.joined_at ? (
                <TextField
                  label={t('mwAdmin.networkParticipants.joinedNetwork')}
                  value={formatDate(currentUser.joined_at)}
                  name="joined_at"
                  disabled
                />
              ) : null}
              <TextField
                label={t('mwAdmin.networkParticipants.lastUpdated')}
                value={formatDate(currentUser.updated_at)}
                name="joined_at"
                disabled
              />
              {currentUser.removal_reason ? (
                <TextField
                  label={t('mwAdmin.networkParticipants.removalReason')}
                  value={currentUser.removal_reason}
                  name="removal_reason"
                  disabled
                />
              ) : null}
            </Paper>
          </Grid>
          {this.isEditButtonShown() ? (
            <Grid item xs={4} align="right">
              <Button
                className={classNames(classes.button, classes.editBtn)}
                component={Link}
                to={`/networks/edit/${userId}`}
              >
                <EditIcon
                  className={classNames(classes.rightIcon, classes.iconSmall)}
                />
                {t('common.edit')}
              </Button>
            </Grid>
          ) : null}
        </Grid>

        <Grid
          container
          className={classNames(
            classes.padding,
            classes.bottomPaddingZero,
            classes.textAlignRight
          )}
        >
          <ProfileBtns
            currentUser={currentUser}
            handleBlockBtn={this.handleBlockBtn}
            handleUnblockBtn={this.handleUnblockBtn}
            handleUserDelete={this.handleUserDelete}
          />
        </Grid>
      </React.Fragment>
    );
  }
}

export default compose(
  withTranslation(),
  withStyles(styles),
  inject('CommonStore', 'NetworkStore'),
  observer
)(NetworkProfilePanel);
