/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// Material ui
import { Paper, Typography, withStyles } from '@material-ui/core';

// Utils
import { compose } from '@Utils';
import { addCommasInNumber } from '@Utils/formatting';

// Styles
import styles from './styles';

const PaymentsSummary = ({
  classes,
  transactionsSummary,
  currencyName,
  tokenName,
  t
}) => (
  <Paper elevation={5} className={classes.paper}>
    <Typography variant="subtitle2">{t('can.common.summary')}</Typography>
    <div className={classes.row}>
      <Typography className={classes.cell}>
        {t('can.common.transactions')}:
      </Typography>
      <Typography className={classes.cell}>
        {transactionsSummary.total_transactions_count || 0}
      </Typography>
    </div>
    <div className={classes.row}>
      <Typography className={classes.cell}>
        {t('can.common.totalAmount')}:
      </Typography>
      <Typography className={classes.cell}>{`${addCommasInNumber(
        transactionsSummary.total_fiat_amount
      ) || 0} ${currencyName}`}</Typography>
      <Typography className={classes.cell}>{`${addCommasInNumber(
        transactionsSummary.total_solve_amount
      ) || 0} ${tokenName}`}</Typography>
    </div>
  </Paper>
);

PaymentsSummary.defaultProps = {
  currencyName: 'USD',
  tokenName: 'SOLVE'
};

PaymentsSummary.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  transactionsSummary: PropTypes.object.isRequired,
  currencyName: PropTypes.string,
  tokenName: PropTypes.string
};

export default compose(
  withTranslation(),
  withStyles(styles)
)(PaymentsSummary);
