/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';

// Material-UI
import { TableCell, Typography } from '@material-ui/core';

export const renderCustomField = (fields, fieldName, detailName, classes) => {
  const field = fields.find(key => key.name === fieldName);
  const detail = fields.find(key => key.name === detailName);

  return (
    <Typography key={`Custom-field-${fieldName}-${field.value}`}>
      {fieldName}: {field.value}{' '}
      {detail ? (
        <span className={classes.contrastText}>({detail.value})</span>
      ) : null}
    </Typography>
  );
};

export const prepareCustomFields = role => {
  const customField = role.group.custom.find(key => key.name === 'GROUP_TYPE');
  if (customField) {
    if (customField.value === 'PROVIDER') {
      return [['TIN', 'TIN_NAME'], ['NPI', 'name']];
    }
    if (customField.value === 'PROVIDER_ORG') {
      return [['TIN', 'name']];
    }
  }
  return [];
};

export const renderCustomFields = (role, index, classes) => {
  if (role.group && (role.group.external_id === 'ACN' || role.group.id === 1)) {
    return '-';
  }
  return (
    <div id="custom-field" key={role.id} className={classes.fieldsWrapper}>
      {prepareCustomFields(role).map(group =>
        renderCustomField(role.group.custom, group[0], group[1], classes)
      )}
    </div>
  );
};

const CustomFields = ({ currentUserRoles, classes }) => (
  <TableCell align="left" className={classes.roleCell}>
    {currentUserRoles
      .filter(item => item.group.status !== 'REMOVED')
      .map((field, index) => renderCustomFields(field, index, classes))}
  </TableCell>
);

CustomFields.propTypes = {
  classes: PropTypes.object,
  currentUserRoles: PropTypes.array
};

export default CustomFields;
