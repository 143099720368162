/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Material UI
import { Paper, Grid, Typography } from '@material-ui/core';

// Components
import { MainContentWrapper } from '@CommonScene/index';
import ButtonBlock from '@ServiceWalletScene/scenes/Withdrawals/scenes/NewWithdrawal/components/ButtonsBlock';
import { FormFieldsGenerator } from '@CommonScene';

// Utils
import { compose } from '@Utils';
import { useStore } from '@Utils/context';

// Styles
import useStyles from './styles';

const NewWithdrawal = props => {
  const classes = useStyles();
  const {
    WithdrawalStore: {
      createWithdrawalTicket,
      isWithdrawalTicketCreated,
      clearCreatedWithdrawalTicket,
      forms,
      clearFormData
    },
    ServicePaymentsStore: {
      getPermissionNodes,
      nodes,
      clearNodes,
      getServiceSummary,
      serviceSummary
    }
  } = useStore();
  const { t } = useTranslation();
  const HEADER_OPTIONS = {
    title: t('can.withdrawal.newSolveWithdrawal'),
    customButtonTitle: 'Payments',
    customButtonLink: `/wallets/${props.match.params.walletId}/payments`
  };
  const {
    history: { goBack }
  } = props;

  useEffect(() => {
    return clearFormData();
  }, []);

  useEffect(() => {
    getPermissionNodes();
    return clearNodes;
  }, []);

  useEffect(() => {
    getServiceSummary({
      serviceProviderCareWalletId: props.match.params.walletId,
      statuses: ['Paid']
    });
  }, []);

  useEffect(() => {
    if (isWithdrawalTicketCreated) {
      goBack();
    }
    return clearCreatedWithdrawalTicket;
  }, [isWithdrawalTicketCreated]);

  const submitHandler = useCallback(() => {
    createWithdrawalTicket({
      amount: forms.newWithdrawal.fields.amount.value,
      target_address: forms.newWithdrawal.fields['wallet address'].value,
      source_care_wallet_id: props.match.params.walletId
    });
  });

  const getTitle = () => {
    const currentNode = nodes.find(
      node => node.cw_id === props.match.params.walletId
    );
    return currentNode ? currentNode.description : '';
  };

  return (
    <MainContentWrapper headerOptions={HEADER_OPTIONS}>
      <Grid container>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" className={classes.typing}>
              {`${t('can.withdrawal.fromServiceWallet')}:`}
            </Typography>
            <Paper className={classes.paper}>
              <Grid container>
                <Grid item xs={6} className={classes.grey}>
                  {getTitle()}
                </Grid>
                <Grid item xs={6} className={classes.grey}>
                  {`${serviceSummary.total_solve_amount || 0} SOLVE`}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <FormFieldsGenerator config={forms.newWithdrawal} />
        </Grid>
        <ButtonBlock
          cancelHandler={goBack}
          submitHandler={submitHandler}
          isSubmitBtnActive={forms.newWithdrawal.meta.isValid}
          classes={classes}
        />
      </Grid>
    </MainContentWrapper>
  );
};

NewWithdrawal.propTypes = {
  history: PropTypes.shape({ goBack: PropTypes.func }).isRequired,
  match: PropTypes.object.isRequired
};

export default compose(observer)(NewWithdrawal);
