/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Fragment, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';
import { Switch, Redirect, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { PublicRoute, PublicRoutes } from '@Routes/PublicRoute';
import PrivateRoute from '@Routes/PrivateRoute';
import DevTools from 'mobx-react-devtools';
import i18n from 'i18next';

// Material UI
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { CssBaseline, Grid, withStyles } from '@material-ui/core';

// Components
import { Header, Footer, BaseModal } from '@CommonScene';

// Utils
import { compose } from '@Utils';
import PrivateRoutes from '@Utils/constans/privateRoutes';
import { getRoutes } from '@Utils/formatting';

// Styles
import theme from '@Assets/styles';
import styles from './styles';

// locales
// TODO: del uk locale after tests
import 'moment/locale/uk';

// Lazy Components
const Sidebar = lazy(() => import('@CommonScene/Sidebar'));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
  }

  static propTypes = {
    AuthStore: PropTypes.shape({
      logout: PropTypes.func,
      setErrorMessage: PropTypes.func
    }).isRequired,
    CommonStore: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    location: PropTypes.object,
    history: PropTypes.shape({
      replace: PropTypes.func
    })
  };

  componentDidMount() {
    const {
      AuthStore: { authData },
      CommonStore: { getUserRole }
    } = this.props;
    getUserRole();
    this.changeTitle();
    if (authData.token && this.props.location.pathname === '/login') {
      this.props.history.replace('/networks');
    }
  }

  getCurrentPath() {
    return this.props.location.pathname;
  }

  componentDidUpdate() {
    this.changeTitle();
  }

  changeTitle = () => {
    const title = this.props.CommonStore.currentTitle;
    document.title = `${title} | Master Wallet`;
  };

  onIdle = () => {
    const message = i18n.t('common.logoutReason');

    this.props.AuthStore.setErrorMessage(message);
    this.props.AuthStore.logout();
  };

  renderRoutes = () => {
    let privateRoutes = PrivateRoutes.filter(route => !!route.path);

    if (!this.props.CommonStore.isACN) {
      privateRoutes = privateRoutes.filter(
        route => !route.path.includes('/groups')
      );
    }

    if (this.props.CommonStore.isBI) {
      privateRoutes = privateRoutes.filter(
        route => !route.path.includes('/members')
      );
    }

    privateRoutes = getRoutes(privateRoutes);

    return (
      <Switch>
        {PublicRoutes.map((prop, key) => (
          <PublicRoute {...prop} key={key} />
        ))}
        {privateRoutes.map((prop, key) => (
          <PrivateRoute {...prop} key={key} />
        ))}
        <Redirect to="/not-found" />
      </Switch>
    );
  };

  renderSidebar() {
    return !this.getCurrentPath().includes('/no-admin-rights') ? (
      <Suspense fallback="">
        <Sidebar currentPath={this.getCurrentPath()} />
      </Suspense>
    ) : null;
  }

  getMainWrapperColumnsCount() {
    return !this.getCurrentPath().includes('/no-admin-rights') ? 10 : 12;
  }

  render() {
    const {
      AuthStore: { authData },
      classes
    } = this.props;

    return (
      <Fragment>
        {authData.token ? (
          <IdleTimer
            ref={ref => {
              this.idleTimer = ref;
            }}
            element={document}
            onIdle={this.onIdle}
            timeout={Number(authData.expiredTime)}
          />
        ) : null}
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            {process.env.NODE_ENV === 'development' ? <DevTools /> : null}
            <BaseModal />
            <CssBaseline />
            <Grid container spacing={0}>
              {!authData.token ? (
                this.renderRoutes()
              ) : (
                <Fragment>
                  <Header />
                  <Grid container>
                    {this.renderSidebar()}
                    <Grid
                      item
                      xs={9}
                      md={this.getMainWrapperColumnsCount()}
                      className={classes.root}
                    >
                      {this.renderRoutes()}
                    </Grid>
                  </Grid>
                  <Footer />
                </Fragment>
              )}
            </Grid>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </Fragment>
    );
  }
}

export default compose(
  withStyles(styles),
  inject('AuthStore', 'CommonStore'),
  withRouter,
  observer
)(App);
