/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// React imports
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

// Material-UI imports
import { withStyles } from '@material-ui/core/styles';
import {
  FormControlLabel,
  Checkbox,
  FormGroup,
  Grid,
  Typography
} from '@material-ui/core';

// Utils
import { translation } from '@Utils/translation';

// Component Style imports
import styles from './styles';

@inject('DeckStore')
@observer
class DeckPermissionForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = { checked: false };
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
    deck: PropTypes.object.isRequired,
    index: PropTypes.number,
    DeckStore: PropTypes.object
  };

  handleChange = ({ target: { checked } }) => {
    const {
      deck: { id },
      DeckStore: { setCheckedDecks }
    } = this.props;

    setCheckedDecks({ id, checked });
    this.setState({ checked });
  };

  render() {
    const { deck, index, classes } = this.props;
    const deckChecked = this.state.checked;

    return (
      <Grid
        container
        spacing={0}
        alignItems="center"
        className={
          index === 0
            ? classNames(classes.formFirst, classes.form)
            : classes.form
        }
      >
        <Grid item xs={6}>
          <FormGroup className={classes.formControl}>
            <FormControlLabel
              key={`deck-${deck.id}`}
              control={
                <Checkbox
                  checked={deckChecked}
                  onChange={this.handleChange}
                  value={`${deck.id}`}
                />
              }
              label={
                <Typography
                  className={classes.cardNames}
                  variant="subtitle1"
                  color="textPrimary"
                >
                  {translation(deck.name, 'cardsNames')}
                </Typography>
              }
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <FormGroup>
            {deck.permissions.map(permission => (
              <FormControlLabel
                key={permission.id}
                disabled
                control={
                  <Checkbox
                    classes={{
                      disabled: classes.disabledCheckbox
                    }}
                    checked
                  />
                }
                label={translation(permission.name, 'permissionsNames')}
              />
            ))}
          </FormGroup>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(DeckPermissionForm);
