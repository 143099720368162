/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

// Utils
import { compose } from '@Utils';
import { translation } from '@Utils/translation';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

// Styles
import styles from './styles';

export class SelectOptions extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    items: PropTypes.array
  };

  static defaultProps = {
    label: 'Label',
    required: false,
    disabled: false,
    onChange: () => {
      // Stub function
    },
    items: []
  };

  getDefaultValue() {
    return (this.props.items.length && this.props.items[0].id) || '';
  }

  renderOptions(items) {
    if (items.length > 0) {
      return items.map(item => (
        <MenuItem key={shortid.generate()} value={item.id}>
          {translation(item.name, 'rolesNames')}
        </MenuItem>
      ));
    }
    return null;
  }

  render() {
    const {
      classes,
      label,
      name,
      required,
      disabled,
      onChange,
      items,
      ...other
    } = this.props;

    return (
      <FormControl className={classes.formControl} disabled={disabled}>
        <InputLabel id="input-label" htmlFor={name}>
          {label}
        </InputLabel>
        <Select
          id="input-wrapper"
          classes={{ select: classes.selectHeight }}
          onChange={(optionName, optionValue, formName) =>
            onChange(optionName, optionValue, formName)
          }
          required={required}
          name={name}
          value={this.getDefaultValue()}
          {...other}
        >
          {this.renderOptions(items)}
        </Select>
      </FormControl>
    );
  }
}

export default compose(withStyles(styles))(SelectOptions);
