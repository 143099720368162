/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

const styles = theme => ({
  margin: {
    margin: theme.spacing(1)
  },
  formControl: {
    paddingBottom: theme.spacing(3),
    width: '100%'
  },
  rowLable: {
    fontWeight: 500,
    fontSize: '0.9rem',
    color: 'rgba(0,0,0,.54)'
  },
  infoWrapper: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    padding: `${theme.spacing(3.75)}px ${theme.spacing(2)}px`,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '7px',
    background: '#f5f5f5'
  },
  infoText: {
    color: 'rgba(0,0,0,.38)',
    fontSize: '0.9rem',
    lineHeight: 1.2
  },
  infoIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3)
  },
  identifier: {
    marginBottom: theme.spacing(2),
    fontFamily: theme.typography.fontFamily,
    borderBottom: '1px dotted rgba(0, 0, 0, 0.42)',
    display: 'block',
    padding: `${theme.spacing(1)}px 0`
  }
});

export default styles;
