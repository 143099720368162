/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react/index';
import classNames from 'classnames';
import shortid from 'shortid';

// Material UI
import { Typography, Grid, withStyles } from '@material-ui/core';

// Components
import { Link } from 'react-router-dom';

// Utils
import { compose } from '@Utils';
import PrivateRoutes from '@Utils/constans/privateRoutes';

// Styles
import styles from './styles';

export class Breadcrumbs extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    CommonStore: PropTypes.object.isRequired
  };

  renderDivider = statement =>
    statement ? <span className={this.props.classes.divider}>/</span> : null;

  renderTitle = item => (item.title ? item.title : item);

  renderBreadcrumbsContent() {
    const {
      classes,
      CommonStore: { getDynamicItem }
    } = this.props;

    const relatedRoutes = [];

    const getRelatedRoutes = path => {
      // Find and get new copy of route
      const route = { ...PrivateRoutes.find(r => r.name === path) };

      if (route.parent) {
        // Continue searching if parents still exist
        getRelatedRoutes(route.parent);
      } else if (!route.path) {
        if (route.dynamic) {
          relatedRoutes.push(getDynamicItem);
        } else {
          // Root route here. Main parent
          // Get just title if there is no path
          relatedRoutes.push(route.title);
        }
        return;
      } else {
        // Not usable for now. Just for Sonar validation
      }

      relatedRoutes.push(route);
    };

    getRelatedRoutes(this.props.CommonStore.currentPath);

    // Last item should not be a link so we remove 'path' field
    delete relatedRoutes[relatedRoutes.length - 1].path;

    return relatedRoutes.map(item =>
      item.path ? (
        <Fragment key={shortid.generate()}>
          {this.renderDivider(item.parent)}
          <Link
            key={shortid.generate()}
            id="parent-link"
            className={classes.itemLink}
            to={item.path}
          >
            {item.title}
          </Link>
        </Fragment>
      ) : (
        <Fragment key={shortid.generate()}>
          {this.renderDivider(item.parent)}
          {this.renderTitle(item)}
        </Fragment>
      )
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid container className={classes.padding}>
        <Grid
          item
          xs={12}
          className={classNames(
            classes.alignMiddle,
            classes.delimiter,
            classes.fullWidth
          )}
        >
          <Typography
            variant="subtitle1"
            className={classNames(classes.alignMiddle, classes.rowLable)}
          >
            {this.renderBreadcrumbsContent()}
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default compose(
  withStyles(styles),
  inject('CommonStore'),
  observer
)(Breadcrumbs);
