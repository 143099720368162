/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

// Material UI
import { Grid, withStyles } from '@material-ui/core';

// Components
import {
  DataTable,
  FromToDateFilter,
  MainContentWrapper,
  PaymentsSummary,
  Balance
} from '@CommonScene/index';
import { TableSearch } from '@CommonScene/MainContentWrapper/components/ContainerHeader/components';

// Utils
import { compose } from '@Utils';
import { getDateFilterKey } from '@Utils/formatting';
import { tableConfig } from '@Assets/config/tables/paymentAdminService';

// Styles
import { withTranslation } from 'react-i18next';
import styles from './styles';

export class PaymentsAdminService extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    AuthStore: PropTypes.shape({
      careWalletId: PropTypes.string
    }).isRequired,
    ServicePaymentsStore: {
      setQuery: PropTypes.func,
      clearQuery: PropTypes.func,
      clearPaging: PropTypes.func,
      clearServiceTransactions: PropTypes.func,
      clearServiceSummary: PropTypes.func,
      updateSearchCriteria: PropTypes.func,
      getServiceSummary: PropTypes.func,
      serviceSummary: PropTypes.object,
      setPage: PropTypes.func,
      setServiceWalletId: PropTypes.func,
      getServiceTransactions: PropTypes.func,
      serviceTransactions: PropTypes.array,
      serviceOptions: PropTypes.object
    },
    match: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.props.ServicePaymentsStore.getPermissionNodes();
    this.getServiceData();
  }

  getWalletId() {
    return this.props.match.params.walletId;
  }

  getTitle = () => {
    const {
      ServicePaymentsStore: { nodes }
    } = this.props;
    const currentNode = nodes.find(node => node.cw_id === this.getWalletId());
    return currentNode ? currentNode.description : '';
  };

  getHeaderOptions = () => {
    return {
      title: `${this.getTitle()} Payments`,
      customButtonTitle: 'New Withdrawal',
      customButtonLink: `/wallets/${this.getWalletId()}/withdrawals/new`
    };
  };

  getServiceData = () => {
    const {
      ServicePaymentsStore: {
        getServiceSummary,
        getServiceTransactions,
        setServiceWalletId
      }
    } = this.props;

    // for payment admin visible only paid transactions
    setServiceWalletId(this.getWalletId());
    getServiceSummary({
      serviceProviderCareWalletId: this.getWalletId(),
      statuses: ['Paid']
    });
    getServiceTransactions({
      serviceProviderCareWalletId: this.getWalletId(),
      statuses: ['Paid']
    });
  };

  updateTableData = searchCriteria => {
    const {
      ServicePaymentsStore: {
        updateSearchCriteria,
        clearServiceSummary,
        clearServiceTransactions,
        clearPaging
      }
    } = this.props;

    updateSearchCriteria(searchCriteria);
    clearPaging();
    clearServiceSummary();
    clearServiceTransactions();
    this.getServiceData();
  };

  handleChangeDate = (date, key) => {
    this.updateTableData({ [getDateFilterKey(key)]: date });
  };

  setQuery = query => {
    const {
      ServicePaymentsStore: { setQuery }
    } = this.props;

    setQuery(query);
  };

  setDefaultQuery = () => {
    const {
      ServicePaymentsStore: { clearQuery }
    } = this.props;

    clearQuery();
    this.getServiceData();
  };

  fetchTableData = () => {
    const {
      ServicePaymentsStore: { setPage, getServiceTransactions }
    } = this.props;

    setPage();
    getServiceTransactions({ serviceProviderCareWalletId: this.getWalletId() });
  };

  render() {
    const {
      classes,
      t,
      ServicePaymentsStore: { serviceSummary, serviceTransactions, query }
    } = this.props;

    return (
      <MainContentWrapper headerOptions={this.getHeaderOptions()}>
        <Balance solveBalance={serviceSummary.total_solve_amount} />
        <Grid container className={classes.filterWrapper}>
          <FromToDateFilter
            title={t('can.common.date')}
            handleChangeDate={this.handleChangeDate}
          />
          <TableSearch
            setQuery={this.setQuery}
            value={query}
            placeholder={t('can.common.sender')}
            setDefaultStatuses={this.setDefaultQuery}
          />
        </Grid>
        <PaymentsSummary transactionsSummary={serviceSummary} />
        <DataTable
          tableConfig={tableConfig}
          tableData={serviceTransactions}
          fetchTableData={this.fetchTableData}
          hasMoreData={false}
        />
      </MainContentWrapper>
    );
  }
}

PaymentsAdminService.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withTranslation(),
  withStyles(styles),
  inject('ServicePaymentsStore', 'AuthStore'),
  withRouter,
  observer
)(PaymentsAdminService);
