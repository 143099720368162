/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import shortid from 'shortid';

// Material UI
import { Grid } from '@material-ui/core';

// Components
import { MainContentWrapper } from '@CommonScene';
import TopologyCard from '@StatisticsScene/scenes/CanTopologyStatistics/components/TopologyCard';

// Utils
import { compose } from '@Utils';
import CardListItems from '../components/CardListItems';

export class CanTopologyStatistics extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    StatisticStore: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { getStatistics } = this.props.StatisticStore;
    getStatistics();
  }

  render() {
    const {
      t,
      StatisticStore: { statistic }
    } = this.props;
    const { wallets, endpoints, admins } = statistic;
    const HEADER_OPTIONS = {
      title: t('can.sidebar.canTopologyStatistics')
    };

    return (
      <MainContentWrapper headerOptions={HEADER_OPTIONS}>
        <Grid container spacing={4} alignItems="stretch" direction="row">
          {wallets.map(wallet => (
            <TopologyCard
              key={wallet.role}
              title={`${t('can.statistics.walletNodes')} - ${wallet.role}`}
            >
              <CardListItems
                items={[
                  {
                    value: (
                      <b key={shortid.generate()}>{t('can.common.total')}</b>
                    ),
                    haveBottomMargin: true
                  },
                  { value: t('common.created') },
                  { value: t('common.assigned') },
                  { value: t('common.connected') }
                ]}
              />
              <CardListItems
                items={[
                  {
                    value: <b key={shortid.generate()}>{wallet.total}</b>,
                    haveGreenSight: true,
                    haveBottomMargin: true
                  },
                  { value: wallet.created },
                  { value: wallet.assigned },
                  { value: wallet.connected }
                ]}
              />
            </TopologyCard>
          ))}
          <TopologyCard title={t('can.statistics.adminNodes')}>
            <CardListItems
              items={[
                {
                  value: <b key={shortid.generate()}>{t('can.common.total')}</b>
                },
                { value: t('can.statistics.canAdmin') },
                { value: t('can.statistics.canSponsor') }
              ]}
            />
            <CardListItems
              items={[
                {
                  value: <b key={shortid.generate()}>{admins.total}</b>,
                  haveGreenSight: true
                },
                { value: admins.can_admins },
                { value: admins.can_sponsors },
                { value: admins.payment_admins }
              ]}
            />
          </TopologyCard>
          <TopologyCard title={t('can.statistics.endPointNodes')}>
            <CardListItems
              items={[
                {
                  value: <b key={shortid.generate()}>{t('can.common.total')}</b>
                },
                ...endpoints.nodes.map(node => ({ value: node }))
              ]}
            />
            <CardListItems
              items={[
                {
                  value: <b key={shortid.generate()}>{endpoints.total}</b>,
                  haveGreenSight: true
                },
                ...endpoints.nodes.map(() => ({ value: 1 }))
              ]}
            />
          </TopologyCard>
        </Grid>
      </MainContentWrapper>
    );
  }
}

export default compose(
  withTranslation(),
  inject('StatisticStore'),
  observer
)(CanTopologyStatistics);
