/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

// Components
import { PasswordField } from '@CommonScene';
import SnackBar from '@AuthScene/scenes/common/SnackBar';
import ResetPasswordButtons from '@AuthScene/scenes/ResetPassword/components/ResetPasswordButtons';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Grid, Divider, Typography } from '@material-ui/core';

// Utils
import { compose } from '@Utils';

// Styles
import styles from './styles';

export class ResetPasswordForm extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    AuthStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
  };

  componentDidMount() {
    const {
      AuthStore: { clearInputFields }
    } = this.props;

    clearInputFields('resetPassword', ['password', 'passwordConfirm']);
  }

  handleSubmitForm = e => {
    e.preventDefault();
    return this.props.AuthStore.resetPassword().then(() => {
      this.props.history.replace('/');
    });
  };

  onChange = event => {
    const {
      AuthStore: { onFieldChange }
    } = this.props;

    onFieldChange(event.target.name, event.target.value, 'resetPassword');
  };

  preventDefault = event => {
    event.preventDefault();
  };

  render() {
    const {
      classes,
      AuthStore: { forms, clearErrorMessage, errors, handleChangeForm },
      t
    } = this.props;

    return (
      <div>
        <form onSubmit={this.handleSubmitForm}>
          <Typography
            align="left"
            gutterBottom
            variant="h5"
            component="h2"
            className={classes.headerTitle}
          >
            {t('auth.resetPassword.stage3.heading')}
          </Typography>

          <Divider className={classes.headerBottomBorder} />

          <Typography
            align="left"
            className={classNames(classes.textLabel, classes.marginBottom)}
          >
            {t('auth.resetPassword.stage3.passwordDescription')}
          </Typography>

          <Grid container spacing={1} alignItems="flex-end">
            <Grid item md sm xs>
              <PasswordField
                name="password"
                label={t('auth.resetPassword.stage3.newPasswordLabel')}
                maxLength="40"
                value={forms.resetPassword.fields.password.value}
                error={forms.resetPassword.fields.password.error}
                onChange={this.onChange}
                placeholder={t(
                  'auth.resetPassword.stage3.newPasswordPlaceholder'
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} alignItems="flex-end">
            <Grid item md sm xs>
              <PasswordField
                name="passwordConfirm"
                label={t('auth.resetPassword.stage3.confirmPasswordLabel')}
                maxLength="40"
                value={forms.resetPassword.fields.passwordConfirm.value}
                error={forms.resetPassword.fields.passwordConfirm.error}
                onCopy={this.preventDefault}
                onPaste={this.preventDefault}
                onChange={this.onChange}
                placeholder={t(
                  'auth.resetPassword.stage3.confirmPasswordPlaceholder'
                )}
              />
            </Grid>
          </Grid>

          {errors ? (
            <SnackBar
              onClose={clearErrorMessage}
              variant={'error'}
              message={errors}
            />
          ) : null}

          <ResetPasswordButtons
            isValid={forms.resetPassword.meta.isValid}
            clearErrorMessage={clearErrorMessage}
            handleChangeForm={handleChangeForm}
            step={2}
            submitBtnText="Reset"
          />
        </form>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation(),
  inject('AuthStore'),
  withRouter,
  observer
)(ResetPasswordForm);
