/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

// Material-UI
import { Button, Menu, MenuItem, FormControlLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MoreVert from '@material-ui/icons/MoreVert';

// Components
import GroupTypeCheckbox from '@GroupsScene/scenes/GroupsList/components/List/GroupTypeCheckbox';

// Styles
import styles from './styles';

// Utils
import { compose } from '@Utils';

export class GroupFilterPanel extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    GroupStore: PropTypes.shape({
      updateGroups: PropTypes.func
    }),
    items: PropTypes.array,
    classes: PropTypes.object.isRequired
  };

  state = {
    anchorEl: null
  };

  handleClick = event => this.setState({ anchorEl: event.currentTarget });

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  applyFilter = () => {
    this.props.GroupStore.updateGroups();
    this.handleClose();
  };

  render() {
    const { classes, items, t } = this.props;
    const { anchorEl } = this.state;
    const filterItems = items.map(item => (
      <MenuItem key={item.id} className={classes.menuItem}>
        <FormControlLabel
          className={classes.checkboxLabel}
          control={<GroupTypeCheckbox itemId={item.id} />}
          label={item.name}
        />
      </MenuItem>
    ));

    return (
      <div>
        <Button
          id="type-button"
          className={classes.filterWrapper}
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <span className={classes.tableHeader}>{t('common.type')}</span>
          <MoreVert />
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {filterItems}
          <div className={classes.menuItemFilter}>
            <Button id="cancel-button" onClick={this.handleClose}>
              {t('common.cancel')}
            </Button>
            <Button id="apply-button" onClick={this.applyFilter}>
              {t('common.apply')}
            </Button>
          </div>
        </Menu>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  withStyles(styles),
  inject('GroupStore'),
  observer
)(GroupFilterPanel);
