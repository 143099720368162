/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Material UI
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Grid,
  withStyles
} from '@material-ui/core';

// Components
import { TextField } from '@CommonScene';

// Utils
import { makeFullUserName } from '@Utils/display';
import { compose } from '@Utils';

// Styles
import styles from './styles';

const ModalDeleteConfirmationSnippet = ({
  NetworkStore: { userName, forms, onFieldChange },
  classes,
  handleCancelAction,
  handleConfirmAction,
  isOpen
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      id="dialog-window"
      className={classes.margin}
      open={isOpen}
      onClose={handleCancelAction}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {`${t('modals.removeParticipantPhraseStart')} ${makeFullUserName(
          userName
        )} ${t('modals.removeParticipantPhraseEnd')}`}
      </DialogTitle>
      <form id="reason-form" className={classes.form}>
        <Grid container className={classes.padding}>
          <Grid item xs={12} lg={12}>
            <TextField
              id="reason-form-field"
              name="reason"
              label={t('modals.reason')}
              value={forms.deleteForm.fields.reason.value}
              error={forms.deleteForm.fields.reason.error}
              onChange={e =>
                onFieldChange(e.target.name, e.target.value, 'deleteForm')
              }
              placeholder={t('modals.reason')}
            />
          </Grid>
        </Grid>
      </form>
      <DialogActions>
        <Button
          id="cancel-btn"
          className={classes.button}
          onClick={handleCancelAction}
          color="primary"
        >
          {t('common.cancel')}
        </Button>
        <Button
          id="confirm-btn"
          className={classes.button}
          disabled={forms.deleteForm.fields.reason.value === ''}
          onClick={handleConfirmAction}
          color="primary"
        >
          {t('common.yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ModalDeleteConfirmationSnippet.propTypes = {
  classes: PropTypes.object.isRequired,
  NetworkStore: PropTypes.shape({
    userName: PropTypes.string,
    forms: PropTypes.object,
    onFieldChange: PropTypes.func
  }).isRequired,
  handleCancelAction: PropTypes.func.isRequired,
  handleConfirmAction: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export { ModalDeleteConfirmationSnippet };

export default compose(
  withStyles(styles),
  inject('NetworkStore'),
  observer
)(ModalDeleteConfirmationSnippet);
