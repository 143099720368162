/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

// Components
import DataTable from '@CommonScene/DataTable';

// Configs
import { tableConfig } from '@Assets/config/tables/groups';

// Utils
import { compose } from '@Utils';

export class GroupListPanel extends React.Component {
  static propTypes = {
    GroupStore: PropTypes.object
  };

  componentDidMount() {
    const {
      GroupStore: { getGroups }
    } = this.props;

    getGroups();
  }

  fetchMoreData = () => {
    const {
      GroupStore: { handleChangePage, page }
    } = this.props;

    handleChangePage(page + 1);
  };

  getCustomIdField = customField => {
    if (customField.ACO) {
      return customField.ACO.value;
    }
    if (customField.NPI) {
      return customField.NPI.value;
    }
    if (customField.TIN) {
      return customField.TIN.value;
    }
    return customField.name;
  };

  getCustom = customField => {
    if (customField.ACO) {
      return 'ACO';
    }
    if (customField.NPI) {
      return 'NPI';
    }
    if (customField.TIN) {
      return 'TIN';
    }
    return '';
  };

  getGroups = () =>
    this.props.GroupStore.groups
      .filter(group => group.custom.NPI || group.custom.TIN)
      .map(item => ({
        id: item.id,
        name: item.name,
        custom: this.getCustom(item.custom),
        customIdField: this.getCustomIdField(item.custom)
      }));

  render() {
    const {
      GroupStore: { lastPage }
    } = this.props;

    return (
      <DataTable
        tableData={this.getGroups()}
        fetchTableData={this.fetchMoreData}
        tableConfig={tableConfig}
        hasMoreData={!lastPage}
      />
    );
  }
}

export default compose(
  inject('GroupStore'),
  observer
)(GroupListPanel);
