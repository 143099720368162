/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

// Material UI
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// Components
import { PhoneNumberField, SelectOptions, TextField } from '@CommonScene';

// Utils
import { compose } from '@Utils';
import { validationKeys } from '@Utils/constans';

// Styles
import styles from './styles';

export class InviteToRoleForm extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    roleTemplates: PropTypes.array.isRequired,
    userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
    formConfig: PropTypes.object.isRequired,
    isUserExist: PropTypes.bool.isRequired
  };

  handleOnChange = e => {
    const { formConfig } = this.props;

    formConfig.onFieldChange(e.target.name, e.target.value, formConfig.name);
  };

  getRoleTemplates = () => {
    const { roleTemplates, userRoles } = this.props;

    return roleTemplates.filter(role => !userRoles.includes(role.name));
  };

  render() {
    const { classes, formConfig, isUserExist, t } = this.props;

    return (
      <Grid container className={classes.padding}>
        <Grid item xs={4} lg={3}>
          <SelectOptions
            name="role"
            label={t('mwAdmin.invitations.selectRole')}
            items={this.getRoleTemplates()}
            value={formConfig.fields.role.value}
            displayEmpty
            onChange={this.handleOnChange}
            placeholder={t('mwAdmin.invitations.selectRole')}
          />
          <PhoneNumberField
            name="phone"
            label={t('common.cellPhone')}
            error={t(validationKeys[formConfig.fields.phone.error])}
            value={formConfig.fields.phone.value}
            placeholder={t('common.phone')}
            disabled={isUserExist}
            onChange={this.handleOnChange}
          />
          <TextField
            name="name"
            label={t('common.name')}
            value={formConfig.fields.name.value}
            error={formConfig.fields.name.error}
            onChange={this.handleOnChange}
            placeholder={t('common.name')}
            disabled={isUserExist}
          />
          <TextField
            name="surname"
            label={t('common.surname')}
            value={formConfig.fields.surname.value}
            error={formConfig.fields.surname.error}
            onChange={this.handleOnChange}
            placeholder={t('common.surname')}
            disabled={isUserExist}
          />
        </Grid>
      </Grid>
    );
  }
}

export default compose(
  withTranslation(),
  withStyles(styles),
  observer
)(InviteToRoleForm);
