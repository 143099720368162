/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import some from 'lodash/some';
import { withTranslation } from 'react-i18next';

// Components
import { MainContentWrapper } from '@CommonScene';
import InviteToRoleForm from '@InvitationsScene/scenes/AddInvitation/components/InviteToRoleForm';
import InviteToRoleTable from '@InvitationsScene/scenes/AddInvitation/components/InviteToRoleTable';
import InviteToRolesBtns from '@InvitationsScene/scenes/AddInvitation/components/InviteToRoleBtns';

// Utils
import { compose } from '@Utils';

export class AddInvitation extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    InvitationStore: PropTypes.object.isRequired,
    NetworkStore: PropTypes.object.isRequired,
    CommonStore: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    customField: null,
    existField: false
  };

  componentDidMount() {
    const {
      CommonStore: { isACN },
      InvitationStore: { fetchRoleTemplates, getGroupByExternalId, getAllDecks }
    } = this.props;
    const {
      NetworkStore: { getUser }
    } = this.props;

    if (this.getUserId()) {
      getUser(this.getUserId(), 'inviteEditForm');
    }
    fetchRoleTemplates().then(() => {
      if (isACN) {
        getGroupByExternalId('ACN').then(() => {
          getAllDecks().then(this.setAdminGroup);
        });
      }
    });
  }

  // Initiate check for admin group
  setAdminGroup = () => {
    const {
      InvitationStore: {
        setAdminGroup,
        forms: {
          inviteCreateForm: {
            fields: {
              role: { value }
            }
          }
        }
      }
    } = this.props;

    if (value) {
      setAdminGroup(+value);
    }
  };

  componentWillUnmount() {
    const { NetworkStore, InvitationStore } = this.props;

    InvitationStore.resetForms();
    NetworkStore.resetForms();
    NetworkStore.resetCurrentUser();
  }

  showErrorMessage = error => {
    const {
      CommonStore: { setModalOptions }
    } = this.props;

    setModalOptions({
      modalName: 'ModalInvitationError',
      modalProps: {
        error
      }
    });
  };

  handleSubmitForm = e => {
    e.preventDefault();

    const {
      InvitationStore: { AddUserRoles, inviteUser, resetForms },
      CommonStore: { isACN },
      history
    } = this.props;

    if (this.getUserId()) {
      return AddUserRoles().then(() => {
        history.replace(`/networks/view/${this.getUserId()}`);
        resetForms();
      });
    }

    return inviteUser()
      .then(() => {
        history.replace('/invitations');
        resetForms();
      })
      .catch(error => {
        this.showErrorMessage(
          isACN
            ? error.response.body.error
            : error.response.body.errors[0].defaultMessage
        );
      });
  };

  handleClose = () => {
    this.setState({ existField: false });
  };

  handleAddField = () => {
    const {
      InvitationStore: { filteredSuggestions },
      CommonStore: { setModalOptions }
    } = this.props;

    setModalOptions({
      modalName: 'AddFieldModal',
      modalProps: {
        customField: this.state.customField,
        onCustomFieldChange: this.onCustomFieldChange,
        inputOnChange: this.inputOnChange,
        filteredSuggestion: toJS(filteredSuggestions),
        existField: this.state.existField,
        handleConfirmAction: this.handleFieldCreate
      }
    });
  };

  handleEditField = editableField => {
    const {
      CommonStore: { setModalOptions },
      InvitationStore: { filteredSuggestions }
    } = this.props;

    setModalOptions({
      modalName: 'AddFieldModal',
      modalProps: {
        customField: this.state.customField,
        onCustomFieldChange: this.onCustomFieldChange,
        inputOnChange: this.inputOnChange,
        filteredSuggestion: toJS(filteredSuggestions),
        existField: this.state.existField,
        handleConfirmAction: this.editField(editableField)
      }
    });
  };

  editField = editableField => () => {
    const {
      InvitationStore: { handleFieldEdit }
    } = this.props;

    handleFieldEdit({ id: editableField, field: this.state.customField });
  };

  handleFieldCreate = () => {
    const {
      InvitationStore: { handleFieldCreate, editableField: editable }
    } = this.props;
    const { customField } = this.state;

    if (editable !== null) {
      handleFieldCreate(customField, editable);
    } else {
      handleFieldCreate(customField);
    }
    this.setState({ customField: null });
  };

  inputOnChange = event => {
    const {
      target: { value }
    } = event;

    if (!value) {
      return;
    }

    this.props.InvitationStore.getAllGroupsWithDebounce({
      searchStr: value
    });
  };

  onCustomFieldChange = field => {
    this.setState(
      {
        customField: field,
        existField: some(toJS(this.props.InvitationStore.fields), field)
      },
      () => {
        this.props.CommonStore.updateModalProps({
          customField: this.state.customField,
          existField: this.state.existField
        });
      }
    );
  };

  getUserId() {
    return this.props.match.params.userId;
  }

  getHeaderOptions() {
    const { t } = this.props;

    return {
      title: this.getUserId()
        ? t('mwAdmin.roles.addRole')
        : t('mwAdmin.invitations.inviteToRole')
    };
  }

  getFormConfig() {
    const {
      t,
      NetworkStore: {
        forms: networkForms,
        onFieldChange: networkOnFieldChange
      },
      InvitationStore: {
        forms: invitationForms,
        onFieldChange: invitationOnFieldChange
      }
    } = this.props;

    return this.getUserId()
      ? {
          name: 'inviteEditForm',
          fields: networkForms.inviteEditForm.fields,
          onFieldChange: networkOnFieldChange,
          isValid: networkForms.inviteEditForm.meta.isValid,
          submitButtonText: t('mwAdmin.roles.addRole'),
          cancelLink: '/networks'
        }
      : {
          name: 'inviteCreateForm',
          fields: invitationForms.inviteCreateForm.fields,
          onFieldChange: invitationOnFieldChange,
          isValid: invitationForms.inviteCreateForm.meta.isValid,
          submitButtonText: t('mwAdmin.invitations.inviteToRole'),
          cancelLink: '/invitations'
        };
  }

  getUserRoles = () => {
    const {
      NetworkStore: {
        currentUser: { roles }
      }
    } = this.props;

    return roles
      ? [...new Set(Object.values(roles).map(role => role.name))]
      : [];
  };

  render() {
    const {
      InvitationStore: {
        roleTemplates,
        isFieldsList,
        fields,
        isNPI,
        adminGroup
      }
    } = this.props;
    const userRoles = this.getUserRoles();

    return (
      <MainContentWrapper headerOptions={this.getHeaderOptions()}>
        <InviteToRoleForm
          formConfig={this.getFormConfig()}
          isUserExist={!!this.getUserId()}
          roleTemplates={toJS(roleTemplates)}
          userRoles={userRoles}
        />
        <InviteToRoleTable
          value={this.getFormConfig().fields.role.value}
          isFieldsList={isFieldsList}
          fields={fields}
          handleAddField={this.handleAddField}
          handleEditField={this.handleEditField}
          isNPI={isNPI}
        />
        <InviteToRolesBtns
          formConfig={this.getFormConfig()}
          adminGroup={adminGroup}
          fieldsLength={fields.length}
          handleSubmitForm={this.handleSubmitForm}
        />
      </MainContentWrapper>
    );
  }
}

export default compose(
  withTranslation(),
  inject('InvitationStore', 'NetworkStore', 'CommonStore'),
  observer
)(AddInvitation);
