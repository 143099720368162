/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Store
import WithdrawalStore from '@Stores/WithdrawalStore';

// Utils
import { translation } from '@Utils/translation';

export const tableConfig = {
  defaultOrder: 'desc',
  defaultOrderBy: 'owner',
  handleRowClick: (event, id, redirectTo) => {
    event.stopPropagation();

    const service = WithdrawalStore.aggregatedServiceTransactions.find(
      transaction => transaction.owner_id === id
    );

    WithdrawalStore.setServiceWalletId(id);
    redirectTo(`/payments/service/${service.owner.replace(/ /g, '')}`);
  },
  rowIdKey: 'owner_id',
  headings: [
    {
      id: 'owner',
      title: 'Service',
      sortable: true,
      cb: () => {
        WithdrawalStore.sortData({ orderBy: 'owner' });
      }
    },
    {
      id: 'payment_target',
      title: 'Benefit',
      sortable: true,
      cb: () => {
        WithdrawalStore.sortData({ orderBy: 'payment_target' });
      }
    },
    {
      id: 'transactions_count',
      title: 'Transactions',
      sortable: true,
      cb: () => {
        WithdrawalStore.sortData({
          orderBy: 'transactions_count'
        });
      }
    },
    {
      id: 'fiat_amount',
      title: 'USD Amount',
      sortable: true,
      cb: () => {
        WithdrawalStore.sortData({ orderBy: 'fiat_amount' });
      }
    },
    {
      id: 'solve_amount',
      title: 'SOLVE Amount',
      sortable: true,
      cb: () => {
        WithdrawalStore.sortData({ orderBy: 'solve_amount' });
      }
    }
  ],
  rowCells: [
    'owner',
    { key: 'payment_target', cb: value => translation(value, 'sponsorNames') },
    'transactions_count',
    'fiat_amount',
    'solve_amount'
  ]
};
