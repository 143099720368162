/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import { withTranslation } from 'react-i18next';

// Material UI
import { Grid, TextField, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// Utils
import { compose } from '@Utils';

// Components
import Suggestion from './Suggestion';

import styles from './styles';

export class CustomFieldsForm extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    inputOnChange: PropTypes.func.isRequired,
    filteredSuggestions: PropTypes.array,
    existField: PropTypes.bool.isRequired
  };

  state = {
    type: '',
    name: '',
    value: ''
  };

  onSelectField = ({ type, name, value }) => {
    this.setState({
      type,
      name,
      value
    });
  };

  onChangeField = inputValue => {
    const { value } = this.state;

    if (value !== inputValue) {
      this.setState({
        type: '',
        name: '',
        value: ''
      });
    }
  };

  render() {
    const {
      t,
      classes,
      onChange,
      inputOnChange,
      existField,
      filteredSuggestions
    } = this.props;
    const { name, type } = this.state;

    return (
      <form onSubmit={e => e.preventDefault()}>
        <Grid container className={classes.marginLeft}>
          <Grid item xs={6}>
            <Downshift
              id="downshift-simple"
              itemToString={item => (item ? `${item.value}` : '')}
              onChange={onChange}
              onInputValueChange={this.onChangeField}
              onSelect={this.onSelectField}
            >
              {({
                getInputProps,
                getItemProps,
                getMenuProps,
                highlightedIndex,
                isOpen,
                selectedItem
              }) => (
                <div className={classes.container}>
                  {type ? <span className={classes.type}>{type}:</span> : null}
                  <TextField
                    fullWidth
                    InputProps={{
                      classes: {
                        root: classes.inputRoot,
                        input: classes.inputInput
                      },
                      ...getInputProps({
                        placeholder: t('mwAdmin.modals.advancedSearch'),
                        onChange: inputOnChange
                      })
                    }}
                  />
                  {name ? <span className={classes.name}>{name}</span> : null}
                  {isOpen ? (
                    <Paper {...getMenuProps()} className={classes.paper} square>
                      {filteredSuggestions.map((suggestion, index) => (
                        <Suggestion
                          key={suggestion.id}
                          suggestion={suggestion}
                          itemProps={getItemProps({ item: suggestion })}
                          isHighlightedIndex={highlightedIndex === index}
                          selectedItem={selectedItem}
                        />
                      ))}
                    </Paper>
                  ) : null}
                </div>
              )}
            </Downshift>
            {existField ? (
              <span className={classes.errorBlock}>
                {t('mwAdmin.modals.youChoseThisField')}
              </span>
            ) : null}
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default compose(
  withTranslation(),
  withStyles(styles),
  observer
)(CustomFieldsForm);
