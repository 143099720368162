/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { toJS } from 'mobx';
import classNames from 'classnames';
import { compose } from '@Utils';
import { withTranslation } from 'react-i18next';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

// Components
import UsersTable from '../ParticipantsTable';

// Styles
import styles from './styles';

export class GroupViewPanel extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    GroupStore: PropTypes.object,
    CommonStore: PropTypes.shape({
      setModalOptions: PropTypes.func
    }).isRequired,
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.props.GroupStore.getGroupInfo(this.getGroupId());
  }

  getGroupId() {
    return this.props.match.params.group;
  }

  handleGroupDelete = () => {
    const {
      CommonStore: { setModalOptions },
      t
    } = this.props;

    setModalOptions({
      modalName: 'ModalDialog',
      modalProps: {
        handleConfirmAction: this.handleConfirmDelete,
        title: t('modals.deleteGroup')
      }
    });
  };

  redirectToGroup = () => this.props.history.push('/groups');

  handleConfirmDelete = () => {
    const {
      CommonStore: { setModalOptions },
      GroupStore: { deleteGroup, setGroupId }
    } = this.props;

    setGroupId(this.getGroupId());
    return deleteGroup()
      .then(this.redirectToGroup)
      .catch(() => {
        setModalOptions({
          modalName: 'ModalDialog',
          modalProps: {
            title:
              'Pay attention, Wallet group you want to remove has unused care' +
              ' coins in its account. All Wallet group assets will be locked' +
              ' without possibility to restore. Are you sure you want to proceed?',
            handleConfirmAction: this.handleForceConfirmDelete
          }
        });
      });
  };

  handleForceConfirmDelete = () => {
    const {
      GroupStore: { forceGroupDelete }
    } = this.props;

    forceGroupDelete().then(this.redirectToGroup);
  };

  getFormattedGroupType = obj => {
    const types = [];

    if ('ACO' in obj) {
      types.push('ACO');
    }
    if ('NPI' in obj) {
      types.push('NPI');
    } else if ('TIN' in obj) {
      types.push('TIN');
    } else {
      // do not add anything to types
    }
    return types.join(', ');
  };

  renderIdField = customFields => {
    return 'NPI' in customFields
      ? customFields.NPI.value
      : customFields.TIN.value;
  };

  render() {
    const { classes, GroupStore, t } = this.props;
    const groupInfo = toJS(GroupStore.groupInfo);

    return (
      <Grid container spacing={3} className={classes.padding}>
        <Grid item sm={12}>
          <Grid item xs={3}>
            <Paper className={classes.profileField} elevation={0}>
              <Typography className={classes.label} variant="caption">
                {t('common.name')}
              </Typography>
              <Typography
                className={classNames(
                  classes.borderBottom,
                  classes.profileFieldText
                )}
                variant="body1"
              >
                {groupInfo.name || 'Group name'}
              </Typography>
            </Paper>
            <Paper className={classes.profileField} elevation={0}>
              <Typography className={classes.label} variant="caption">
                ID
              </Typography>
              <Typography
                className={classNames(
                  classes.borderBottom,
                  classes.profileFieldText
                )}
                variant="body1"
              >
                {groupInfo.custom
                  ? this.renderIdField(groupInfo.custom)
                  : 'Group id'}
              </Typography>
            </Paper>
            <Paper className={classes.profileField} elevation={0}>
              <Typography className={classes.label} variant="caption">
                {t('common.type')}
              </Typography>
              <Typography
                className={classNames(
                  classes.borderBottom,
                  classes.profileFieldText
                )}
                variant="body1"
                noWrap
              >
                {groupInfo.custom
                  ? this.getFormattedGroupType(groupInfo.custom)
                  : 'Group types'}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
        <Grid item sm={12} md={12} lg={12}>
          <div className={classNames(classes.alignRight, classes.padding)}>
            <Button
              id="remove-group"
              variant="outlined"
              color="default"
              onClick={this.handleGroupDelete}
            >
              {t('common.remove')}
            </Button>
          </div>
        </Grid>

        <UsersTable groupId={this.getGroupId()} />
      </Grid>
    );
  }
}

export default compose(
  withTranslation(),
  withStyles(styles),
  withRouter,
  inject('GroupStore', 'CommonStore'),
  observer
)(GroupViewPanel);
