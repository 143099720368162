/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

// Components
import { MainContentWrapper } from '@CommonScene';
import TableWrapper from '@RolesScene/scenes/RolesList/components/TableWrapper';

// Utils
import { compose } from '@Utils';

export class RolesList extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    RoleStore: PropTypes.object.isRequired,
    DeckStore: PropTypes.object.isRequired
  };

  componentDidMount() {
    const {
      DeckStore: { getDecks },
      RoleStore: { getRoles }
    } = this.props;

    getDecks().then(getRoles);
  }

  componentWillUnmount() {
    this.props.RoleStore.reset();
  }

  setDefaultQuery = () => {
    const {
      RoleStore: { clearQuery, getRoles }
    } = this.props;

    clearQuery();
    getRoles();
  };

  getHeaderOptions() {
    const { t } = this.props;

    return {
      title: t('mwAdmin.sidebar.roles'),
      addButtonTitle: t('mwAdmin.roles.addRole'),
      addButtonLink: '/roles/create',
      placeholder: t('mwAdmin.roles.searchByRole'),
      isList: true
    };
  }

  render() {
    const { setQuery, query } = this.props.RoleStore;

    return (
      <MainContentWrapper
        headerOptions={this.getHeaderOptions()}
        setQuery={setQuery}
        queryValue={query}
        setDefaultQuery={this.setDefaultQuery}
      >
        <TableWrapper />
      </MainContentWrapper>
    );
  }
}

export default compose(
  withTranslation(),
  inject('RoleStore', 'DeckStore'),
  observer
)(RolesList);
