/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import { action, observable, computed, toJS } from 'mobx';

// Stores
import AuthStore from '@Stores/AuthStore';
import CommonStore from '@Stores/CommonStore';
import BasePaymentsStore from '@Stores/BasePaymentsStore';

// Provider
import { PaymentsProvider, NpsProvider } from '@Providers';

// Utils
import { getParsedCookies } from '@Utils/formatting';
import { debounce } from 'lodash';
import { DELAY_REQUEST } from '@Utils/constans';

class ServicePaymentsStore extends BasePaymentsStore {
  DATE_RANGE = 'ServiceDateRange';

  @observable aggregatedServiceSummary = {};

  @observable aggregatedServiceTransactions = [];

  @observable aggregatedServiceOptions = {};

  @observable serviceSummary = {};

  @observable serviceTransactions = [];

  @observable serviceOptions = {};

  @observable serviceWalletId = getParsedCookies().service;

  @observable permissionNames = getParsedCookies().permissionNames;

  @observable nodes = [];

  @observable query = '';

  @observable orderBy = 'created_date';

  @action('ServicePaymentsStore => setPermissionNames')
  setPermissionNames = permissionNames => {
    this.permissionNames = permissionNames;
  };

  @action('ServicePaymentsStore => clearPermissionNames')
  clearPermissionNames = () => {
    this.permissionNames = [];
  };

  @action('ServicePaymentsStore => getPaymentAdminServiceName')
  getPaymentAdminServiceName = () => {
    return NpsProvider.getPaymentAdminType({
      careWalletId: AuthStore.careWalletId
    }).then(data => {
      CommonStore.setDynamicMenuItems({
        id: data.nodes[0].role_sub_type,
        name: data.nodes[0].description
      });
    });
  };

  @action('ServicePaymentsStore => setNodes')
  setNodes = nodes => {
    this.nodes = [...this.nodes, ...nodes];
  };

  @action('ServicePaymentsStore => clearNodes')
  clearNodes = () => {
    this.nodes = [];
  };

  @action('ServicePaymentsStore => getPermissionNodes')
  getPermissionNodes = () => {
    if (this.permissionNames) {
      this.clearNodes();
      this.permissionNames.forEach(nameId => {
        NpsProvider.getPaymentAdminType({
          careWalletId: nameId
        }).then(data => {
          const nodes = data.nodes;
          nodes.forEach(node => {
            const currentNode = node;
            currentNode.cw_id = nameId;
          });
          this.setNodes(data.nodes);
        });
        return [];
      });
    }
  };

  @action('ServicePaymentsStore => setServiceWalletId')
  setServiceWalletId = id => {
    this.serviceWalletId = id;
    document.cookie = `service=${id}; path=/`;
  };

  @action('ServicePaymentsStore => clearServiceWalletId')
  clearServiceWalletId = () => {
    this.serviceWalletId = null;
    document.cookie = 'service=; path=/';
  };

  @action('ServicePaymentsStore => getAggregatedServiceSummary')
  getAggregatedServiceSummary = data => {
    CommonStore.setPending();
    return PaymentsProvider.getTotalAggregatedServiceProviderTransactions({
      ...this.aggregatedSearchCriteria,
      ...data
    })
      .then(this.setAggregatedServiceSummary)
      .catch(this._handleErrors)
      .finally(CommonStore.clearPending);
  };

  @action('ServicePaymentsStore => setAggregatedServiceSummary')
  setAggregatedServiceSummary = summary => {
    if (!summary) {
      return;
    }

    this.aggregatedServiceSummary = summary;
  };

  @action('ServicePaymentsStore => clearAggregatedServiceSummary')
  clearAggregatedServiceSummary = () => {
    this.aggregatedServiceSummary = {};
  };

  @action('ServicePaymentsStore => getAggregatedServiceTransactions')
  getAggregatedServiceTransactions = data => {
    CommonStore.setPending();
    return PaymentsProvider.getAggregatedServiceProviderTransactions({
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      orderBy: this.orderBy,
      order: this.order,
      ...this.aggregatedSearchCriteria,
      ...data
    })
      .then(this.setAggregatedServiceTransactions)
      .catch(this._handleErrors)
      .finally(CommonStore.clearPending);
  };

  @action('ServicePaymentsStore => setAggregatedServiceTransactions')
  setAggregatedServiceTransactions = ({ content, ...options }) => {
    this.aggregatedServiceTransactions.push(...content);
    this.aggregatedServiceOptions = options;
  };

  @action('ServicePaymentsStore => clearAggregatedServiceTransactions')
  clearAggregatedServiceTransactions = () => {
    this.aggregatedServiceTransactions = [];
    this.aggregatedServiceOptions = {};
  };

  @computed get servicesList() {
    return this.aggregatedServiceTransactions.map(transaction => ({
      name: transaction.owner,
      id: transaction.owner_id
    }));
  }

  sortAggregatedData = orderBy => {
    this.clearAggregatedServiceTransactions();
    this.setOrder(orderBy);
    this.getAggregatedServiceTransactions();
  };

  resetAggregatedData = () => {
    this.clearPaging();
    this.clearAggregatedSearchCriteria();
    this.clearAggregatedServiceSummary();
    this.clearAggregatedServiceTransactions();
  };

  @action('ServicePaymentsStore => getServiceSummary')
  getServiceSummary = data => {
    CommonStore.setPending();
    return PaymentsProvider.getTotalServiceProviderTransactions({
      ...this.searchCriteria,
      ...this.dateRange,
      ...data
    })
      .then(this.setServiceSummary)
      .catch(this._handleErrors)
      .finally(CommonStore.clearPending);
  };

  @action('ServicePaymentsStore => setServiceSummary')
  setServiceSummary = summary => {
    if (!summary) {
      return;
    }

    this.serviceSummary = summary;
  };

  @action('ServicePaymentsStore => clearServiceSummary')
  clearServiceSummary = () => {
    this.serviceSummary = {};
  };

  @action('ServicePaymentsStore => getServiceTransactions')
  getServiceTransactions = data => {
    CommonStore.setPending();
    return PaymentsProvider.getServiceProviderTransactions({
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      orderBy: this.orderBy,
      order: this.order,
      ...this.searchCriteria,
      ...this.dateRange,
      ...data
    })
      .then(this.setServiceTransactions)
      .catch(this._handleErrors)
      .finally(CommonStore.clearPending);
  };

  @action('ServicePaymentsStore => setServiceTransactions')
  setServiceTransactions = ({ content, ...options }) => {
    this.serviceTransactions = [...this.serviceTransactions, ...content];
    this.serviceOptions = options;
  };

  @action('ServicePaymentsStore => clearServiceTransactions')
  clearServiceTransactions = () => {
    this.serviceTransactions = [];
    this.serviceOptions = {};
  };

  resetData = () => {
    this.clearSearchCriteria();
    this.clearServiceSummary();
    this.clearServiceTransactions();
    this.clearServiceWalletId();
    this.clearPermissionNames();
    this.clearPaging();
  };

  sortData = orderBy => {
    this.clearServiceTransactions();
    this.setOrder(orderBy);
    this.getServiceTransactions({
      serviceProviderCareWalletId: this.serviceWalletId
    });
  };

  reset = () => {
    this.resetAggregatedData();
    this.resetData();
  };

  @action('ServicePaymentsStore => filterByStatus') filterByStatus = () => {
    const data = {
      serviceProviderCareWalletId: this.serviceWalletId
    };
    const statuses = toJS(this.selectedStatuses);

    if (statuses.length) {
      this.updateSearchCriteria({ statuses });
    } else {
      delete this.searchCriteria.statuses;
    }

    this.clearPaging();
    this.clearServiceSummary();
    this.clearServiceTransactions();
    this.getServiceSummary(data);
    this.getServiceTransactions(data);
  };

  @action('ServicePaymentsStore => setQuery') setQuery = query => {
    this.query = query;
    this._searchWithDebounce();
  };

  @action('ServicePaymentsStore => clearQuery') clearQuery = () => {
    this.query = '';
  };

  @action('ServicePaymentsStore => _searchQuery') _searchQuery = () => {
    this.clearPaging();
    this.getServiceSummary({
      serviceProviderCareWalletId: this.serviceWalletId,
      sender: this.query
    });
    this.getServiceTransactions({
      serviceProviderCareWalletId: this.serviceWalletId,
      sender: this.query
    });
  };

  _searchWithDebounce = debounce(this._searchQuery, DELAY_REQUEST);
}

export default new ServicePaymentsStore();
