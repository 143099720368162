/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

// Components
import { MainContentWrapper, DataTable } from '@CommonScene';

// Configs
import { tableConfig } from '@Assets/config/tables/networks';

// Utils
import { compose } from '@Utils';

export class NetworkList extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    NetworkStore: PropTypes.shape({
      getUsersAccounts: PropTypes.func,
      setQuery: PropTypes.func,
      clearQuery: PropTypes.func,
      searchQuery: PropTypes.string,
      reset: PropTypes.func,
      resetPage: PropTypes.func,
      handleChangePage: PropTypes.func,
      page: PropTypes.number,
      lastPage: PropTypes.bool,
      isUserStatusTouched: PropTypes.bool,
      filteredUsers: PropTypes.array
    }).isRequired
  };

  componentDidMount() {
    this.props.NetworkStore.getUsersAccounts();
  }

  componentWillUnmount() {
    this.props.NetworkStore.reset();
  }

  fetchTableData = () => {
    const { page, lastPage, handleChangePage } = this.props.NetworkStore;

    if (!lastPage) {
      handleChangePage(page + 1);
    }
  };

  setDefaultStatuses = () => {
    const {
      NetworkStore: {
        resetPage,
        clearQuery,
        getUsersAccounts,
        isUserStatusTouched
      }
    } = this.props;

    if (!isUserStatusTouched) {
      resetPage();
    }
    clearQuery();
    getUsersAccounts();
  };

  getHeaderOptions() {
    const { t } = this.props;

    return {
      title: t('mwAdmin.sidebar.networkParticipants'),
      addButtonTitle: t('mwAdmin.invitations.createInvite'),
      addButtonLink: '/invitations/create',
      placeholder: t('mwAdmin.networkParticipants.searchByNamePhoneWallet'),
      isList: true
    };
  }

  render() {
    const {
      NetworkStore: { lastPage, filteredUsers, setQuery, searchQuery }
    } = this.props;

    return (
      <MainContentWrapper
        headerOptions={this.getHeaderOptions()}
        setDefaultQuery={this.setDefaultStatuses}
        setQuery={setQuery}
        queryValue={searchQuery}
      >
        <DataTable
          tableConfig={tableConfig}
          tableData={filteredUsers}
          fetchTableData={this.fetchTableData}
          hasMoreData={!lastPage}
        />
      </MainContentWrapper>
    );
  }
}

export default compose(
  withTranslation(),
  inject('NetworkStore'),
  observer
)(NetworkList);
