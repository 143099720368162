/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  offset: {
    marginTop: '20vh',
    maxWidth: '100%'
  },
  container: {
    minWidth: 400
  },
  containerWithImage: {
    minWidth: 430,
    height: 430
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'center'
  },
  card: {
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },
  media: {
    width: '100%',
    height: '100%'
  },
  form: {
    padding: theme.spacing(2)
  },
  headerBottomBorder: {
    width: 40,
    height: 4,
    marginBottom: theme.spacing(2),
    background: theme.palette.primary.main
  },
  headerTitle: {
    fontWeight: 500,
    letterSpacing: 3
  },
  margin: {
    margin: theme.spacing(1)
  },
  button: {
    textTransform: 'upperCase',
    marginTop: theme.spacing(2)
  },
  forgotButton: {
    padding: 0,
    marginTop: theme.spacing(2),
    fontSize: theme.spacing(1.4),
    opacity: 0.8,
    '&:hover': {
      opacity: 1,
      background: 'none'
    }
  }
});

export default styles;
