/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import { action, observable } from 'mobx';

// Stores
import ValidationStore from '@Stores/ValidationStore';

export default class BasePaymentsStore extends ValidationStore {
  @observable errors = null;

  @observable pageNumber = 0;

  @observable pageSize = 25;

  @observable aggregatedSearchCriteria = {};

  @observable searchCriteria = {};

  @observable orderBy = '';

  @observable order = 'desc';

  selectedStatuses = observable.array([]);

  @observable dateRange =
    JSON.parse(window.localStorage.getItem(this.DATE_RANGE)) || {};

  @action('BasePaymentsStore => setOrderBy') setOrderBy = orderBy => {
    this.orderBy = orderBy;
  };

  @action('BasePaymentsStore => setOrder') setOrder = ({ orderBy }) => {
    if (this.orderBy !== orderBy) {
      // set default sorting if new column is clicked
      this.order = 'desc';
    } else if (this.order === 'asc') {
      this.order = 'desc';
    } else {
      this.order = 'asc';
    }

    this.clearPaging();
    this.setOrderBy(orderBy);
  };

  @action('BasePaymentsStore => setPage') setPage = (
    pageNumber = this.pageNumber + 1
  ) => {
    this.pageNumber = pageNumber;
  };

  @action('BasePaymentsStore => clearPaging') clearPaging = () => {
    this.pageNumber = 0;
    this.pageSize = 25;
  };

  @action('BasePaymentsStore => updateAggregatedSearchCriteria')
  updateAggregatedSearchCriteria = searchCriteria => {
    this.aggregatedSearchCriteria = {
      ...this.aggregatedSearchCriteria,
      ...searchCriteria
    };
  };

  @action('BasePaymentsStore => clearAggregatedSearchCriteria')
  clearAggregatedSearchCriteria = () => {
    this.aggregatedSearchCriteria = {};
  };

  @action('BasePaymentsStore => updateSearchCriteria')
  updateSearchCriteria = searchCriteria => {
    this.searchCriteria = {
      ...this.searchCriteria,
      ...searchCriteria
    };
  };

  @action('BasePaymentsStore => clearSearchCriteria')
  clearSearchCriteria = () => {
    this.searchCriteria = {};
  };

  @action('BasePaymentsStore => _handleErrors') _handleErrors = err => {
    this.errors =
      err && err.response && err.response.body && err.response.body.errors;
  };

  @action('BasePaymentsStore => handleStatusSelect')
  handleStatusSelect = statusId => {
    const selectedStatuses = [...this.selectedStatuses];
    const matchId = selectedStatuses.indexOf(statusId);

    if (matchId >= 0) {
      selectedStatuses.splice(matchId, 1);
      this.selectedStatuses.replace(selectedStatuses);
    } else {
      selectedStatuses.push(statusId);
      this.selectedStatuses.replace(selectedStatuses);
    }
  };

  @action('BasePaymentsStore => setDateRange')
  setDateRange = date => {
    this.dateRange = { ...this.dateRange, ...date };
    this.setDateRangeToLocalStorage();
  };

  @action('BasePaymentsStore => clearDateRange')
  clearDateRange = () => {
    this.dateRange = {};
    this.setDateRangeToLocalStorage();
  };

  setDateRangeToLocalStorage = () => {
    window.localStorage.setItem(
      this.DATE_RANGE,
      JSON.stringify(this.dateRange)
    );
  };
}
