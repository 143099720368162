/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

// Material UI
import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/MoreVert';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { withStyles } from '@material-ui/core/styles';

// Utils
import { compose } from '@Utils';

// Styles
import styles from './styles';

const LoggedInPanel = ({
  classes,
  CommonStore: { currentUserInfo },
  isMenuOpen,
  handleProfileMenuOpen
}) => {
  const { t } = useTranslation();
  const renderUserName = () => {
    if (currentUserInfo && currentUserInfo.first_name) {
      return (
        <Fragment>
          ,{' '}
          <span className={classes.bold}>
            {currentUserInfo.first_name} {currentUserInfo.last_name || ''}
          </span>
        </Fragment>
      );
    }
    return null;
  };
  return (
    <div>
      <span id="welcome-text" className={classes.welcomeText}>
        {t('common.welcome')}
        {renderUserName()}
      </span>
      <IconButton
        aria-owns={isMenuOpen ? 'material-appbar' : undefined}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
        id="acccount-avatar"
      >
        <AccountCircle className={classes.sectionDesktop} />
        <MoreIcon className={classes.sectionMobile} />
      </IconButton>
    </div>
  );
};

LoggedInPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  CommonStore: PropTypes.object.isRequired,
  handleProfileMenuOpen: PropTypes.func.isRequired,
  isMenuOpen: PropTypes.bool
};

LoggedInPanel.defaltProps = {
  isMenuOpen: false
};

export default compose(
  withStyles(styles),
  inject('CommonStore'),
  observer
)(LoggedInPanel);
