/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

// Material-UI
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Dehaze from '@material-ui/icons/Dehaze';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

// Styles
import styles from './styles';

// Utils
import { compose } from '@Utils';

export class CustomFieldActionsPanel extends React.Component {
  state = {
    anchorEl: null
  };

  static propTypes = {
    t: PropTypes.func.isRequired,
    InvitationStore: PropTypes.object,
    customFieldId: PropTypes.number,
    handleEditField: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
  };

  handleOpenMenu = event => this.setState({ anchorEl: event.currentTarget });

  handleClose = () => this.setState({ anchorEl: null });

  handleFieldEdit = () => {
    const { handleEditField, customFieldId } = this.props;

    handleEditField(customFieldId);
    this.handleClose();
  };

  handleFieldRemove = () => {
    const {
      InvitationStore: { handleFieldRemove },
      customFieldId
    } = this.props;

    handleFieldRemove(customFieldId);
  };

  render() {
    const { classes, t } = this.props;
    const { anchorEl } = this.state;

    return (
      <React.Fragment>
        <IconButton
          aria-owns={anchorEl ? 'menu-action' : undefined}
          aria-haspopup="true"
          onClick={this.handleOpenMenu}
        >
          <Dehaze />
        </IconButton>
        <Menu
          id="menu-action"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem className={classes.menuItem} onClick={this.handleFieldEdit}>
            <EditIcon />{' '}
            <span className={classes.actionTitle}>{t('common.edit')}</span>
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={this.handleFieldRemove}
          >
            <DeleteIcon />{' '}
            <span className={classes.actionTitle}>{t('common.delete')}</span>
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  }
}

export default compose(
  withTranslation(),
  withStyles(styles),
  inject('InvitationStore'),
  observer
)(CustomFieldActionsPanel);
