/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import { action, observable, computed, toJS } from 'mobx';

// Stores
import CommonStore from '@Stores/CommonStore';
import BasePaymentsStore from '@Stores/BasePaymentsStore';

// Provider
import { PaymentsProvider } from '@Providers';

// Utils
import { getParsedCookies, hideFieldsInfo } from '@Utils/formatting';

class MembersPaymentsStore extends BasePaymentsStore {
  DATE_RANGE = 'MembersDateRange';

  @observable aggregatedMemberSummary = {};

  @observable aggregatedMemberTransactions = [];

  @observable aggregatedMemberTransactionsOptions = {};

  @observable memberCareWalletId = getParsedCookies().member;

  @observable memberSummary = {};

  @observable memberTransactions = [];

  @observable memberOptions = {};

  @observable memberTransactionsOptions = {};

  @action('MembersPaymentsStore => updateMemberId') updateMemberId = id => {
    this.memberCareWalletId = id;
    document.cookie = `member=${this.memberCareWalletId}; path=/`;
  };

  @action('MembersPaymentsStore => clearMemberId') clearMemberId = () => {
    this.memberCareWalletId = null;
    document.cookie = 'member=; path=/';
  };

  @action('MembersPaymentsStore => getAggregatedMemberSummary')
  getAggregatedMemberSummary = data => {
    CommonStore.setPending();
    return PaymentsProvider.getTotalAggregatedMemberTransactions({
      ...this.aggregatedSearchCriteria,
      ...data
    })
      .then(this.setAggregatedMemberSummary)
      .catch(this._handleErrors)
      .finally(CommonStore.clearPending);
  };

  @action('MembersPaymentsStore => setAggregatedMemberSummary')
  setAggregatedMemberSummary = summary => {
    if (!summary) {
      return;
    }

    this.aggregatedMemberSummary = summary;
  };

  @action('MembersPaymentsStore => clearAggregatedMemberSummary')
  clearAggregatedMemberSummary = () => {
    this.aggregatedMemberSummary = {};
  };

  @action('MembersPaymentsStore => getAggregatedMemberTransactions')
  getAggregatedMemberTransactions = data => {
    CommonStore.setPending();
    return PaymentsProvider.getAggregatedMemberTransactions({
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      order: this.order,
      orderBy: this.orderBy,
      ...this.aggregatedSearchCriteria,
      ...data
    })
      .then(this.setAggregatedMemberTransactions)
      .catch(this._handleErrors)
      .finally(CommonStore.clearPending);
  };

  @action('MembersPaymentsStore => setAggregatedMemberTransactions')
  setAggregatedMemberTransactions = ({ content, ...options }) => {
    this.aggregatedMemberTransactions.push(...content);
    this.aggregatedMemberTransactionsOptions = options;
  };

  @action('MembersPaymentsStore => clearAggregatedMemberTransactions')
  clearAggregatedMemberTransactions = () => {
    this.aggregatedMemberTransactions = [];
    this.aggregatedMemberTransactionsOptions = {};
  };

  @computed get membersId() {
    return this.aggregatedMemberTransactions.map(transaction => ({
      name: hideFieldsInfo(transaction.owner),
      id: transaction.owner
    }));
  }

  sortAggregatedData = orderBy => {
    this.clearAggregatedMemberTransactions();
    this.setOrder(orderBy);
    this.getAggregatedMemberTransactions();
  };

  @action('MembersPaymentsStore => resetAggregatedData')
  resetAggregatedData = () => {
    this.clearPaging();
    this.clearAggregatedMemberSummary();
    this.clearAggregatedMemberTransactions();
    this.clearAggregatedSearchCriteria();
  };

  @action('MembersPaymentsStore => getMemberSummary')
  getMemberSummary = data => {
    CommonStore.setPending();
    return PaymentsProvider.getTotalMemberTransactions({
      ...this.searchCriteria,
      ...this.dateRange,
      ...data
    })
      .then(this.setMemberSummary)
      .catch(this._handleErrors)
      .finally(CommonStore.clearPending);
  };

  @action('MembersPaymentsStore => setMemberSummary')
  setMemberSummary = summary => {
    if (!summary) {
      return;
    }

    this.memberSummary = summary;
  };

  @action('MembersPaymentsStore => clearMemberSummary')
  clearMemberSummary = () => {
    this.memberSummary = {};
  };

  @action('MembersPaymentsStore => getMemberTransactions')
  getMemberTransactions = data => {
    CommonStore.setPending();
    return PaymentsProvider.getMemberTransactions({
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      order: this.order,
      orderBy: this.orderBy,
      ...this.searchCriteria,
      ...this.dateRange,
      ...data
    })
      .then(this.setMemberTransactions)
      .catch(this._handleErrors)
      .finally(CommonStore.clearPending);
  };

  @action('MembersPaymentsStore => setMemberTransactions')
  setMemberTransactions = ({ content, ...options }) => {
    this.memberTransactions.push(...content);
    this.memberOptions = options;
  };

  @action('MembersPaymentsStore => clearMemberTransactions')
  clearMemberTransactions = () => {
    this.memberTransactions = [];
  };

  sortData = orderBy => {
    this.clearMemberTransactions();
    this.setOrder(orderBy);
    this.getMemberTransactions({
      memberCareWalletId: this.memberCareWalletId
    });
  };

  @action('MembersPaymentsStore => resetMembersData') resetMembersData = () => {
    this.clearPaging();
    this.clearSearchCriteria();
    this.clearMemberSummary();
    this.clearMemberId();
    this.clearMemberTransactions();
  };

  @action('MembersPaymentsStore => reset') reset = () => {
    this.resetAggregatedData();
    this.resetMembersData();
  };

  @action('MembersPaymentsStore => filterByStatus') filterByStatus = () => {
    const data = {
      memberCareWalletId: this.memberCareWalletId
    };
    const statuses = toJS(this.selectedStatuses);

    if (statuses.length) {
      this.updateSearchCriteria({ statuses });
    } else {
      delete this.searchCriteria.statuses;
    }

    this.clearPaging();
    this.clearMemberSummary();
    this.clearMemberTransactions();
    this.getMemberSummary(data);
    this.getMemberTransactions(data);
  };
}

export default new MembersPaymentsStore();
