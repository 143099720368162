/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import { observable, action } from 'mobx';
import debounce from 'lodash/debounce';
import i18n from 'i18next';

// Providers
import { GroupProvider, RoleProvider } from '@Providers';

// Stores
import CommonStore from '@Stores/CommonStore';

// constants
import { DELAY_REQUEST } from '@Utils/constans';

class GroupStore {
  groups = observable.array([], { deep: false });

  @observable query = '';

  @observable groupInfo = {};

  @observable order = 'asc';

  @observable orderBy = 'name';

  @observable page = 0;

  @observable rowsPerPage = 25;

  @observable totalItems = 0;

  @observable lastPage = false;

  deleteGroupId = null;

  searchFilter = observable.array([]);

  @action('GroupStore => _handleServerError') _handleServerError = err => {
    this.errors =
      err && err.response && err.response.body && err.response.body.errors;
    throw err;
  };

  @action('GroupStore => handleRoleSelect') handleRoleSelect = typeId => {
    const selectedGroups = [...this.searchFilter];

    const matchId = selectedGroups.indexOf(typeId);
    if (matchId >= 0) {
      selectedGroups.splice(matchId, 1);
      this.searchFilter.replace(selectedGroups);
    } else {
      selectedGroups.push(typeId);
      this.searchFilter.replace(selectedGroups);
    }
  };

  @action('GroupStore => getGroups') getGroups = (
    pageNumber = this.page,
    pageSize = this.rowsPerPage
  ) => {
    CommonStore.setPending();
    let queryS = {
      page: pageNumber,
      size: pageSize,
      sort: `${this.orderBy},${this.order}`
    };

    if (this.query) {
      queryS = { ...queryS, searchStr: this.query };
    }
    if (this.searchFilter.length) {
      const isTinSelected = this.searchFilter.includes('TIN');
      const isNpiSelected = this.searchFilter.includes('NPI');

      if (isTinSelected && !isNpiSelected) {
        queryS = { ...queryS, custom: 'TIN', no_custom: 'NPI' };
      } else if (!isTinSelected && isNpiSelected) {
        queryS = { ...queryS, custom: 'NPI' };
      } else {
        queryS = { ...queryS, custom: 'TIN' };
      }
    }

    return GroupProvider.getGroups(queryS)
      .then(this.getGroupsHandler)
      .catch(this._handleServerError)
      .finally(CommonStore.clearPending);
  };

  @action('GroupStore => getGroupsHandler') getGroupsHandler = response => {
    this.setGroups(response.content, response.total_elements);
    this.totalItems = response.total_elements;
    this.lastPage = response.last;
  };

  @action('GroupStore => setQuery') setQuery = query => {
    this.resetPage();
    this.searchFilter.replace([]);
    this.query = query;
    this.searchWithDebounce();
  };

  @action('GroupStore => clearQuery') clearQuery = () => {
    this.query = '';
    this.searchFilter.replace([]);
  };

  @action('GroupStore => clearQuery') _resetOrder() {
    this.order = 'asc';
  }

  @action('GroupStore => reset') reset() {
    this.resetPage();
    this.clearQuery();
    this._resetOrder();
  }

  @action('GroupStore => setOrder') setOrder = () => {
    this.page = 0;
    this.groups.replace([]);
    if (this.order === 'asc') {
      this.order = 'desc';
    } else {
      this.order = 'asc';
    }
    this.getGroups();
  };

  @action('GroupStore => updateGroups') updateGroups = () => {
    this.page = 0;
    this.groups.replace([]);
    this.getGroups();
  };

  searchWithDebounce = debounce(this.updateGroups, DELAY_REQUEST);

  @action('GroupStore => getGroupInfo') getGroupInfo = groupId => {
    CommonStore.setPending();
    return GroupProvider.getGroupInfo(groupId)
      .then(this.getGroupInfoHandler)
      .catch(this._handleServerError)
      .finally(CommonStore.clearPending);
  };

  @action('GroupStore => getGroupInfoHandler')
  getGroupInfoHandler = response => {
    this.setGroupInfo(response);
    return RoleProvider.getActiveRoles().then(this.setGroupRoles);
  };

  @action('GroupStore => fetchGroupBalance') fetchGroupBalance = groupId => {
    this.deleteGroupId = groupId;
    CommonStore.setModalOptions({
      modalName: 'ModalDialog',
      modalProps: {
        handleConfirmAction: this._deleteGroup,
        title: i18n.t('modals.deleteGroup')
      }
    });
  };

  _deleteGroup = () => {
    this.deleteGroup().catch(() => {
      CommonStore.setModalOptions({
        modalName: 'ModalDialog',
        modalProps: {
          title:
            'Pay attention, Wallet group you want to remove has unused care' +
            ' coins in its account. All Wallet group assets will be locked' +
            ' without possibility to restore. Are you sure you want to proceed?',
          handleConfirmAction: this.forceGroupDelete
        }
      });
    });
  };

  @action('GroupStore => setGroupId') setGroupId = groupId => {
    this.deleteGroupId = groupId;
  };

  @action('GroupStore => deleteGroup') deleteGroup = () => {
    CommonStore.setPending();
    return GroupProvider.removeGroup(this.deleteGroupId)
      .then(this._deleteGroupHandler)
      .catch(this._handleServerError)
      .finally(CommonStore.clearPending);
  };

  @action('GroupStore => deleteGroupHandler') _deleteGroupHandler = () => {
    this.groups.replace([]);
    this.resetPage();
    this.getGroups();
    this.deleteGroupId = null;
  };

  @action('GroupStore => forceGroupDelete') forceGroupDelete = () => {
    CommonStore.setPending();
    return GroupProvider.forceRemoveGroup(this.deleteGroupId)
      .then(this._deleteGroupHandler)
      .catch(this._handleServerError)
      .finally(CommonStore.clearPending);
  };

  @action('GroupStore => setGroups') setGroups = (groups, total) => {
    if (this.page === 0) {
      this.groups.replace([]);
    }

    if (this.groups.length === total) {
      return;
    }

    const newGroups = [...this.groups, ...groups];
    this.groups.replace(newGroups);
  };

  @action('GroupStore => setGroupRoles') setGroupRoles = roles => {
    let newGroupInfo = {};
    Object.keys(this.groupInfo.user_roles).forEach(key => {
      newGroupInfo[key] = this.groupInfo.user_roles[key].map(role =>
        roles.content.find(myRole => myRole.id === role)
      );
    });
    newGroupInfo = {
      ...this.groupInfo,
      user_roles: {
        ...newGroupInfo
      }
    };

    this.setGroupInfo(newGroupInfo);
  };

  @action('GroupStore => setGroupInfo') setGroupInfo(groupInfo) {
    this.groupInfo = groupInfo;
  }

  @action('GroupStore => handleChangePage') handleChangePage = page => {
    if (!this.lastPage) {
      this.getGroups(page);
      this.page = page;
    }
  };

  @action('GroupStore => resetPage') resetPage = () => {
    this.page = 0;
    this.rowsPerPage = 25;
  };
}

export default new GroupStore();
