/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// Utils
import { compose } from '@Utils';

// material ui
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';

// Components
import CustomFieldActionsPanel from '@InvitationsScene/scenes/AddInvitation/components/CustomFieldActionsPanel';

import styles from './styles';

const InviteToRoleTable = ({
  t,
  classes,
  fields,
  handleAddField,
  handleEditField,
  isNPI,
  value,
  isFieldsList
}) => {
  if (!(value !== '' && isFieldsList)) {
    return null;
  }

  const displayNpiData = (data, key) => (data.NPI ? data.NPI[key] : '');

  return (
    <React.Fragment>
      <Grid container className={classes.padding}>
        <Grid item xs={6}>
          <Typography variant="h5">{t('common.customFields')}</Typography>
        </Grid>
        <Grid item xs={6} align="right" className={classes.fieldsAction}>
          {fields.length === 0 ? (
            <span className={classes.infoMessage}>
              {t('mwAdmin.modals.addOneCustomField')}
            </span>
          ) : null}
          <Button id="add-btn" onClick={handleAddField}>
            {t('mwAdmin.modals.addField')}
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Table>
          <TableHead>
            <TableRow>
              {isNPI ? (
                <React.Fragment>
                  <TableCell>NPI ID</TableCell>
                  <TableCell>{`NPI ${t('common.name')}`}</TableCell>
                </React.Fragment>
              ) : null}
              <TableCell>TIN ID</TableCell>
              <TableCell>{`TIN ${t('common.name')}`}</TableCell>
              <TableCell>{t('common.actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((item, id) => (
              <TableRow key={item.id}>
                {isNPI ? (
                  <React.Fragment>
                    <TableCell>{displayNpiData(item, 'value')}</TableCell>
                    <TableCell>{displayNpiData(item, 'name')}</TableCell>
                  </React.Fragment>
                ) : null}
                <TableCell>{item.TIN.value}</TableCell>
                <TableCell>{item.TIN.name}</TableCell>
                <TableCell>
                  <CustomFieldActionsPanel
                    customFieldId={id}
                    handleEditField={handleEditField}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </React.Fragment>
  );
};

InviteToRoleTable.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  handleAddField: PropTypes.func.isRequired,
  handleEditField: PropTypes.func.isRequired,
  isNPI: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isFieldsList: PropTypes.bool.isRequired
};

export { InviteToRoleTable };

export default compose(
  withTranslation(),
  withStyles(styles),
  observer
)(InviteToRoleTable);
