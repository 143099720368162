/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import i18n from 'i18next';

// Utils
import {
  formatDate,
  addCommasInNumber,
  renderEtherscanLink
} from '@Utils/formatting';

// Stores
import DepositsStore from '@Stores/DepositsStore';
import { translation } from '@Utils/translation';

const LOADED_DATE = 'transaction_details.loaded_date';
const CREATED_DATE = 'transaction_details.created_date';
const INITIATOR_ADDRESS = 'initiator_address';
const FIAT_AMOUNT = 'transaction_details.fiat_amount';
const EXCHANGE_RATE = 'transaction_details.exchange_rate';
const SOLVE_AMOUNT = 'transaction_details.solve_amount';

const translateStatus = value => translation(value, 'statuses');

export const tableConfig = {
  defaultOrder: 'desc',
  defaultOrderBy: LOADED_DATE,
  rowIdKey: 'id',
  headings: [
    {
      id: 'orderDate',
      title: i18n.t('can.deposits.table.dateOrdered'),
      sortable: true,
      cb: () => DepositsStore.setOrder('order_date')
    },
    {
      id: LOADED_DATE,
      title: i18n.t('can.deposits.table.dateReceived'),
      sortable: true,
      cb: () => DepositsStore.setOrder(CREATED_DATE)
    },
    i18n.t('can.deposits.table.status'),
    {
      id: INITIATOR_ADDRESS,
      title: i18n.t('can.deposits.table.sender'),
      sortable: true,
      cb: () => DepositsStore.setOrder(INITIATOR_ADDRESS)
    },
    {
      id: FIAT_AMOUNT,
      title: i18n.t('can.deposits.table.usdAmount'),
      sortable: true,
      cb: () => DepositsStore.setOrder(FIAT_AMOUNT)
    },
    {
      id: 'solve_amount.exchange_rate',
      title: i18n.t('can.deposits.table.solveValue'),
      sortable: true,
      cb: () => DepositsStore.setOrder(EXCHANGE_RATE)
    },
    {
      id: SOLVE_AMOUNT,
      title: i18n.t('can.deposits.table.solveAmount'),
      sortable: true,
      cb: () => DepositsStore.setOrder(SOLVE_AMOUNT)
    }
  ],
  rowCells: [
    { key: 'orderDate', cb: formatDate },
    { key: CREATED_DATE, cb: formatDate },
    { key: 'transaction_details.status', cb: translateStatus },
    { key: INITIATOR_ADDRESS, cb: renderEtherscanLink },
    { key: FIAT_AMOUNT, cb: addCommasInNumber },
    EXCHANGE_RATE,
    { key: SOLVE_AMOUNT, cb: addCommasInNumber }
  ]
};
