/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import classNames from 'classnames';
import { observer, inject } from 'mobx-react';
import shortid from 'shortid';

// Material UI
import { List, ListItem, ListItemText, withStyles } from '@material-ui/core';

// Utils
import { compose } from '@Utils';

// Styles
import styles from './styles';

export class MenuLinks extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    CommonStore: PropTypes.object.isRequired,
    ServicePaymentsStore: PropTypes.object.isRequired,
    sections: PropTypes.array,
    currentPath: PropTypes.string.isRequired
  };

  // verifies if routeName is the one active (in browser input)
  isActiveRoute = path => this.props.currentPath.includes(path);

  handleSidebarRouteClick = (event, itemPath) => {
    if (itemPath === this.props.currentPath) {
      event.preventDefault();
    }
  };

  addClass = item => {
    const { classes } = this.props;

    return item.subitem ? classes.subitem : classes.listItem;
  };

  renderTitle = prop => {
    const {
      CommonStore: { dynamicMenuItems, headerId }
    } = this.props;

    return prop.dynamic ? dynamicMenuItems[headerId] : prop.title;
  };

  renderMenuItems = sections => {
    const {
      classes,
      CommonStore: { isACN, isBI }
    } = this.props;
    let menuSections = sections;

    if (!isACN) {
      menuSections = menuSections.filter(section => section.path !== '/groups');
    }

    if (isBI) {
      menuSections = menuSections.filter(
        section => !section.path.includes('members')
      );
    }

    return menuSections.map(prop =>
      prop.path ? (
        <Link
          key={shortid.generate()}
          className={classNames(
            classes.item,
            this.isActiveRoute(prop.path) && classes.itemActive,
            'menu-item'
          )}
          onClick={e => this.handleSidebarRouteClick(e, prop.path)}
          to={prop.path}
        >
          <ListItem
            button
            className={classNames(classes.linkBtn, this.addClass(prop))}
          >
            <ListItemText
              disableTypography
              primary={prop.title}
              className={classes.linkBtn}
            />
          </ListItem>
        </Link>
      ) : (
        <ListItem
          button
          className={classNames(classes.listItem, classes.menuItem)}
          key={shortid.generate()}
        >
          <ListItemText
            disableTypography
            className={classes.menuItem}
            primary={this.renderTitle(prop)}
          />
        </ListItem>
      )
    );
  };

  render() {
    const { classes, sections } = this.props;
    return (
      <List className={classes.list}>{this.renderMenuItems(sections)}</List>
    );
  }
}

export default compose(
  withStyles(styles),
  withRouter,
  inject('CommonStore', 'ServicePaymentsStore'),
  observer
)(MenuLinks);
