/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

// Utils
import { compose } from '@Utils';
import modalConfig from '../modalConfig';

export class BaseModal extends Component {
  static propTypes = {
    CommonStore: PropTypes.shape({
      modalName: PropTypes.string,
      modalProps: PropTypes.object,
      delModalOptions: PropTypes.func
    }).isRequired
  };

  handleCancelAction = () => {
    const {
      CommonStore: { delModalOptions, modalProps }
    } = this.props;

    if (modalProps && modalProps.handleCancelAction) {
      modalProps.handleCancelAction();
    }

    delModalOptions();
  };

  handleConfirmAction = () => {
    const {
      CommonStore: { modalProps }
    } = this.props;

    if (modalProps && modalProps.handleConfirmAction) {
      modalProps.handleConfirmAction();
    }
    this.handleCancelAction();
  };

  render() {
    const {
      CommonStore: { modalName, modalProps }
    } = this.props;

    if (!modalName) {
      return null;
    }

    const ModalComponent = modalConfig[modalName];

    return (
      <ModalComponent
        {...modalProps}
        isOpen={!!modalName}
        handleCancelAction={this.handleCancelAction}
        handleConfirmAction={this.handleConfirmAction}
      />
    );
  }
}

export default compose(
  inject('CommonStore'),
  observer
)(BaseModal);
