/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// Material UI
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';

// Components
import UserInfoField from '@NetworkScene/scenes/EditRole/components/UserInfoField';

// Utils
import { translation } from '@Utils/translation';

const RoleEditForm = ({
  classes,
  currentRole,
  selectValue,
  currentUser,
  t
}) => (
  <Grid className={classes.padding}>
    <Grid item xs={3}>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="selectedRole">{t('common.role')}</InputLabel>
        <Select
          disabled
          value={selectValue}
          inputProps={{ name: 'selectedRole' }}
        >
          <MenuItem value={selectValue}>
            {translation(currentRole.name, 'rolesNames')}
          </MenuItem>
        </Select>
      </FormControl>
    </Grid>
    <Grid item xs={3}>
      <UserInfoField
        label={t('common.phoneNumber')}
        name="phone_number"
        value={currentUser.phone_number}
        classes={classes}
      />
    </Grid>
    <Grid item xs={3}>
      <UserInfoField
        label={t('common.name')}
        name="first_name"
        value={currentUser.first_name}
        classes={classes}
      />
    </Grid>
    <Grid item xs={3}>
      <UserInfoField
        label={t('common.surname')}
        name="last_name"
        value={currentUser.last_name}
        classes={classes}
      />
    </Grid>
  </Grid>
);

RoleEditForm.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  currentRole: PropTypes.object.isRequired,
  selectValue: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired
};

export default withTranslation()(RoleEditForm);
