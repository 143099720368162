/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

// Material-UI imports
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DehazeIcon from '@material-ui/icons/Dehaze';

// Utils and helpers
import classNames from 'classnames';
import { rolesExclude } from '@Assets/config/configForExclude';
import { compose } from '@Utils';

// Component Style imports
import styles from './styles';

export class RolesMenu extends React.Component {
  state = {
    anchorEl: null
  };

  static propTypes = {
    t: PropTypes.func.isRequired,
    CommonStore: PropTypes.shape({
      setModalOptions: PropTypes.func
    }).isRequired,
    NetworkStore: PropTypes.shape({
      setUserId: PropTypes.func,
      setDeletedRoles: PropTypes.func,
      deleteUserFromRole: PropTypes.func,
      userId: PropTypes.string,
      currentUser: PropTypes.shape({
        roles: PropTypes.array
      })
    }),
    history: PropTypes.object,
    user: PropTypes.object.isRequired,
    roles: PropTypes.array.isRequired,
    rolesToDelete: PropTypes.array.isRequired,
    userName: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired
  };

  handleOpenMenu = event => this.setState({ anchorEl: event.currentTarget });

  handleDeleteUserFromRole = () => {
    const {
      user: { id },
      NetworkStore: { setUserId },
      CommonStore: { setModalOptions }
    } = this.props;

    setUserId(id);
    setModalOptions({
      modalName: 'ConfirmationAction',
      modalProps: { handleConfirmAction: this.handleDeleteRole }
    });
    this.handleClose();
  };

  handleDeleteRole = () => {
    const {
      rolesToDelete,
      NetworkStore: { userId, deleteUserFromRole, setDeletedRoles }
    } = this.props;

    rolesToDelete.map(role => deleteUserFromRole(role.id, userId));
    setDeletedRoles();
  };

  handleEditRole = () => {
    const { roles, user, history } = this.props;

    this.handleClose();
    history.push(`/networks/${user.id}/edit-role/${roles[0].id}`);
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  isEditRoleShown = () => {
    const { roles } = this.props;

    return roles.some(
      item =>
        item.group.external_id && !rolesExclude.includes(item.group.external_id)
    );
  };

  render() {
    const { classes, t } = this.props;
    const { anchorEl } = this.state;

    return (
      <React.Fragment>
        <IconButton
          aria-owns={anchorEl ? 'menu-role' : undefined}
          aria-haspopup="true"
          onClick={this.handleOpenMenu}
        >
          <DehazeIcon />
        </IconButton>
        <Menu
          id="menu-role"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {this.isEditRoleShown() ? (
            <MenuItem
              className={classes.menuItem}
              onClick={this.handleEditRole}
            >
              <EditIcon
                className={classNames(classes.rightSpace, classes.iconSize)}
              />
              {t('mwAdmin.networkParticipants.editRole')}
            </MenuItem>
          ) : null}
          <MenuItem
            className={classes.menuItem}
            onClick={this.handleDeleteUserFromRole}
          >
            <DeleteIcon
              className={classNames(classes.rightSpace, classes.iconSize)}
            />
            {t('common.remove')}
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  }
}

export default compose(
  withTranslation(),
  withStyles(styles),
  inject('NetworkStore', 'CommonStore'),
  withRouter,
  observer
)(RolesMenu);
