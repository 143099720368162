/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

import _ from 'lodash';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const filterSuggestions = arr => {
  return _.without(
    arr.map(item => {
      let obj = null;
      Object.keys(item.custom).forEach(key => {
        obj = {
          id: item.id,
          group: item,
          value: item.custom[key].value,
          label: `${item.custom[key].value} ${key} ${item.name}`
        };
      });
      return obj;
    }),
    null
  );
};

export const formFields = (data, provider) => {
  /*
    We should form new obj if provider string is 'PROVIDER_ORG'
    we need add just one property TIN, and TIN name should look into
    name value of the object. If provider will be PROVIDER_ORG name will
    apply to the NPI name.
  */
  const TINid = data.find(key => key.name === 'TIN').value;
  const TINname = data.find(
    key => key.name === (provider ? 'TIN_NAME' : 'name')
  ).value;

  const newCustomFields = {
    TIN: {
      id: TINid,
      name: TINname
    }
  };

  if (provider) {
    const NPIid = data.find(key => key.name === 'NPI').value;
    const NPIname = data.find(key => key.name === 'name').value;

    newCustomFields.NPI = {
      id: NPIid,
      name: NPIname
    };
  }

  return newCustomFields;
};

export const filterFieldsByProvider = data => {
  const customField = data.find(key => key.name === 'GROUP_TYPE');
  return customField.value === 'PROVIDER';
};

export const formatNewCustomFields = (field, groupId, roleId) => {
  const isNPIandTIN = filterFieldsByProvider(field);
  return {
    roleId,
    groupId,
    ...formFields(field, isNPIandTIN)
  };
};

export const filteredRoles = (currentRole, userRoles) => {
  if (!currentRole) {
    return false;
  }
  const currentRoleTemplateId = currentRole.role_template_id;
  return userRoles.reduce((acc, item) => {
    if (item.role_template_id === currentRoleTemplateId) {
      const allCustomFields = item.group.custom;
      const customFieldGroupId = item.group.id;
      const roleId = item.id;
      const newCustomField = formatNewCustomFields(
        allCustomFields,
        customFieldGroupId,
        roleId
      );

      acc.push(newCustomField);
    }
    return acc;
  }, []);
};

export default {
  reorder
};
