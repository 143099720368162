/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import shortid from 'shortid';

// Material UI
import { Typography, Grid, withStyles } from '@material-ui/core';

// Components
import { MenuLinks } from '@CommonScene';

// Utils
import { compose } from '@Utils';
import { getParsedCookies } from '@Utils/formatting';
import {
  menuLinksConfig,
  MwAdminConfigForAcn
} from '@Utils/constans/privateRoutes';

// Styles
import styles from './styles';

export class Sidebar extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    currentPath: PropTypes.string.isRequired,
    CommonStore: PropTypes.shape({
      isACN: PropTypes.bool
    }).isRequired
  };

  getMenuLinks = () => {
    const {
      CommonStore: { isACN }
    } = this.props;
    const menuLinks = [];

    if (isACN) {
      menuLinks.push(...MwAdminConfigForAcn);
    } else {
      const roles = getParsedCookies().roles;

      if (roles.length) {
        roles.forEach(role => {
          if (menuLinksConfig[role]) {
            menuLinks.push(...menuLinksConfig[role]);
          }
        });
      }
    }

    return menuLinks;
  };

  render() {
    const { classes, currentPath } = this.props;

    return (
      <Grid item xs={3} md={2} className={classes.sidebarWrapper}>
        {this.getMenuLinks().map(menuLink => (
          <Fragment key={shortid.generate()}>
            {menuLink.title ? (
              <Typography
                component="h4"
                variant="h6"
                gutterBottom
                className={classes.sidebarTitle}
              >
                {menuLink.title}
              </Typography>
            ) : null}
            <MenuLinks sections={menuLink.routes} currentPath={currentPath} />
          </Fragment>
        ))}
      </Grid>
    );
  }
}

export default compose(
  withStyles(styles),
  inject('CommonStore'),
  observer
)(Sidebar);
