/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// Components
import {
  MainContentWrapper,
  PaymentsSummary,
  PaymentsFilter,
  DataTable
} from '@CommonScene';

// Configs
import { tableConfig } from '@Assets/config/tables/servicePayments';

// Utils
import { compose } from '@Utils';
import { getDateFilterKey } from '@Utils/formatting';

export class ServicePayments extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    ServicePaymentsStore: PropTypes.shape({
      serviceOptions: PropTypes.object,
      serviceSummary: PropTypes.object,
      serviceTransactions: PropTypes.array,
      updateSearchCriteria: PropTypes.func,
      getServiceSummary: PropTypes.func,
      getServiceTransactions: PropTypes.func,
      getAggregatedServiceTransactions: PropTypes.func,
      clearPaging: PropTypes.func,
      clearServiceSummary: PropTypes.func,
      clearServiceTransactions: PropTypes.func,
      setPage: PropTypes.func,
      setServiceWalletId: PropTypes.func,
      setDateRange: PropTypes.func,
      resetData: PropTypes.func,
      reset: PropTypes.func,
      setOrder: PropTypes.func,
      servicesList: PropTypes.array,
      serviceWalletId: PropTypes.string
    }).isRequired,
    t: PropTypes.func.isRequired
  };

  componentDidMount() {
    const {
      history,
      ServicePaymentsStore: {
        servicesList,
        getAggregatedServiceTransactions,
        serviceWalletId,
        setOrder,
        dateRange,
        updateSearchCriteria
      }
    } = this.props;

    if (!serviceWalletId) {
      history.replace('/payments/service');
      return;
    }

    if (!servicesList.length) {
      getAggregatedServiceTransactions({ order: 'desc', orderBy: 'owner' });
    }

    setOrder({ orderBy: tableConfig.defaultOrderBy });
    updateSearchCriteria(dateRange);
    this.getServiceData(serviceWalletId);
  }

  componentWillUnmount() {
    this.props.ServicePaymentsStore.reset();
  }

  getServiceData = serviceProviderCareWalletId => {
    const {
      ServicePaymentsStore: { getServiceSummary, getServiceTransactions }
    } = this.props;

    getServiceSummary({ serviceProviderCareWalletId });
    getServiceTransactions({ serviceProviderCareWalletId });
  };

  fetchTableData = () => {
    const {
      ServicePaymentsStore: { setPage, getServiceTransactions, serviceWalletId }
    } = this.props;

    setPage();
    getServiceTransactions({
      serviceProviderCareWalletId: serviceWalletId
    });
  };

  handleChangeSelect = ({ target: { value } }) => {
    const {
      ServicePaymentsStore: { resetData, setServiceWalletId }
    } = this.props;

    resetData();
    setServiceWalletId(value);
    this.getServiceData(value);
  };

  updateTableData = searchCriteria => {
    const {
      ServicePaymentsStore: {
        updateSearchCriteria,
        clearServiceSummary,
        clearServiceTransactions,
        serviceWalletId,
        clearPaging
      }
    } = this.props;

    updateSearchCriteria(searchCriteria);
    clearPaging();
    clearServiceSummary();
    clearServiceTransactions();
    this.getServiceData(serviceWalletId);
  };

  handleChangeDate = (date, key) => {
    const {
      ServicePaymentsStore: { setDateRange }
    } = this.props;
    setDateRange({ [getDateFilterKey(key)]: date });
    this.updateTableData();
  };

  getHeaderOptions = () => ({
    title: this.props.t('can.services.transactions.title')
  });

  render() {
    const {
      ServicePaymentsStore: {
        serviceWalletId,
        servicesList,
        serviceSummary,
        serviceTransactions,
        serviceOptions,
        dateRange
      },
      t
    } = this.props;

    return (
      <MainContentWrapper headerOptions={this.getHeaderOptions()}>
        <PaymentsFilter
          selectName={t('can.services.transactions.selectServiceTitle')}
          selectList={servicesList}
          selectValue={serviceWalletId}
          handleChangeSelect={this.handleChangeSelect}
          handleChangeDate={this.handleChangeDate}
          dateRange={dateRange}
        />
        <PaymentsSummary transactionsSummary={serviceSummary} />
        <DataTable
          tableConfig={tableConfig}
          tableData={serviceTransactions}
          fetchTableData={this.fetchTableData}
          hasMoreData={!serviceOptions.last}
        />
      </MainContentWrapper>
    );
  }
}

export default compose(
  withRouter,
  withTranslation(),
  inject('ServicePaymentsStore'),
  observer
)(ServicePayments);
