/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import { observable, action } from 'mobx';
import { AuthProvider } from '@Providers';
import _ from 'lodash';

// Stores
import CommonStore from '@Stores/CommonStore';
import GroupStore from '@Stores/GroupStore';
import ValidationStore from '@Stores/ValidationStore';
import RoleStore from '@Stores/RoleStore';
import NetworkStore from '@Stores/NetworkStore';
import InvitationStore from '@Stores/InvitationStore';
import MembersPaymentsStore from '@Stores/MembersPaymentsStore';
import ServicePaymentsStore from '@Stores/ServicePaymentsStore';
import DepositsStore from '@Stores/DepositsStore';

import { AuthForms as forms } from '@Assets/config/configForAuthForms';

// Utils
import { parseTokens } from '@Utils/formatting';
import isEmpty from 'lodash/isEmpty';

const MAX_COOKIES_SIZE = 4000;

class AuthStore extends ValidationStore {
  @observable errors = null;

  @observable forms = forms;

  @observable resetPasswordStep = null;

  @observable isAccessPermissionsError = false;

  @observable authData = parseTokens();

  @observable careWalletId = null;

  _checkingInterval = null;

  constructor() {
    super();
    const userData = JSON.parse(window.localStorage.getItem('currentUserInfo'));

    if (userData && userData.care_wallet_id) {
      this._setCareWalletId(userData.care_wallet_id);
    }
    this._checkSession();
    if (!isEmpty(this.authData)) {
      this._checkingInterval = setInterval(this._checkCookies, 1000);
    }
  }

  @action('AuthStore => _setAuthData') _setAuthData = userData => {
    const roles = userData.groupDeckPermissions.reduce((result, permission) => {
      result.push(permission.deck_permissions[0].deck_name);
      return result;
    }, []);

    const permissionNames = _.flatten(
      userData.groupDeckPermissions.reduce((acc, permission) => {
        acc.push(permission.deck_permissions);
        return acc;
      }, [])
    )
      .filter(
        deckPermission => deckPermission.deck_name !== 'PAYMENT_ADMIN_DECK'
      )
      .reduce((acc, deckPermission) => {
        return [...acc, ...deckPermission.permission_names];
      }, []);

    ServicePaymentsStore.setPermissionNames(permissionNames);

    this.authData = {
      roles: JSON.stringify(roles),
      permissionNames: JSON.stringify(permissionNames),
      token: userData.access_token,
      refreshToken: userData.refresh_token,
      expiredDate: new Date().getTime() + userData.expires_in * 1000,
      expiredTime: userData.expires_in * 1000
    };
  };

  @action('AuthStore => _deleteAuthData') _deleteAuthData = () => {
    this.authData = {};
  };

  _setCookie = ({ name, value = '' }) => {
    document.cookie = `${name}=${value}; path=/`;
  };

  _setCookies = () => {
    Object.keys(this.authData).forEach(name => {
      const value = `${this.authData[name]}`;

      if (value.length > MAX_COOKIES_SIZE) {
        const regExp = new RegExp(`.{1,${MAX_COOKIES_SIZE}}`, 'g');

        value.match(regExp).forEach((item, index) => {
          this._setCookie({ name: `${name}-${index}`, value: item });
        });
      } else {
        this._setCookie({ name, value });
      }
    });
  };

  _deleteCookies = () => {
    document.cookie.split(';').forEach(item => {
      const equalPosition = item.indexOf('=');
      const name = equalPosition > -1 ? item.substr(0, equalPosition) : item;

      this._setCookie({ name });
    });
  };

  _checkSession = () => {
    if (this.authData.expiredDate > new Date().getTime()) {
      return;
    }
    this.reset();
  };

  _checkCookies = () => {
    const currentCookies = parseTokens();

    if (isEmpty(currentCookies)) {
      this.logout();
      clearInterval(this._checkingInterval);
    }
  };

  @action('AuthStore => handle errors') _handleErrors = err => {
    const resMessage =
      err &&
      err.response &&
      err.response.body &&
      err.response.body.errors.find(message => message.defaultMessage);
    const resMessageText = (resMessage && resMessage.defaultMessage) || '';
    this.setErrorMessage(resMessageText);
    throw err;
  };

  @action('AuthStore => login') login = () => {
    const { login, password } = this.forms.signInForm.fields;
    this.errors = null;

    return AuthProvider.loginUser({
      phone: `+${login.value}`,
      password: password.value
    })
      .then(
        action('AuthStore => handler in login', userData => {
          this._setAuthData(userData);
          this._setCookies();
          this._setCareWalletId(userData.careWalletId);
          this._checkingInterval = setInterval(this._checkCookies, 1000);
          CommonStore.getCurrentUser();
          CommonStore.getUserRole();
          this.clearErrorMessage();
        })
      )
      .catch(this._handleErrors);
  };

  @action _setCareWalletId = careWalletId => {
    this.careWalletId = careWalletId;
  };

  @action _clearCareWalletId = () => {
    this.careWalletId = null;
  };

  @action('AuthStore => refreshToken') refreshToken = () => {
    const { refreshToken } = this.authData;

    return AuthProvider.refreshToken(refreshToken)
      .then(userData => {
        this._setAuthData(userData);
        this._setCookies();
      })
      .catch(this._handleErrors);
  };

  @action('AuthStore => sendPhoneForResetPassword')
  sendPhoneForResetPassword = () => {
    const {
      resetPasswordPhone: {
        fields: { phone }
      }
    } = this.forms;

    return AuthProvider.sendPhoneForReset(`+${phone.value}`)
      .then(() => this.handleChangeForm(2))
      .catch(this._handleErrors);
  };

  @action('AuthStore => sendConfirmationCode') sendConfirmationCode = () => {
    const {
      resetPasswordCode: {
        fields: { code }
      },
      resetPasswordPhone: {
        fields: { phone }
      }
    } = this.forms;

    return AuthProvider.sendConfirmationCode(`+${phone.value}`, code.value)
      .then(() => this.handleChangeForm(3))
      .catch(this._handleErrors);
  };

  @action('AuthStore => resetPassword') resetPassword = () => {
    const {
      resetPasswordCode: {
        fields: { code }
      },
      resetPasswordPhone: {
        fields: { phone }
      },
      resetPassword: {
        fields: { passwordConfirm }
      }
    } = this.forms;

    return AuthProvider.resetPassword(
      `+${phone.value}`,
      code.value,
      passwordConfirm.value
    ).catch(this._handleErrors);
  };

  @action('AuthStore => setErrorMessage') setErrorMessage = errorText => {
    this.errors = errorText;
  };

  @action('AuthStore => handleChangeForm') handleChangeForm = step => {
    this.resetPasswordStep = step;
  };

  @action('AuthStore => clearInputFields') clearInputFields = (
    form,
    fields
  ) => {
    fields.forEach(item => {
      this.forms[form].fields[item].value = '';
      this.forms[form].fields[item].error = '';
    });
  };

  @action('AuthStore => clearErrorMessage') clearErrorMessage = () => {
    this.errors = null;
  };

  @action('AuthStore => reset data in store') reset() {
    this.forms = forms;
    this.resetPasswordStep = null;
    this._deleteCookies();
    this._deleteAuthData();
    this._clearCareWalletId();
  }

  @action('AuthStore => logout') logout() {
    this.reset();
    RoleStore.reset();
    CommonStore.reset();
    MembersPaymentsStore.reset();
    ServicePaymentsStore.reset();
    MembersPaymentsStore.reset();
    MembersPaymentsStore.clearDateRange();
    ServicePaymentsStore.clearDateRange();
    DepositsStore.clearDateRange();
    // TODO: Need to reset all stores on logout in one line of code
    NetworkStore.clearUsers();
    NetworkStore.resetPage();
    NetworkStore.clearQuery();
    InvitationStore.resetPage();
    InvitationStore.clearQuery();
    GroupStore.resetPage();
    GroupStore.clearQuery();
  }

  @action('AuthStore => handleAccessPermissionsError')
  handleAccessPermissionsError = () => {
    // Handle only for authenticated users
    if (this.authData.token) {
      this.isAccessPermissionsError = true;
    }
  };

  @action('AuthStore => skipAccessPermissionsError')
  skipAccessPermissionsError = () => {
    this.isAccessPermissionsError = false;
  };
}

export default new AuthStore();
