/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

import { observable, action, computed, set } from 'mobx';
import { isObject, without, isEmpty, debounce } from 'lodash';
import { filterSuggestions } from '@Utils/sorting';
import {
  UserProvider,
  RoleProvider,
  GroupProvider,
  InvitationProvider
} from '@Providers';
import { DELAY_REQUEST } from '@Utils/constans';

// stores
import ValidationStore from '@Stores/ValidationStore';

// config
import { NetworkParticipantsForms as forms } from '@Assets/config/configForNetworkForms';
import { userStatuses } from '@Assets/config/configForStatuses';
import CommonStore from '@Stores/CommonStore';
import InvitationStore from '@Stores/InvitationStore';

class NetworkStore extends ValidationStore {
  userId = null;

  roleId = null;

  roleTemplateId = null;

  currentGroupId = null;

  userName = null;

  _setCurrentUser = (user, formToSaveData) => {
    this.currentUser = user;
    this._setUserFormData(user, formToSaveData);
  };

  _setUserFormData = (user, formToSaveData = 'networkEditForm') => {
    const fields = this.forms[formToSaveData].fields;
    fields.name.value = user.first_name || '';
    fields.surname.value = user.last_name || '';
    if (formToSaveData === 'inviteEditForm') {
      fields.phone.value = user.phone_number || '';
    }
  };

  users = observable.array([], { deep: false });

  groupUsers = observable.array([], { deep: false });

  filter = observable.array([], { deep: false });

  customFields = observable.array([], { deep: false });

  roleTemplates = observable.array([], { deep: false });

  roleOptions = observable.array([], { deep: false });

  fields = InvitationStore.fields;

  _currentUserStatus = null;

  @observable userStatus = userStatuses;

  @observable allStatusesChecked = false;

  @observable forms = forms;

  @observable isUpdating;

  @observable openAddRoleModal = false;

  @observable updateErrors;

  @observable currentUser = {};

  @observable currentUserRoles = null;

  @observable searchQuery = '';

  @observable currentRoleId;

  @observable userCustomField = {};

  @observable order = 'asc';

  @observable orderBy = 'firstName';

  @observable totalRows = 25;

  @observable lastPage = false;

  @observable page = 0;

  @observable rowsPerPage = 25;

  @observable groupUsersPage = 0;

  @observable deletedRoles = null;

  @observable rolesToDelete = null;

  @observable lastUsersPage = false;

  @computed get isAddRoleFieldsValid() {
    return (
      !isEmpty(this.userCustomField) &&
      Boolean(this.forms.networkForm.fields.role.value)
    );
  }

  // Not usable for now. Just for Sonar validation
  set isAddRoleFieldsValid(value) {
    this.forms.networkForm.meta.isValid = value;
  }

  @computed get filteredUsers() {
    const f = this.filter;
    return f.length ? this.users.filter(r => f.includes(r.id)) : this.users;
  }

  // Not usable for now. Just for Sonar validation
  set filteredUsers(value) {
    this.users = value;
  }

  _checkPhoneInQuery = () => {
    const searchStr = this.searchQuery.trim();
    const regPhone = /^\+?(\d)(\s|-)?(\(\d{3}\)|(\d{3}))(\s|-)?\d{0,3}(\s|-)?\d{0,4}$/;
    const isPhone = regPhone.test(searchStr);

    return isPhone ? searchStr.replace(/\s|-|\(|\)/g, '') : searchStr;
  };

  @action setActualUserStatus = () => {
    if (this._currentUserStatus) {
      this.userStatus = this._currentUserStatus;
    }
  };

  @action getUsersAccounts = (orderBy = this.orderBy) => {
    let queryS = {
      page: this.page,
      size: this.rowsPerPage,
      userStatus: this.getUserStatuses
    };

    queryS = { ...queryS, sort: `${orderBy},${this.order}` };

    if (this.searchQuery) {
      queryS = {
        ...queryS,
        searchStr: this._checkPhoneInQuery(this.searchQuery)
      };
    }

    CommonStore.setPending();
    return UserProvider.getUsers(queryS)
      .then(
        action('initiate setUsersList', users => {
          this._currentUserStatus = { ...this.userStatus };
          this.setUsersList(users);
        })
      )
      .finally(CommonStore.clearPending);
  };

  @action getGroupUsersAccounts = (
    groupId = this.currentGroupId,
    orderBy = this.orderBy
  ) => {
    CommonStore.setPending();
    let queryS = {
      groupId,
      page: this.page,
      size: this.size,
      userStatus: this.getUserStatuses
    };
    queryS = { ...queryS, sort: `${orderBy},${this.order}` };
    return UserProvider.getUsers(queryS)
      .then(
        action(groupUsers => {
          const filteredUsers = without(groupUsers.content, 'undefined');
          this.groupUsersPage = groupUsers.number;
          this.lastUsersPage = groupUsers.last;
          if (this.groupUsersPage === 0) {
            this.groupUsers.replace([]);
          }
          const newGroupUsers = [...this.groupUsers, ...filteredUsers];
          this.groupUsers.replace(newGroupUsers);
        })
      )
      .finally(
        action(() => {
          CommonStore.clearPending();
        })
      );
  };

  @action resetUsersPage = () => {
    this.groupUsersPage = 0;
    this.lastUsersPage = false;
    this.currentGroupId = null;
  };

  @action resetOrder = () => {
    this.order = 'asc';
  };

  @action resetOrderBy = () => {
    this.orderBy = 'firstName';
  };

  @action reset() {
    this.resetUsersPage();
    this.resetPage();
    this.clearQuery();
    this.resetOrder();
    this.resetOrderBy();
  }

  @action setOrder({ groupId, orderBy }) {
    if (this.orderBy !== orderBy) {
      // set default sorting if new column is clicked
      this.order = 'asc';
    } else if (this.order === 'asc') {
      this.order = 'desc';
    } else {
      this.order = 'asc';
    }

    // assign new getAllsorting param (clicked column)
    this.orderBy = orderBy;

    if (groupId) {
      this.getGroupUsersAccounts(groupId, orderBy);
    } else {
      this.getUsersAccounts(orderBy);
    }
  }

  @action clearGroupUsers = () => {
    this.groupUsers.replace([]);
  };

  _handleServerError = err => {
    this.errors = err.response && err.response.body && err.response.body.errors;
    throw err;
  };

  @action getAllGroups() {
    CommonStore.setPending();
    return GroupProvider.getGroups()
      .then(
        action(response => {
          this._setGroups(response.content);
        })
      )
      .catch(
        action(err => {
          this._handleServerError(err);
        })
      )
      .finally(
        action(() => {
          CommonStore.clearPending();
        })
      );
  }

  @action getActiveRoles = () => {
    CommonStore.setPending();
    return RoleProvider.getActiveRoles()
      .then(
        action(response => {
          this.setRoleOptions(response.content);
        })
      )
      .catch(
        action(err => {
          this._handleServerError(err);
        })
      )
      .finally(CommonStore.clearPending);
  };

  @action getAllTemplates = () => {
    CommonStore.setPending();
    return RoleProvider.getRoles({ size: 3000 })
      .then(
        action(response => {
          this.setTemplates(response.content);
        })
      )
      .catch(
        action(err => {
          this._handleServerError(err);
        })
      )
      .finally(
        action(() => {
          CommonStore.clearPending();
        })
      );
  };

  @action getUser = (userId, formToSaveData) => {
    CommonStore.setPending();
    return UserProvider.getUser(userId)
      .then(
        action(user => {
          this._setCurrentUser(user, formToSaveData);
        })
      )
      .catch(
        action(err => {
          this._handleServerError(err);
        })
      )
      .finally(CommonStore.clearPending);
  };

  @action getUserRoles = userId => {
    CommonStore.setPending();
    return UserProvider.getUserRoles(userId)
      .then(
        action(user => {
          this.setUserRoles(user);
        })
      )
      .catch(
        action(err => {
          this._handleServerError(err);
        })
      )
      .finally(CommonStore.clearPending);
  };

  @action setDeletedRoles = deletedRoles => {
    this.deletedRoles = deletedRoles || null;
  };

  @action deleteUser = () => {
    CommonStore.setPending();
    return UserProvider.deleteUser(
      this.userId,
      this.forms.deleteForm.fields.reason.value
    )
      .then(
        action(user => {
          this._setCurrentUser(user);
          this.users.replace([]);
          this.getUsersAccounts();
        })
      )
      .catch(
        action(err => {
          this._handleServerError(err);
        })
      )
      .finally(
        action(() => {
          CommonStore.clearPending();
        })
      );
  };

  @action resetForms = () => {
    this.forms = forms;
  };

  @action activateUser = id => {
    CommonStore.setPending();
    return UserProvider.activateUser(id)
      .then(action(user => this.setUserStatus(user)))
      .catch(
        action(err => {
          this._handleServerError(err);
        })
      )
      .finally(
        action(() => {
          CommonStore.clearPending();
        })
      );
  };

  @action blockUser = id => {
    CommonStore.setPending();
    return UserProvider.blockUser(id)
      .then(action(user => this._setCurrentUser(user)))
      .catch(
        action(err => {
          this._handleServerError(err);
        })
      )
      .finally(
        action(() => {
          CommonStore.clearPending();
        })
      );
  };

  @action unblockUser = id => {
    CommonStore.setPending();
    return UserProvider.activateUser(id)
      .then(
        action(user => {
          this._setCurrentUser(user);
        })
      )
      .catch(
        action(err => {
          this._handleServerError(err);
        })
      )
      .finally(
        action(() => {
          CommonStore.clearPending();
        })
      );
  };

  @action('NetworkStore => set user id') setUserId = id => {
    this.userId = id;
  };

  @action delUserId = () => {
    this.userId = null;
  };

  @action updateUser = id => {
    CommonStore.setPending();
    return UserProvider.updateUser(id, this.currentUser)
      .then(action(user => this._setCurrentUser(user)))
      .catch(
        action(err => {
          this._handleServerError(err);
        })
      )
      .finally(
        action(() => {
          CommonStore.clearPending();
        })
      );
  };

  @action deleteRole = roleId => {
    CommonStore.setPending();
    return RoleProvider.deleteRole(roleId)
      .then(action(user => this._setCurrentUser(user)))
      .catch(
        action(err => {
          this._handleServerError(err);
        })
      )
      .finally(
        action(() => {
          CommonStore.clearPending();
        })
      );
  };

  @action addUserToGroup = (groupId, roleId, accountId) => {
    CommonStore.setPending();
    return InvitationProvider.inviteUser(groupId, roleId, accountId)
      .then(() => this.getUser(this.currentUser.id))
      .catch(
        action(err => {
          this._handleServerError(err);
        })
      )
      .finally(
        action(() => {
          CommonStore.clearPending();
        })
      );
  };

  @action addCustomFields = (groupId, roleTemplateId, userId) => {
    CommonStore.setPending();
    return InvitationProvider.inviteUser(groupId, roleTemplateId, userId)
      .then(() => this.getUser(this.currentUser.id))
      .catch(
        action(err => {
          this._handleServerError(err);
        })
      )
      .finally(
        action(() => {
          CommonStore.clearPending();
        })
      );
  };

  @action deleteUserFromRole = (roleId, userId) => {
    CommonStore.setPending();
    return RoleProvider.deleteUserFromRole(roleId, userId)
      .then(() => {
        this.getUser(this.currentUser.id);
        this.getUserRoles(this.currentUser.id);
      })
      .catch(
        action(err => {
          if (err.status === 400) {
            CommonStore.setModalOptions({
              modalName: 'ModalError',
              modalProps: { text: err.response.body.error }
            });
          }
          this._handleServerError(err);
        })
      )
      .finally(
        action(() => {
          CommonStore.clearPending();
        })
      );
  };

  @action checkOrderParams = () => {
    const changedOrder = this.order === 'ASC' ? 'DESC' : 'ASC';
    this.order = changedOrder;
  };

  @action setPagesParams(users) {
    this.totalRows = users.total_elements;
    this.lastPage = users.last;
  }

  @action setUsersList = users => {
    const filteredUsers = users.content.filter(n => isObject(n));
    this.setPagesParams(users);

    if (this.page === 0) {
      this.users.replace([]);
    }

    if (this.users.length === this.totalRows) {
      return false;
    }

    const addNewUsers = [...this.users, ...filteredUsers];
    return this.users.replace(addNewUsers);
  };

  @computed get getUserStatuses() {
    return Object.keys(this.userStatus).filter(item => this.userStatus[item]);
  }

  // Not usable for now. Just for Sonar validation
  set getUserStatuses(value) {
    this.userStatus = value;
  }

  _setGroups(groups) {
    const newCustomFields = filterSuggestions(groups);
    this.customFields.replace(newCustomFields);
  }

  @action setTemplates(templates) {
    this.roleTemplates.replace(templates);
  }

  @action setRoleTemplateId = roleTemplateId => {
    this.roleTemplateId = roleTemplateId;
  };

  @action setRoleOptions(roles) {
    const GroupTemplateId = 2;
    const options = roles.filter(r => r.group !== GroupTemplateId);
    this.roleOptions.replace(options);
  }

  @action setUserRoles = user => {
    this.currentUserRoles = user.roles;
  };

  @action setCurrentRoleId = roleId => {
    this.currentRoleId = roleId;
  };

  @action setFilter(userIds) {
    this.filter.replace(userIds);
  }

  @action setQuery = query => {
    this.searchQuery = query;
    this._searchWithDebounce();
  };

  _searchWithDebounce = debounce(
    action(() => {
      this.page = 0;
      if (this.searchQuery) {
        this.checkAllStatuses();
      } else {
        this.setDefaultStatuses();
      }
      this.getUsersAccounts();
    }),
    DELAY_REQUEST
  );

  @action checkAllStatuses = () => {
    this.allStatusesChecked = true;
    this.userStatus = {
      ACTIVE: true,
      INVITED: true,
      BLOCKED: true,
      REMOVED: true
    };
  };

  @action handleStatusSelect = ({ type, checked }) => {
    this.userStatus[type] = checked;
    this.allStatusesChecked = Object.values(this.userStatus).every(
      item => item
    );
  };

  @action handleStatusAllSelect = ({ checked }) => {
    Object.keys(this.userStatus).forEach(item => {
      this.userStatus[item] = checked;
    });
    this.allStatusesChecked = checked;
  };

  @action resetCurrentUser = () => {
    this.currentUser = {};
    this.currentUserRoles = null;
  };

  @action clearQuery = () => {
    this.searchQuery = '';
    this.setDefaultStatuses();
  };

  @action setDefaultStatuses = () => {
    this.allStatusesChecked = false;
    this.userStatus = userStatuses;
  };

  _updateUsers = () => {
    this.getUsersAccounts();
    this.resetPage();
    this.delUserId();
  };

  @action setBlockUser = () => {
    this.blockUser(this.userId).then(this._updateUsers);
  };

  @action setUnblockUser = () => {
    this.unblockUser(this.userId).then(this._updateUsers);
  };

  @action handleUserChangeCustomField = newCustomField => {
    this.userCustomField = newCustomField;
  };

  @action handleChangePage = newPage => {
    this.page = newPage;
    if (this.currentGroupId) {
      this.getGroupUsersAccounts();
    } else {
      this.getUsersAccounts();
    }
  };

  @action handleChangeRowsPerPage = newPageSize => {
    this.rowsPerPage = newPageSize;
    this.getUsersAccounts();
  };

  @action handleChangeUserName = name => {
    set(this.currentUser, { first_name: name });
  };

  @action handleChangeUserSurname = surname => {
    set(this.currentUser, { last_name: surname });
  };

  @action handleOpenRoleModal = () => {
    this.openAddRoleModal = !this.openAddRoleModal;
  };

  @action setCurrentGroup = groupId => {
    this.currentGroupId = groupId;
  };

  @action onFieldRoleChange = (field, value, formName) => {
    this.forms[formName].fields[field].value = value;
  };

  @action resetPage = () => {
    this.page = 0;
  };

  @action clearUsers = () => {
    this.users.replace([]);
  };

  @action setAdminGroup(roleId) {
    InvitationStore.setAdminGroup(roleId);
  }
}

export default new NetworkStore();
