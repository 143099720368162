/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

// Material-UI
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

// Styles
import styles from './styles';

@withTranslation()
@inject('DeckStore')
@withRouter
@observer
class RoleCustomFieldsForm extends React.Component {
  constructor(props) {
    super(props);

    // TODO: code below need refactor
    if (props.DeckStore.decks.length < 1) {
      props.DeckStore.getDecks();
    }
    this.state = { checked: false };
  }

  static propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    DeckStore: PropTypes.object
  };

  render() {
    const {
      t,
      classes,
      DeckStore: { customFields }
    } = this.props;

    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" color="textPrimary">
            <span className={classes.rowLable}>{t('common.customFields')}</span>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.infoWrapper}>
            <InfoIcon className={classes.infoIcon} color="action" />
            <Typography className={classes.infoText} variant="subtitle1">
              {t('mwAdmin.roles.requiredFieldsExplanation')}
            </Typography>
          </div>
        </Grid>
        {customFields.map((custom, index) => (
          <Grid key={index} item xs={12}>
            <span className={classes.identifier}>
              {`${custom} ${t('mwAdmin.roles.identifier')}`}
            </span>
          </Grid>
        ))}
      </Grid>
    );
  }
}

export default withStyles(styles)(RoleCustomFieldsForm);
