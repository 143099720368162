/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import { observable, action, computed } from 'mobx';

// Providers
import { DeckProvider } from '@Providers';

// Stores
import CommonStore from '@Stores/CommonStore';

class DeckStore {
  decks = observable.array([], { deep: false });

  @observable checkedDecks = {};

  @action('DeckStore => assigning decks to store') _setDecks = decks => {
    this.decks.replace(decks.content);
  };

  @action('DeckStore => getting decks from server')
  getDecks = () => {
    CommonStore.setPending();
    return DeckProvider.getAllDecks()
      .then(this._setDecks)
      .finally(CommonStore.clearPending);
  };

  @action('DeckStore => resetCheckedDecks') resetCheckedDecks = () => {
    this.checkedDecks = {};
  };

  @action('DeckStore => setCheckedDecks')
  setCheckedDecks = ({ id, checked }) => {
    this.checkedDecks[id] = checked;
  };

  @computed get getRolePermissions() {
    return this.decks.filter(deck => this.checkedDecks[deck.id]);
  }

  @computed get customFields() {
    const customFields = this.decks.reduce((acc, role) => {
      if (this.checkedDecks[role.id]) {
        return acc.concat(role.custom.map(item => item.name));
      }
      return acc;
    }, []);

    return [...new Set(customFields)];
  }

  @computed get deckPermissions() {
    const data = {};

    Object.keys(this.checkedDecks).forEach(deckId => {
      data[deckId] = this._getDeck(deckId).permissions.map(p => p.id);
    });
    return data;
  }

  _getDeck(id) {
    const deckId = Number(id);
    const index = this.decks.findIndex(d => d.id === deckId);

    return this.decks[index];
  }

  getDeckName(id) {
    const deckId = Number(id);
    const deck = this._getDeck(deckId);

    return deck ? deck.name : 'none';
  }
}

export default new DeckStore();
