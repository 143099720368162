/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

// Material UI
import { Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// Utils
import { compose } from '@Utils';
import { validationKeys } from '@Utils/constans';

// Styles
import styles from './styles';

export class ResetPasswordButtons extends Component {
  static defaultProps = {
    handleChangeForm: () => {
      // Stub function
    },
    step: null,
    submitBtnText: 'Next'
  };

  static propTypes = {
    clearErrorMessage: PropTypes.func.isRequired,
    handleChangeForm: PropTypes.func,
    history: PropTypes.object.isRequired,
    step: PropTypes.number,
    classes: PropTypes.object.isRequired,
    isValid: PropTypes.bool.isRequired,
    submitBtnText: PropTypes.string,
    t: PropTypes.func.isRequired
  };

  handleBack = e => {
    e.preventDefault();

    const { clearErrorMessage, handleChangeForm, history, step } = this.props;

    clearErrorMessage();
    if (step) {
      handleChangeForm(step);
    } else {
      history.push('/login');
    }
  };

  render() {
    const { classes, isValid, submitBtnText, t } = this.props;

    return (
      <Grid container justify="flex-end" className={classes.marginTop}>
        <Button
          onClick={this.handleBack}
          className={classNames(classes.button, classes.textLight)}
          color="primary"
        >
          {t('auth.resetPassword.common.backBtn')}
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          color="primary"
          type="submit"
          disabled={!isValid}
        >
          {t(validationKeys[submitBtnText])}
        </Button>
      </Grid>
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation(),
  withRouter
)(ResetPasswordButtons);
