/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

// Material UI
import { withStyles } from '@material-ui/core';

// Components
import RoleEditForm from '@NetworkScene/scenes/EditRole/components/RoleEditForm';
import CustomFieldsTable from '@NetworkScene/scenes/EditRole/components/CustomFieldsTable';
import EditRoleButtons from '@NetworkScene/scenes/EditRole/components/EditRoleButtons';

// Utils
import { filteredRoles } from '@Utils/sorting';
import { compose } from '@Utils';

// Style
import styles from './styles';

export class RoleEdit extends React.Component {
  state = {
    customField: null,
    existField: false,
    addField: false,
    initialRolesId: [],
    temporaryCustomFields: []
  };

  static propTypes = {
    NetworkStore: PropTypes.shape({
      currentUser: PropTypes.object,
      setRoleTemplateId: PropTypes.func,
      setAdminGroup: PropTypes.func,
      getUser: PropTypes.func,
      getAllTemplates: PropTypes.func,
      getActiveRoles: PropTypes.func,
      currentGroupId: PropTypes.number,
      roleTemplateId: PropTypes.number,
      addCustomFields: PropTypes.func,
      deleteUserFromRole: PropTypes.func
    }).isRequired,
    CommonStore: PropTypes.shape({
      updateModalProps: PropTypes.func,
      setModalOptions: PropTypes.func
    }).isRequired,
    InvitationStore: PropTypes.shape({
      getAllGroupsWithDebounce: PropTypes.func,
      getAllDecks: PropTypes.func,
      fetchRoleTemplates: PropTypes.func,
      isNPI: PropTypes.bool,
      filteredSuggestions: PropTypes.array
    }).isRequired,
    classes: PropTypes.object.isRequired,
    history: PropTypes.object,
    match: PropTypes.shape({
      params: PropTypes.shape({
        roleId: PropTypes.string.isRequired,
        userId: PropTypes.string.isRequired
      })
    })
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      NetworkStore: {
        currentUser: { roles }
      },
      match: {
        params: { roleId }
      }
    } = nextProps;
    const userRoles = toJS(roles);
    const currentRole = userRoles
      ? userRoles.find(item => item.id === Number(roleId))
      : '';

    if (currentRole) {
      const temporaryCustomFields = filteredRoles(currentRole, userRoles);
      nextProps.NetworkStore.setRoleTemplateId(currentRole.role_template_id);

      if (!prevState.temporaryCustomFields.length) {
        const initialRolesId = temporaryCustomFields.map(e => e.roleId);
        return {
          ...prevState,
          initialRolesId,
          temporaryCustomFields
        };
      }
    }

    return null;
  }

  componentDidMount() {
    const {
      NetworkStore,
      InvitationStore,
      match: {
        params: { userId, roleId }
      }
    } = this.props;

    NetworkStore.getUser(userId).then(() => {
      const currentUser = NetworkStore.currentUser;
      const userRoles = toJS(currentUser.roles);
      const currentRoleId = this.getCurrentRole(userRoles, roleId)
        .role_template_id;

      InvitationStore.fetchRoleTemplates().then(() => {
        NetworkStore.setAdminGroup(currentRoleId);
      });
    });
    NetworkStore.getAllTemplates();
    NetworkStore.getActiveRoles();
    InvitationStore.getAllDecks();
  }

  inputOnChange = ({ target: { value } }) => {
    if (!value) {
      return;
    }
    this.props.InvitationStore.getAllGroupsWithDebounce({
      searchStr: value
    });
  };

  onCustomFieldChange = field => {
    const isFieldExist = this.state.temporaryCustomFields.some(
      elem => elem.groupId === field.id
    );

    this.setState(
      {
        customField: {
          ...field,
          NPI: field.NPI ? { ...field.NPI, id: field.NPI.value } : {},
          TIN: field.TIN ? { ...field.TIN, id: field.TIN.value } : {}
        },
        existField: !!isFieldExist
      },
      () => {
        this.props.CommonStore.updateModalProps({
          customField: this.state.customField,
          existField: this.state.existField
        });
      }
    );
  };

  handleRoleActionClick = () => {
    const {
      CommonStore: { setModalOptions }
    } = this.props;

    setModalOptions({
      modalName: 'AddFieldModal',
      modalProps: {
        customField: this.state.customField,
        onCustomFieldChange: this.onCustomFieldChange,
        inputOnChange: this.inputOnChange,
        filteredSuggestion: [],
        existField: this.state.existField,
        handleConfirmAction: this.handleAddCustomFields
      }
    });
  };

  handleFindIndex = (field, id) => field.findIndex(e => e.groupId === id);

  handleAddRecord = () => {
    this.setState({ addField: true });
    this.handleRoleActionClick();
  };

  handleEditRecord = () => {
    this.setState({ addField: false });
  };

  handleAddCustomFields = () => {
    const {
      NetworkStore: { currentGroupId },
      match: {
        params: { roleId }
      }
    } = this.props;
    const { addField, customField, temporaryCustomFields } = this.state;
    const newCustomField = {
      roleId,
      groupId: customField.id,
      TIN: customField.TIN,
      NPI: customField.NPI
    };
    const currentIndex = this.handleFindIndex(
      temporaryCustomFields,
      currentGroupId
    );

    if (addField) {
      temporaryCustomFields.push(newCustomField);
    } else {
      temporaryCustomFields.splice(currentIndex, 1, newCustomField);
    }
    this.setState({
      ...this.state,
      temporaryCustomFields,
      customField: null
    });
  };

  handleDeleteCustomFields = currentGroupId => {
    const { temporaryCustomFields } = this.state;

    const currentIndex = this.handleFindIndex(
      temporaryCustomFields,
      currentGroupId
    );
    temporaryCustomFields.splice([currentIndex], 1);

    this.setState({
      ...this.state,
      temporaryCustomFields
    });
  };

  handleSaveNewCustomFields = () => {
    const {
      match: {
        params: { userId }
      },
      history,
      NetworkStore: {
        roleTemplateId,
        addCustomFields,
        deleteUserFromRole,
        setRoleTemplateId
      }
    } = this.props;
    const { temporaryCustomFields, initialRolesId } = this.state;
    const groupIds = temporaryCustomFields.map(e => e.groupId);

    addCustomFields(groupIds, roleTemplateId, [userId]).then(() => {
      if (initialRolesId.length > 0) {
        initialRolesId.map(e => deleteUserFromRole(e, userId));
      }
      history.replace(`/networks/view/${userId}`);
    });

    setRoleTemplateId(null);
  };

  handleGoBack = () => {
    this.props.history.goBack();
  };

  openConfirmActionModal = () => {
    this.props.CommonStore.setModalOptions({
      modalName: 'ConfirmationAction',
      modalProps: { handleConfirmAction: this.handleGoBack }
    });
  };

  getCurrentRole(userRoles = [], roleId = '') {
    return userRoles.find(item => item.id === Number(roleId)) || {};
  }

  isNPI({ userRole }) {
    // NPI === 3
    return (
      userRole &&
      userRole.deck_permissions &&
      userRole.deck_permissions.some(permission => permission.deck.id === 3)
    );
  }

  getPlainValue(value) {
    return value || '';
  }

  render() {
    const {
      classes,
      NetworkStore: { currentUser },
      match: {
        params: { userId, roleId }
      }
    } = this.props;
    const { temporaryCustomFields } = this.state;
    const userRoles = toJS(currentUser.roles);
    const currentRole = this.getCurrentRole(userRoles, roleId);
    const hasNPI = this.isNPI({ userRole: currentRole });

    return (
      <React.Fragment>
        <RoleEditForm
          classes={classes}
          currentUser={currentUser}
          selectValue={this.getPlainValue(currentRole.name)}
          currentRole={currentRole}
        />
        <CustomFieldsTable
          classes={classes}
          userId={userId}
          temporaryCustomFields={temporaryCustomFields}
          hasNPI={!!hasNPI}
          handleAddRecord={this.handleAddRecord}
          handleDeleteCustomFields={this.handleDeleteCustomFields}
          handleEditRecord={this.handleEditRecord}
          handleRoleActionClick={this.handleRoleActionClick}
        />
        <EditRoleButtons
          classes={classes}
          handleSaveNewCustomFields={this.handleSaveNewCustomFields}
          isSaveBtnDisabled={!temporaryCustomFields.length}
          openConfirmActionModal={this.openConfirmActionModal}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  withStyles(styles),
  inject('NetworkStore', 'InvitationStore', 'CommonStore'),
  withRouter,
  observer
)(RoleEdit);
