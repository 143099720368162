/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import shortid from 'shortid';
import i18n from 'i18next';

// Utils
import { translation } from '@Utils/translation';

// Material UI
import { Create, Delete } from '@material-ui/icons';

// Stores
import RoleStore from '@Stores/RoleStore';
import DeckStore from '@Stores/DeckStore';
import CommonStore from '@Stores/CommonStore';

const renderCardsNames = cards =>
  Object.keys(cards)
    .map(key => DeckStore.getDeckName(key))
    .map(title => (
      <React.Fragment key={shortid.generate()}>
        {translation(title, 'cardsNames')}
        <br />
      </React.Fragment>
    ));

const renderRoleName = name => translation(name, 'rolesNames');

const isActionDisabled = permissions =>
  !!Object.keys(permissions).find(id => Number(id) === 1 || Number(id) === 6);

export const tableConfig = {
  defaultOrder: 'asc',
  defaultOrderBy: 'name',
  hasActions: true,
  handleRowClick: (event, roleId, redirectTo) => {
    event.stopPropagation();
    redirectTo(`/roles/view/${roleId}`);
  },
  rowIdKey: 'id',
  headings: [
    {
      id: 'name',
      title: i18n.t('mwAdmin.roles.roleName'),
      sortable: true,
      cb: RoleStore.setOrder
    },
    i18n.t('mwAdmin.roles.assignedCards')
  ],
  rowCells: [
    { key: 'name', cb: renderRoleName },
    { key: 'permissions', cb: renderCardsNames }
  ],
  actionsConfig: {
    keyForActivation: 'permissions',
    isDisabled: value => isActionDisabled(value),
    filterKey: 'status',
    menuItems: [
      {
        name: i18n.t('common.edit'),
        icon: <Create />,
        isRedirect: true,
        handleClick: ({ rowId, redirectTo }) => {
          redirectTo(`/roles/edit/${rowId}`);
        }
      },
      {
        name: i18n.t('common.remove'),
        icon: <Delete />,
        handleClick: ({ rowId }) => {
          RoleStore.setDeleteRoleId(rowId);
          CommonStore.setModalOptions({
            modalName: 'ConfirmationAction',
            modalProps: {
              handleConfirmAction: RoleStore.removeRole
            }
          });
        }
      }
    ]
  }
};
