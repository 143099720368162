/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import shortid from 'shortid';
import { Link } from 'react-router-dom';

// Material UI
import { Paper, Typography, Grid, Button, withStyles } from '@material-ui/core';

// Utils
import { compose } from '@Utils';

// Styles
import styles from './styles';

export const WalletCard = ({ classes, node }) => {
  return (
    <Grid item sm={12} md={6} lg={4} key={shortid.generate()}>
      <Paper elevation={5} className={classes.walletWrapper}>
        <Typography variant="h6">{node.description}</Typography>
        <Typography variant="body1" className={classes.content}>
          <span>Review payments for </span>
          <i>{node.description}</i>
          <span> wallet or withdraw SOLVE from wallet</span>
        </Typography>
        <Grid item>
          <Grid container justify="space-between">
            <Button
              color="primary"
              component={Link}
              to={`/wallets/${node.cw_id}/payments`}
            >
              Payments
            </Button>
            <Button
              color="primary"
              component={Link}
              to={`/wallets/${node.cw_id}/withdrawals/new`}
            >
              New Withdrawal
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

WalletCard.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles),
  observer
)(WalletCard);
