/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

// Material UI
import { Grid, Typography, withStyles } from '@material-ui/core';

// Components
import { DateFilter } from '@CommonScene';

// Utils
import { compose } from '@Utils';

// Styles
import styles from './styles';

export class FromToDateFilter extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    dateRange: PropTypes.object,
    handleChangeDate: PropTypes.func.isRequired,
    title: PropTypes.string
  };

  static defaultProps = {
    title: '',
    dateRange: undefined
  };

  state = {
    dateRange: {
      from: undefined,
      to: undefined
    }
  };

  componentDidMount() {
    if (this.props.dateRange) {
      this.setState({
        dateRange: {
          ...this.state.dateRange,
          from: this.getDate(this.props.dateRange.from),
          to: this.getDate(this.props.dateRange.to)
        }
      });
    }
  }

  getDate = date => (date ? moment(date) : '');

  handleChangeDate = (key, date) => {
    this.setState(
      {
        dateRange: { ...this.state.dateRange, [key]: this.getDate(date) }
      },
      this.props.handleChangeDate(date || '', key)
    );
  };

  render() {
    const { classes, title, t } = this.props;
    const { dateRange } = this.state;
    return (
      <Grid item className={classes.wrapper}>
        {title ? <Typography id="title">{`${title}:`}</Typography> : null}
        <DateFilter
          id="from"
          label={t('can.common.from')}
          handleChangeDate={date => this.handleChangeDate('from', date)}
          clearDate={() => this.handleChangeDate('from')}
          maxDate={dateRange.to || moment().format()}
          value={dateRange.from}
        />
        <DateFilter
          id="to"
          label={t('can.common.to')}
          handleChangeDate={date => this.handleChangeDate('to', date)}
          clearDate={() => this.handleChangeDate('to')}
          minDate={dateRange.from}
          disableFuture
          value={dateRange.to}
        />
      </Grid>
    );
  }
}

export default compose(
  withTranslation(),
  withStyles(styles)
)(FromToDateFilter);
