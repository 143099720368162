/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

// Material UI
import { Grid, Typography, Button, withStyles } from '@material-ui/core';

// Components
import { TableSearch } from '@CommonScene/MainContentWrapper/components/ContainerHeader/components';

// Utils
import { compose } from '@Utils';

// Styles
import styles from './styles';

export class ContainerHeader extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    options: PropTypes.object.isRequired,
    setQuery: PropTypes.func,
    setDefaultStatuses: PropTypes.func,
    queryValue: PropTypes.string
  };

  static defaultProps = {
    setQuery: undefined,
    queryValue: '',
    setDefaultStatuses: () => {
      // Stub function
    }
  };

  renderCreationButton(link, title) {
    const { classes } = this.props;
    return link ? (
      <Grid item sm={6} md={6} lg={6}>
        <div className={classNames(classes.alignRight, classes.padding)}>
          <Button
            id="add-button"
            variant="contained"
            color="secondary"
            component={Link}
            to={link}
          >
            {title}
          </Button>
        </div>
      </Grid>
    ) : null;
  }

  render() {
    const {
      classes,
      options: {
        title,
        isList,
        placeholder,
        addButtonLink,
        addButtonTitle,
        customButtonLink,
        customButtonTitle
      },
      setQuery,
      queryValue,
      setDefaultStatuses
    } = this.props;
    return (
      <Grid container spacing={3} className={classes.padding}>
        <Grid item sm={6} md={6} lg={6}>
          <Typography id="title-text" variant="h5">
            {title}
          </Typography>
        </Grid>
        {customButtonTitle
          ? this.renderCreationButton(customButtonLink, customButtonTitle)
          : null}
        {isList && setQuery ? (
          <Grid item sm={12} md={12} lg={12}>
            <Grid container spacing={0}>
              <Grid item sm={6} md={6} lg={6}>
                <TableSearch
                  id="table-search"
                  setQuery={setQuery}
                  value={queryValue}
                  placeholder={placeholder}
                  setDefaultStatuses={setDefaultStatuses}
                />
              </Grid>
              {this.renderCreationButton(addButtonLink, addButtonTitle)}
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    );
  }
}

export default compose(
  withStyles(styles),
  observer
)(ContainerHeader);
