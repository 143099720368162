/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// core
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// Material UI
import { Grid, Typography, Button, withStyles } from '@material-ui/core';
import IconEdit from '@material-ui/icons/Edit';

// Utils
import { compose } from '@Utils';
import { translation } from '@Utils/translation';

// Components
import { StaticTextField } from '@CommonScene';

// Styles
import classNames from 'classnames';
import styles from './styles';

const RoleDetails = ({
  t,
  classes,
  roleId,
  rolePermissions,
  customFields,
  handleDeleteClick,
  inviteUserToRole,
  showActionsBlock,
  invitationSettings,
  roleName
}) => (
  <Fragment>
    <Grid container className={classes.wrapper}>
      <Grid item xs={6}>
        <StaticTextField label={t('mwAdmin.roles.roleName')}>
          {translation(roleName, 'rolesNames')}
        </StaticTextField>
        <StaticTextField label={t('mwAdmin.roles.inviteType')}>
          {invitationSettings.inviteType || 'By Invitation code'}
        </StaticTextField>
        <StaticTextField label={t('mwAdmin.roles.codeType')}>
          {invitationSettings.code_type}
        </StaticTextField>
        <StaticTextField label={t('mwAdmin.roles.deliveryMethod')}>
          {invitationSettings.delivery_method}
        </StaticTextField>
        <StaticTextField label={t('mwAdmin.roles.codeExpiration')}>
          {invitationSettings.expiration_period}
        </StaticTextField>
      </Grid>
      <Grid item xs={6}>
        {rolePermissions.length && showActionsBlock ? (
          <div className={classes.fullHeightStretch}>
            <div className={classes.alignRight}>
              <Button
                variant="contained"
                size="small"
                className={classNames(
                  classes.point,
                  classes.padding,
                  classes.transparentBtn,
                  classes.editBtn
                )}
                component={Link}
                to={`/roles/edit/${roleId}`}
              >
                <IconEdit className={classes.editIcon} />
                {t('common.edit')}
              </Button>
            </div>
            <div className={classes.rowBottom}>
              <Button
                variant="outlined"
                color="default"
                className={classNames(
                  classes.point,
                  classes.padding,
                  classes.removeBtn
                )}
                onClick={() => handleDeleteClick()}
              >
                {t('common.remove')}
              </Button>
            </div>
          </div>
        ) : null}
      </Grid>
      <Grid item xs={12}>
        <div className={classes.horizontalLine} />
      </Grid>
      <Grid item xs={7} className={classes.cardsTable}>
        <Grid container spacing={16}>
          <Grid item xs={6}>
            <Typography
              variant="subtitle1"
              className={classes.tableTitle}
              color="textPrimary"
            >
              <span className={classes.rowLable}>
                {t('mwAdmin.roles.cards')}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="subtitle1"
              className={classes.tableTitle}
              color="textPrimary"
            >
              <span className={classes.rowLable}>
                {t('mwAdmin.roles.permissions')}
              </span>
            </Typography>
          </Grid>
          {!!rolePermissions.length &&
            rolePermissions.map((item, index) =>
              item ? (
                <Grid
                  item
                  xs={12}
                  className={classes.row}
                  key={`deck-${index}`}
                >
                  <div className={classes.rowInner}>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle1"
                        className={classNames(
                          classes.paddings,
                          classes.cardName
                        )}
                      >
                        {translation(item.name, 'cardsNames')}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      {item.permissions.map((p, key) => (
                        <Typography
                          variant="subtitle1"
                          key={`deck-perm-${key}`}
                          className={classes.permissionItems}
                        >
                          {translation(p.name, 'permissionsNames')}
                        </Typography>
                      ))}
                    </Grid>
                  </div>
                </Grid>
              ) : null
            )}
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              className={classes.tableTitle}
              color="textPrimary"
            >
              <span className={classes.rowLable}>
                {t('common.customFields')}
              </span>
            </Typography>
          </Grid>
          {customFields.map((key, index) => (
            <Grid key={index} item xs={12} className={classes.paddingsCustoms}>
              <span className={classes.identifier}>
                {`${key} ${t('mwAdmin.roles.identifier')}`}
              </span>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>

    <div className={classes.alignRight}>
      <Button
        variant="outlined"
        color="default"
        className={classes.marginTop}
        onClick={() => inviteUserToRole()}
      >
        {t('mwAdmin.roles.inviteUserToRole')}
      </Button>
    </div>
  </Fragment>
);

RoleDetails.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  roleId: PropTypes.string.isRequired,
  rolePermissions: PropTypes.array.isRequired,
  customFields: PropTypes.array.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  inviteUserToRole: PropTypes.func.isRequired,
  showActionsBlock: PropTypes.bool,
  invitationSettings: PropTypes.object.isRequired,
  roleName: PropTypes.string
};

RoleDetails.defaultProps = {
  showActionsBlock: false,
  isDialogOpen: false,
  roleName: undefined
};

export default compose(
  withTranslation(),
  withStyles(styles)
)(RoleDetails);
