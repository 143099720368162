/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

// Material-UI
import { withStyles } from '@material-ui/core/styles';

// Components
import { TextField, SelectOptions } from '@CommonScene';

// Tools
import { compose } from '@Utils';
import { validationKeys } from '@Utils/constans';

// Styles
import styles from './styles';

const InviteOptionsForm = ({
  t,
  classes,
  onFieldChange,
  formData,
  formName,
  InvitationStore
}) => {
  const { settings } = InvitationStore;

  return (
    <div className={classes.wrapper}>
      <TextField
        label={t('mwAdmin.roles.roleName')}
        autoFocus
        required
        error={t(validationKeys[formData.fields.name.error])}
        value={formData.fields.name.value}
        onChange={e => onFieldChange('name', e.target.value, formName)}
      />

      <SelectOptions
        name="invite_type"
        label={t('mwAdmin.roles.inviteType')}
        disabled
        items={[
          {
            id: 1,
            value: settings.invite_type || 'By Invitation code',
            name: settings.invite_type || 'By Invitation code'
          }
        ]}
      />

      <SelectOptions
        name="code_type"
        label={t('mwAdmin.roles.codeType')}
        disabled
        items={[
          {
            id: 1,
            value: settings.code_type || 'NA',
            name: settings.code_type || 'NA'
          }
        ]}
      />

      <SelectOptions
        name="delivery_method"
        label={t('mwAdmin.roles.deliveryMethod')}
        disabled
        items={[
          {
            id: 1,
            value: settings.delivery_method || 'NA',
            name: settings.delivery_method || 'NA'
          }
        ]}
      />

      <TextField
        label={t('mwAdmin.roles.codeExpiration')}
        value={settings.expiration_period || 'NA'}
        name={settings.expiration_period || 'NA'}
        disabled
      />
    </div>
  );
};

InviteOptionsForm.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  formName: PropTypes.string.isRequired,
  InvitationStore: PropTypes.object.isRequired
};

InviteOptionsForm.defaultProps = {
  invitationSettings: {}
};

export default compose(
  withTranslation(),
  withStyles(styles),
  inject('InvitationStore'),
  observer
)(InviteOptionsForm);
