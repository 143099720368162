/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { IconButton, withStyles } from '@material-ui/core';
import Dehaze from '@material-ui/icons/Dehaze';
import ActionMenuItems from '@CommonScene/DataTable/BodyRow/ActionButton/ActionMenuItems';

import styles from './styles';

export class ActionButton extends Component {
  state = {
    anchorEl: null
  };

  static propTypes = {
    rowId: PropTypes.number.isRequired,
    extraId: PropTypes.number,
    isDisabled: PropTypes.bool,
    menuItems: PropTypes.array.isRequired
  };

  static defaultProps = {
    isDisabled: false,
    extraId: undefined
  };

  openActionMenu = event => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget });
  };

  closeActionMenu = event => {
    event.stopPropagation();
    this.setState({ anchorEl: null });
  };

  render() {
    const { rowId, extraId, isDisabled, menuItems } = this.props;
    const { anchorEl } = this.state;

    return (
      <Fragment>
        <IconButton
          id="menu-actions-btn"
          disabled={isDisabled}
          aria-owns={anchorEl ? 'menu-action' : undefined}
          aria-haspopup="true"
          onClick={e => this.openActionMenu(e)}
        >
          <Dehaze />
        </IconButton>
        <ActionMenuItems
          rowId={rowId}
          extraId={extraId}
          anchorEl={anchorEl}
          menuItems={menuItems}
          closeActionMenu={this.closeActionMenu}
        />
      </Fragment>
    );
  }
}

export default withStyles(styles)(ActionButton);
