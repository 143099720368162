/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// Material UI
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';

// Component
import MenuEditField from '@NetworkScene/scenes/EditRole/components/MenuEditField';

const CustomFieldsTable = ({
  t,
  classes,
  temporaryCustomFields,
  hasNPI,
  userId,
  handleAddRecord,
  handleEditRecord,
  handleRoleActionClick,
  handleDeleteCustomFields
}) => (
  <Fragment>
    <Grid container className={classes.padding}>
      <Grid item xs={6}>
        <Typography variant="h5">{t('common.customFields')}</Typography>
      </Grid>
      <Grid item xs={6} align="right" className={classes.fieldsAction}>
        {temporaryCustomFields.length ? (
          <span className={classes.infoMessage}>
            {t('mwAdmin.modals.addOneCustomField')}
          </span>
        ) : null}
        <Button id="add-field" onClick={handleAddRecord}>
          {t('mwAdmin.modals.addField')}
        </Button>
      </Grid>
    </Grid>
    <Grid container className={classes.root}>
      <Table>
        <TableHead>
          <TableRow>
            {hasNPI ? (
              <React.Fragment>
                <TableCell align="left" className={classes.roleCell}>
                  NPI ID
                </TableCell>
                <TableCell align="left" className={classes.roleCell}>
                  {`NPI ${t('common.name')}`}
                </TableCell>
              </React.Fragment>
            ) : null}
            <TableCell align="left" className={classes.roleCell}>
              TIN ID
            </TableCell>
            <TableCell align="left" className={classes.roleCell}>
              {`TIN ${t('common.name')}`}
            </TableCell>
            <TableCell align="left" className={classes.roleCell}>
              {t('common.actions')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {temporaryCustomFields.map((item, index) => (
            <TableRow key={`body-row-${index}`}>
              {hasNPI ? (
                <React.Fragment>
                  <TableCell align="left" className={classes.roleCell}>
                    {(item.NPI && item.NPI.id) || ''}
                  </TableCell>
                  <TableCell align="left" className={classes.roleCell}>
                    {(item.NPI && item.NPI.name) || ''}
                  </TableCell>
                </React.Fragment>
              ) : null}
              <TableCell align="left" className={classes.roleCell}>
                {item.TIN.id || item.TIN.value || ''}
              </TableCell>
              <TableCell align="left" className={classes.roleCell}>
                {item.TIN.name || ''}
              </TableCell>
              <TableCell align="left" className={classes.roleCell}>
                <MenuEditField
                  userId={userId}
                  groupId={item.groupId}
                  handleEditRecord={handleEditRecord}
                  handleActionClick={handleRoleActionClick}
                  handleDeleteCustomFields={handleDeleteCustomFields}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>
  </Fragment>
);

CustomFieldsTable.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  temporaryCustomFields: PropTypes.array.isRequired,
  hasNPI: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  handleAddRecord: PropTypes.func.isRequired,
  handleEditRecord: PropTypes.func.isRequired,
  handleRoleActionClick: PropTypes.func.isRequired,
  handleDeleteCustomFields: PropTypes.func.isRequired
};

export default withTranslation()(CustomFieldsTable);
