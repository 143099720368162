/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// Material UI
import { Grid, Button, withStyles } from '@material-ui/core';

// Components
import InviteOptionsForm from '@RolesScene/scenes/common/InviteOptionsForm';

// Utils
import { compose } from '@Utils';

// Styles
import styles from './styles';

const RoleEditForm = ({ classes, currentRoleId, handleSave, RoleStore, t }) => {
  const {
    onFieldChange,
    forms: { editRole }
  } = RoleStore;
  return (
    <Fragment>
      <Grid container spacing={0} className={classes.padding}>
        <Grid item xs={6}>
          <InviteOptionsForm
            onFieldChange={onFieldChange}
            formData={editRole}
            formName="editRole"
          />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.actionsContainer}>
            <div className={classes.alignRight}>
              <Button
                className={classes.button}
                component={Link}
                to={`/roles/view/${currentRoleId}`}
              >
                {t('common.cancel')}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                disabled={!editRole.meta.isValid}
                onClick={handleSave}
                className={classes.button}
              >
                {t('common.save')}
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
};

RoleEditForm.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  RoleStore: PropTypes.object.isRequired,
  currentRoleId: PropTypes.string.isRequired,
  handleSave: PropTypes.func.isRequired
};

export default compose(
  withTranslation(),
  withStyles(styles),
  inject('RoleStore'),
  observer
)(RoleEditForm);
