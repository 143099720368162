/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

import { status } from '@Assets/styles/status';

const styles = theme => ({
  visibilityHidden: {
    visibility: 'hidden'
  },
  padding: {
    padding: theme.spacing(3)
  },
  topSpace: {
    paddingTop: theme.spacing(1)
  },
  bottomSpace: {
    paddingBottom: theme.spacing(3)
  },
  profileField: {
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  profileFieldText: {
    padding: `${theme.spacing(0.5)}px 0`
  },
  profileFieldPlaceholder: {
    color: 'rgba(0, 0, 0, 0.38)'
  },
  button: {
    margin: '0 10px',
    '&:first-child': {
      marginLeft: 0
    }
  },
  label: {
    color: 'rgba(0, 0, 0, 0.38)'
  },
  borderBottom: {
    borderBottom: '1px dotted rgba(0, 0, 0, 0.12)'
  },
  solidBorderBottom: {
    borderBottom: '2px solid rgba(0, 0, 0, 0.12)'
  },
  rightIcon: {
    marginRight: theme.spacing(1)
  },
  iconSmall: {
    fontSize: 20
  },
  formControl: {
    marginBottom: 20
  },
  textAlignRight: {
    textAlign: 'right'
  },
  marginRightZero: {
    marginRight: 0
  },
  bottomPaddingZero: {
    paddingBottom: 0
  },
  modalText: {
    fontFamily: theme.typography.fontFamily
  },
  modalButton: {
    color: '#2196f3'
  },
  editBtn: {
    marginTop: -theme.spacing(13)
  },
  ...status
});

export default styles;
